import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Modal,
  Typography,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Autocomplete,
} from "@mui/material";
import { updateInfo } from "../../redux/authSlice"; // Import the Redux action
import Swal from "sweetalert2";
import { companies } from "../data/CampanyTopic";
import BASE_URL from "../../API/BaseUrl";
const interestsOptions = [
  "Computer Science",
  "Electronics",
  "Electrical",
  "Mechanical",
  "Civil",
  "Instrumentation",
  "Chemical",
  "Aerospace",
  "Aeronautical",
  "Agricultural",
  "Mining",
  "Naval and Marine",
  "Metallurgy",
  "Other",
];

const AdditionalInfoModal = ({ open, setOpen }) => {
  const dispatch = useDispatch();
  const { user, status } = useSelector((state) => state.auth);
  const fieldsOfStudy = [
    "Computer Science",
    "Electrical and Electronics",
    "Mechanical Engineering",
    "Civil Engineering",
    "Information Technology",
    "Biomedical Engineering",
    "Chemical Engineering",
  ];
  const [formValues, setFormValues] = useState({
    userId: user.id,
    company: "",
    gender: "",
    role: "",
    interests: [],
    dept: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleInterestsChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormValues({
      ...formValues,
      interests: typeof value === "string" ? value.split(",") : value,
    });
  };
  const handleDeptChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleSubmit = () => {
    const { userId, company, gender, role, interests, dept } = formValues;
    if (company && gender && role && interests.length) {
      dispatch(updateInfo({ userId, company, gender, role, interests, dept }))
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Information Added",
            text: "Your additional information has been successfully Added.",
          });
          setOpen(false); // Close modal after successful submission
        })
        .catch(() => {
          Swal.fire({
            icon: "success",
            title: "Information Added",
            text: "Your additional information has been successfully Added.",
          });
        });
    } else {
      Swal.fire({
        icon: "warning",
        title: "Incomplete Information",
        text: "Please fill all fields before submitting.",
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => {}}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backdropFilter: "blur(20px)",
        // backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box
        sx={{
          //   bgcolor: "rgba(0, 0, 0, 0.8)",
          p: 4,
          borderRadius: "10px",
          maxWidth: 500,
          width: "100%",
          boxShadow: 24,
          border: "1px solid white",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "white", textAlign: "center", mb: 2 }}
        >
          Please fill all the below Information
        </Typography>

        {/* Company Input */}
        {/* <TextField
          name="company"
          label="Company"
          variant="outlined"
          fullWidth
          margin="normal"
          value={formValues.company}
          onChange={handleChange}
          InputLabelProps={{ style: { color: "white" } }}
          InputProps={{
            style: { color: "white", borderColor: "white" },
            notchedOutline: { borderColor: "white" },
          }}
          sx={{
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "white",
            },
            ".MuiSvgIcon-root": {
              color: "white",
            },
          }}
        /> */}
        <Autocomplete
          options={companies}
          getOptionLabel={(option) => option}
          value={formValues.company}
          onChange={(event, newValue) => {
            handleChange({ target: { name: "company", value: newValue } });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Company/Institution"
              variant="outlined"
              fullWidth
              margin="normal"
              InputLabelProps={{ style: { color: "white" } }}
              InputProps={{
                ...params.InputProps,
                style: { color: "white", borderColor: "white" },
                notchedOutline: { borderColor: "white" },
              }}
              sx={{
                color: "white",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                ".MuiSvgIcon-root": {
                  color: "white",
                },
              }}
            />
          )}
        />

        {/* Gender Selection */}
        <Typography variant="body1" sx={{ color: "white", mb: 1, mt: 1 }}>
          Gender
        </Typography>
        <div className="flex items-center justify-center">
          <RadioGroup
            name="gender"
            value={formValues.gender}
            onChange={handleChange}
            row
            sx={{ mb: 2, color: "white" }}
          >
            <FormControlLabel
              value="Male"
              control={<Radio sx={{ color: "white" }} />}
              label={<Typography style={{ color: "white" }}>Male</Typography>}
            />
            <FormControlLabel
              value="Female"
              control={<Radio sx={{ color: "white" }} />}
              label={<Typography style={{ color: "white" }}>Female</Typography>}
            />
            <FormControlLabel
              value="Other"
              control={<Radio sx={{ color: "white" }} />}
              label={<Typography style={{ color: "white" }}>Other</Typography>}
            />
          </RadioGroup>
        </div>

        {/* Role Dropdown */}
        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ color: "white" }}>Role</InputLabel>
          <Select
            name="role"
            value={formValues.role}
            onChange={handleChange}
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{
              style: { color: "white", borderColor: "white" },
              notchedOutline: { borderColor: "white" },
            }}
            label="Role"
            sx={{
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              ".MuiSvgIcon-root": {
                color: "white",
              },
            }}
          >
            <MenuItem value="Student">Student</MenuItem>
            <MenuItem value="Professional">Professional</MenuItem>
          </Select>
        </FormControl>

        {/* Interests Multi-Select */}
        <FormControl fullWidth margin="normal">
          <InputLabel sx={{ color: "white" }}>Interests</InputLabel>
          <Select
            name="interests"
            multiple
            value={formValues.interests}
            onChange={handleInterestsChange}
            input={<OutlinedInput label="Interests" />}
            InputLabelProps={{ style: { color: "white" } }}
            InputProps={{
              style: { color: "white", borderColor: "white" },
              notchedOutline: { borderColor: "white" },
            }}
            renderValue={(selected) => selected.join(", ")}
            sx={{
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              ".MuiSvgIcon-root": {
                color: "white",
              },
            }}
          >
            {interestsOptions.map((interest) => (
              <MenuItem key={interest} value={interest}>
                <Checkbox
                  checked={formValues.interests.indexOf(interest) > -1}
                  sx={{ color: "white" }}
                />
                <ListItemText primary={interest} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Department Dropdown */}
        <FormControl fullWidth margin="normal" style={{ marginTop: "10px" }}>
          <InputLabel sx={{ color: "white" }}>Field of Study</InputLabel>
          <Select
            name="dept"
            value={formValues.dept}
            onChange={handleDeptChange}
            label="Field of Study"
            sx={{
              color: "white",
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "white",
              },
              ".MuiSvgIcon-root": {
                color: "white",
              },
            }}
          >
            {fieldsOfStudy.map((field, index) => (
              <MenuItem key={index} value={field}>
                {field}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Submit Button */}
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={status === "loading"}
          sx={{
            mt: 3,
          }}
          style={{
            py: 4,
            fontFamily: "Roboto",
            fontWeight: "bold",
            background:
              "linear-gradient(90deg, rgba(103, 54, 215, 0.9) 0%, rgba(160, 54, 193, 0.9) 50.15%, rgba(233, 53, 166, 0.9) 100.31%)",
          }}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

export default AdditionalInfoModal;
