import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { registerUser, verifyOTP } from "../../redux/features/authSlice";
import {
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Swal from "sweetalert2";
import OtpInput from "react-otp-input";
import { GoogleLogin } from "@react-oauth/google";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { googleLogin } from "../../redux/authSlice";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [formData, setFormData] = useState({
    name: "",
    password: "",
    email: "",
    phoneNumber: "",
  });
  const [otp, setOtp] = useState("");
  const [openOtpDialog, setOpenOtpDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  // Handle form data changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Toggle password visibility
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // Handle Google login success
  const handleGoogleLoginSuccess = async (response) => {
    try {
      const tokenId = response.credential;
      await dispatch(googleLogin(tokenId)).unwrap();
      Swal.fire("Success", "Google Register successful", "success").then(() => {
        navigate("/dashboard-layout/analytics");
      });
    } catch (error) {
      Swal.fire("Error", error.message || "Google login failed", "error");
    }
  };

  // Handle Google login failure
  const handleGoogleLoginFailure = (error) => {
    Swal.fire("Error", error.message || "Google login failed", "error");
  };

  // Handle registration
  const handleSubmit = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Processing...",
      text: "Please wait while we register your account",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    dispatch(registerUser(formData))
      .unwrap()
      .then(() => {
        Swal.close();
        Swal.fire("Success", "OTP sent to your email", "success");
        setOpenOtpDialog(true);
      })
      .catch((error) => {
        Swal.close();
        Swal.fire("Error", error.message || "Registration failed", "error");
      });
  };

  // Handle OTP verification
  const handleVerifyOTP = () => {
    setOtpLoading(true);
    dispatch(verifyOTP({ email: formData.email, otp }))
      .unwrap()
      .then(() => {
        Swal.fire("Success", "Registration successful!", "success").then(() => {
          setOpenOtpDialog(false);
          navigate("/login");
        });
      })
      .catch((error) => {
        Swal.fire("Error", error.message || "OTP verification failed", "error");
      })
      .finally(() => {
        setOtpLoading(false);
      });
  };

  // Disable OTP button if OTP is incomplete
  const isOtpComplete = otp.length === 6;

  return (
    <Box
      className="text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 2,
        minHeight: "85vh", // Make it full height for smaller screens
      }}
    >
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: { xs: "100%", sm: "550px" }, // Full width on extra small screens, 400px from small screens onwards
          maxWidth: "800px",
          mx: "auto",
          m: { xs: 2, md: 16 }, // Adjust margins for different screen sizes
          p: 4,
          borderRadius: 2,
          boxShadow: 16,
          backgroundColor: "#fff",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500"
          initial={{ backgroundPosition: "200% center" }}
          animate={{ backgroundPosition: "0% center" }}
          transition={{ duration: 3, ease: "linear" }}
          justifyContent="center"
          mb={3}
          sx={{
            textAlign: "center",
            fontWeight: "800",
            fontSize: { xs: "1.5rem", sm: "1.8rem" },
          }} // Adjust font size for responsiveness
        >
          Create a new account
        </Box>

        <TextField
          label="Name"
          variant="outlined"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          label="Email"
          variant="outlined"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />

        <TextField
          label="Password"
          variant="outlined"
          name="password"
          type={showPassword ? "text" : "password"}
          value={formData.password}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          label="Phone Number"
          variant="outlined"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{
            mt: 2,
            p: 1.5,
            backgroundImage:
              "linear-gradient(to right, #3b82f6, #a855f7, #ec4899)", // Responsive gradient
          }}
          disabled={status === "loading"}
        >
          {status === "loading" ? <CircularProgress size={24} /> : "Register"}
        </Button>

        {error && (
          <Typography color="error" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}

        <Box className="mb-4 mt-4 flex items-center justify-center">
          <GoogleLogin
            onSuccess={handleGoogleLoginSuccess}
            onError={handleGoogleLoginFailure}
            style={{ width: "100%" }}
          />
        </Box>

        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ mt: 2, textAlign: "center" }}
        >
          Already have an account?{" "}
          <a
            href="/login"
            style={{ color: "#6200ea", textDecoration: "underline" }}
          >
            Sign in
          </a>
        </Typography>

        {/* OTP Dialog */}
        <Dialog open={openOtpDialog} onClose={() => setOpenOtpDialog(false)}>
          <DialogTitle>Enter OTP</DialogTitle>
          <DialogContent>
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              separator={<span>-</span>}
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 0.5rem",
                fontSize: "1.5rem",
                borderRadius: "0.5rem",
                border: "1px solid #d1d5db",
              }}
              isInputNum
              shouldAutoFocus
              renderInput={(props) => <input {...props} />}
            />
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button
              onClick={handleVerifyOTP}
              color="primary"
              variant="contained"
              disabled={!isOtpComplete || otpLoading}
            >
              {otpLoading ? <CircularProgress size={24} /> : "Verify OTP"}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
};

export default Register;
