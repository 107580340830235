import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  Paper,
  Grow,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import { motion } from "framer-motion";
import MicIcon from "@mui/icons-material/Mic";
import SendIcon from "@mui/icons-material/Send";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import StopIcon from "@mui/icons-material/Stop";
import { useNavigate } from "react-router-dom";
import Title from "../../Header/Title";
import BASE_URL from "../../../API/BaseUrl";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: 24,
  backgroundColor: "#ffffff",
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
  transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 15px 35px rgba(0, 0, 0, 0.12)",
  },
}));

const AnswerQuestions = ({ onAllAnswersSubmitted }) => {
  const [questions, setQuestions] = useState(() => {
    const savedQuestions = localStorage.getItem("generatedQuestions");
    return savedQuestions ? JSON.parse(savedQuestions) : [];
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
    return parseInt(localStorage.getItem("currentQuestionIndex") || "0", 10);
  });
  const [answers, setAnswers] = useState(() => {
    const savedAnswers = localStorage.getItem("interviewAnswers");
    return savedAnswers ? JSON.parse(savedAnswers) : [];
  });
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speakingEnabled, setSpeakingEnabled] = useState(false);
  const [interviewComplete, setInterviewComplete] = useState(false);
  const [voiceTyping, setVoiceTyping] = useState(false);
  const [questionRead, setQuestionRead] = useState(false);
  const recognitionRef = useRef(null);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  useEffect(() => {
    localStorage.setItem(
      "currentQuestionIndex",
      currentQuestionIndex.toString()
    );
    localStorage.setItem("interviewAnswers", JSON.stringify(answers));
  }, [currentQuestionIndex, answers]);

  useEffect(() => {
    if (speakingEnabled && questions.length > 0 && !questionRead) {
      speakText(questions[currentQuestionIndex]);
      setQuestionRead(true);
    }
  }, [currentQuestionIndex, questions, speakingEnabled, questionRead]);

  const handleSubmitAnswer = async () => {
    if (questions.length === 0) {
      console.error("No questions available");
      return;
    }

    const question = questions[currentQuestionIndex];

    let userId;

    if (user.id === undefined) {
      userId = user._id;
    } else {
      userId = user.id;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/interview/analyze-answer/${userId}`,
        {
          question,
          answer: currentAnswer,
        }
      );

      const newAnswers = [
        ...answers,
        {
          question,
          answer: currentAnswer,
          analysis: response.data.analysis || {
            feedback: "No feedback available",
            score: 0,
          },
        },
      ];
      setAnswers(newAnswers);
      setCurrentAnswer("");
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        setQuestionRead(false);
      } else {
        setInterviewComplete(true);
        onAllAnswersSubmitted(newAnswers);
        navigate("/dashboard-layout/analysis");
        localStorage.removeItem("generatedQuestions");
        localStorage.removeItem("currentQuestionIndex");
        localStorage.removeItem("interviewAnswers");
      }
    } catch (error) {
      console.error("Error analyzing answer:", error);
    } finally {
      setLoading(false);
    }
  };

  const startVoiceRecognition = () => {
    if ("webkitSpeechRecognition" in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.lang = "en-US";
      recognition.interimResults = false;
      recognition.continuous = true;
      recognition.maxAlternatives = 1;

      recognition.onstart = () => {
        setVoiceTyping(true);
        console.log("Voice typing started.");
      };

      recognition.onresult = (event) => {
        const speechToText =
          event.results[event.results.length - 1][0].transcript;
        console.log("Recognized speech:", speechToText);
        setCurrentAnswer((prevAnswer) => prevAnswer + " " + speechToText);
      };

      recognition.start();
      recognitionRef.current = recognition;
    } else {
      console.error("Speech recognition is not supported in this browser.");
    }
  };

  const stopVoiceRecognition = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      setVoiceTyping(false);
      recognitionRef.current = null;
    }
  };

  const speakText = (text) => {
    setIsSpeaking(true);
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = "en-US";
    utterance.onend = () => {
      setIsSpeaking(false);
      setQuestionRead(true);
    };
    window.speechSynthesis.speak(utterance);
  };

  const toggleSpeaking = () => {
    setSpeakingEnabled(!speakingEnabled);
    if (speakingEnabled) {
      window.speechSynthesis.cancel();
      setIsSpeaking(true);
    } else if (questions.length > 0 && !questionRead) {
      speakText(questions[currentQuestionIndex]);
    }
  };

  if (questions.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h5" gutterBottom>
          No questions available. Please generate questions first.
        </Typography>
      </Box>
    );
  }

  if (interviewComplete) {
    return null;
  }

  return (
    <>
      <div className="">
        <Title title="Interview Session "></Title>
      </div>
      <Box
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        // minHeight="100vh"
        p={1}
        // sx={{ background: "linear-gradient(145deg, #f3f4f6, #ffffff)" }}
        // marginTop="15px"
      >
        {/* <Typography
        variant="h3"
        gutterBottom
        fontWeight="800"
        // color="primary"
        mb={1}
        fontFamily="'Poppins', sans-serif"
      >
        Interview Session
      </Typography> */}

        <Box
          display="flex"
          flexDirection="row"
          alignItems="stretch"
          justifyContent="center"
          width="100%"
          maxWidth="1200px"
          mb={4}
          sx={{ flexWrap: "wrap", gap: 4 }}
        >
          <Grow in={true} timeout={700}>
            <StyledPaper
              elevation={3}
              sx={{ flex: 1, minWidth: "300px", maxWidth: "1000px" }}
              style={{
                background: "transparent",
                // backdropFilter: "blur(8px)",
                color: "white",
                // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
              >
                <Typography
                  variant="h4"
                  gutterBottom
                  fontWeight="bold"
                  fontFamily="'Poppins', sans-serif"
                  // color="primary"
                  style={{ color: "white" }}
                >
                  Question {currentQuestionIndex + 1} of {questions.length}
                </Typography>
                <IconButton onClick={toggleSpeaking}>
                  {speakingEnabled ? (
                    <VolumeUpIcon style={{ color: "white" }} />
                  ) : (
                    <VolumeOffIcon style={{ color: "white" }} />
                  )}
                </IconButton>
              </Box>
              <Typography
                variant="h5"
                gutterBottom
                mb={3}
                color="white"
                // color="text.secondary"
                fontFamily="'Roboto', sans-serif"
              >
                {questions[currentQuestionIndex]}
              </Typography>
              {!isSpeaking && (
                <>
                  <TextField
                    label="Your Answer"
                    value={currentAnswer}
                    onChange={(e) => setCurrentAnswer(e.target.value)}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "white", // Default border color
                        },
                        "&:hover fieldset": {
                          borderColor: "white", // Border color when hovered
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "white", // Border color when focused
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "white", // Label color when not focused
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "white", // Label color when focused
                      },
                      "& .MuiOutlinedInput-input": {
                        color: "white", // Text color inside the input
                      },
                    }}
                  />

                  <Button
                    component={motion.button}
                    variant="contained"
                    onClick={handleSubmitAnswer}
                    fullWidth
                    startIcon={
                      loading ? <CircularProgress size={24} /> : <SendIcon />
                    }
                    disabled={loading}
                    size="large"
                    sx={{
                      mt: "16px",
                      fontSize: "18px",
                      height: "50px",
                      textTransform: "none",
                      fontWeight: 500,
                      boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Adding white shadow
                    }}
                    className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-48 font-semibold btn-alt"
                    initial={{ backgroundPosition: "200% center" }}
                    animate={{ backgroundPosition: "0% center" }}
                    transition={{ duration: 0.2, ease: "linear" }}
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    {loading ? "Submitting..." : "Submit Answer"}
                  </Button>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmitAnswer}
                    startIcon={
                      loading ? <CircularProgress size={24} /> : <SendIcon />
                    }
                    disabled={loading}
                    fullWidth
                  >
                    {loading ? "Submitting..." : "Submit Answer"}
                  </Button> */}
                  {!voiceTyping ? (
                    <Button
                      component={motion.button}
                      variant="contained"
                      onClick={startVoiceRecognition}
                      fullWidth
                      disabled={loading}
                      size="large"
                      sx={{
                        mt: "20px",
                        fontSize: "18px",
                        height: "50px",
                        textTransform: "none",
                        fontWeight: 500,
                        boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Adding white shadow
                      }}
                      className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-48 font-semibold btn-alt"
                      initial={{ backgroundPosition: "200% center" }}
                      animate={{ backgroundPosition: "0% center" }}
                      transition={{ duration: 0.2, ease: "linear" }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <MicIcon /> Start Voice Recognition
                    </Button>
                  ) : (
                    <Button
                      component={motion.button}
                      variant="error"
                      onClick={stopVoiceRecognition}
                      fullWidth
                      startIcon={
                        loading ? <CircularProgress size={24} /> : <SendIcon />
                      }
                      disabled={loading}
                      size="large"
                      sx={{
                        mt: "20px",
                        fontSize: "18px",
                        height: "50px",
                        textTransform: "none",
                        fontWeight: 500,
                        boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Adding white shadow
                      }}
                      className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-48 font-semibold btn-alt"
                      initial={{ backgroundPosition: "200% center" }}
                      animate={{ backgroundPosition: "0% center" }}
                      transition={{ duration: 0.2, ease: "linear" }}
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <MicIcon />
                      Stop Voice Recognition
                    </Button>
                  )}
                </>
              )}
            </StyledPaper>
          </Grow>
        </Box>
      </Box>
    </>
  );
};

export default AnswerQuestions;

// import React, { useState, useEffect } from "react";
// import {
//   TextField,
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Paper,
//   Grow,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import axios from "axios";
// import { motion } from "framer-motion";
// import MicIcon from "@mui/icons-material/Mic";
// import SendIcon from "@mui/icons-material/Send";
// import { useNavigate } from "react-router-dom";

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(4),
//   borderRadius: 24,
//   backgroundColor: "#ffffff",
//   boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
//   transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//   "&:hover": {
//     transform: "translateY(-5px)",
//     boxShadow: "0 15px 35px rgba(0, 0, 0, 0.12)",
//   },
// }));

// const AnswerQuestions = ({ onAllAnswersSubmitted }) => {
//   const [questions, setQuestions] = useState(() => {
//     const savedQuestions = localStorage.getItem("generatedQuestions");
//     return savedQuestions ? JSON.parse(savedQuestions) : [];
//   });
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
//     return parseInt(localStorage.getItem("currentQuestionIndex") || "0", 10);
//   });
//   const [answers, setAnswers] = useState(() => {
//     const savedAnswers = localStorage.getItem("interviewAnswers");
//     return savedAnswers ? JSON.parse(savedAnswers) : [];
//   });
//   const [currentAnswer, setCurrentAnswer] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [questionRead, setQuestionRead] = useState(false);
//   const [interviewComplete, setInterviewComplete] = useState(false);
//   const [voiceTyping, setVoiceTyping] = useState(false);
//   const navigate = useNavigate(); // Hook for navigation

//   useEffect(() => {
//     localStorage.setItem(
//       "currentQuestionIndex",
//       currentQuestionIndex.toString()
//     );
//     localStorage.setItem("interviewAnswers", JSON.stringify(answers));
//   }, [currentQuestionIndex, answers]);

//   useEffect(() => {
//     if (!questionRead && questions.length > 0) {
//       speakText(questions[currentQuestionIndex]);
//       setQuestionRead(true);
//     }
//   }, [currentQuestionIndex, questions, questionRead]);

//   const handleSubmitAnswer = async () => {
//     if (questions.length === 0) {
//       console.error("No questions available");
//       return;
//     }

//     const question = questions[currentQuestionIndex];
//     const userId = JSON.parse(localStorage.getItem("user"))?.id;
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         `https://hirehack.io/api/interview/analyze-answer/${userId}`,
//         {
//           question,
//           answer: currentAnswer,
//         }
//       );

//       const newAnswers = [
//         ...answers,
//         {
//           question,
//           answer: currentAnswer,
//           analysis: response.data.analysis || {
//             feedback: "No feedback available",
//             score: 0,
//           },
//         },
//       ];
//       setAnswers(newAnswers);
//       setCurrentAnswer("");
//       if (currentQuestionIndex < questions.length - 1) {
//         setCurrentQuestionIndex(currentQuestionIndex + 1);
//         setQuestionRead(false);
//       } else {
//         setInterviewComplete(true);
//         onAllAnswersSubmitted(newAnswers);
//         navigate("/analysis"); // Navigate to the analysis page
//         localStorage.removeItem("generatedQuestions");
//         localStorage.removeItem("currentQuestionIndex");
//         localStorage.removeItem("interviewAnswers");
//       }
//     } catch (error) {
//       console.error("Error analyzing answer:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const startVoiceRecognition = () => {
//     if ("webkitSpeechRecognition" in window) {
//       const recognition = new window.webkitSpeechRecognition();
//       recognition.lang = "en-US";
//       recognition.interimResults = false;
//       recognition.maxAlternatives = 1;

//       recognition.onstart = () => {
//         setVoiceTyping(true);
//         console.log("Voice typing started.");
//       };

//       recognition.onend = () => {
//         setVoiceTyping(false);
//         console.log("Voice typing ended.");
//       };

//       recognition.onerror = (event) => {
//         console.error("Speech recognition error:", event.error);
//         setVoiceTyping(false);
//       };

//       recognition.onresult = (event) => {
//         const speechToText = event.results[0][0].transcript;
//         console.log("Recognized speech:", speechToText);
//         setCurrentAnswer(speechToText);
//       };

//       recognition.start();
//     } else {
//       console.error("Speech recognition is not supported in this browser.");
//     }
//   };

//   const speakText = (text) => {
//     const utterance = new SpeechSynthesisUtterance(text);
//     utterance.lang = "en-US";
//     window.speechSynthesis.speak(utterance);
//   };

//   if (questions.length === 0) {
//     return (
//       <Box
//         display="flex"
//         flexDirection="column"
//         alignItems="center"
//         justifyContent="center"
//         minHeight="100vh"
//       >
//         <Typography variant="h5" gutterBottom>
//           No questions available. Please generate questions first.
//         </Typography>
//       </Box>
//     );
//   }

//   if (interviewComplete) {
//     return null; // This will be handled by redirection
//   }

//   return (
//     <Box
//       component={motion.div}
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 0.5 }}
//       display="flex"
//       flexDirection="column"
//       alignItems="center"
//       justifyContent="center"
//       minHeight="100vh"
//       p={4}
//       sx={{ background: "linear-gradient(145deg, #f3f4f6, #ffffff)" }}
//       marginTop="15px"
//     >
//       <Typography
//         variant="h3"
//         gutterBottom
//         fontWeight="800"
//         color="primary"
//         mb={4}
//         fontFamily="'Poppins', sans-serif"
//       >
//         Interview Session
//       </Typography>
//       <Box
//         display="flex"
//         flexDirection="row"
//         alignItems="stretch"
//         justifyContent="center"
//         width="100%"
//         maxWidth="1200px"
//         mb={4}
//         sx={{ flexWrap: "wrap", gap: 4 }}
//       >
//         {/* Question */}
//         <Grow in={true} timeout={700}>
//           <StyledPaper
//             elevation={3}
//             sx={{ flex: 1, minWidth: "300px", maxWidth: "600px" }}
//           >
//             <Typography
//               variant="h4"
//               gutterBottom
//               fontWeight="bold"
//               fontFamily="'Poppins', sans-serif"
//             >
//               Question {currentQuestionIndex + 1} of {questions.length}
//             </Typography>
//             <Typography
//               variant="h5"
//               gutterBottom
//               mb={3}
//               color="text.secondary"
//               fontFamily="'Roboto', sans-serif"
//             >
//               {questions[currentQuestionIndex]}
//             </Typography>
//             <TextField
//               label="Your Answer"
//               value={currentAnswer}
//               onChange={(e) => setCurrentAnswer(e.target.value)}
//               fullWidth
//               margin="normal"
//               multiline
//               rows={4}
//               variant="outlined"
//             />
//             <Button
//               variant="contained"
//               color="primary"
//               onClick={handleSubmitAnswer}
//               startIcon={
//                 loading ? <CircularProgress size={24} /> : <SendIcon />
//               }
//               disabled={loading}
//               fullWidth
//             >
//               {loading ? "Submitting..." : "Submit Answer"}
//             </Button>
//             <Button
//               variant="contained"
//               color="secondary"
//               onClick={startVoiceRecognition}
//               disabled={voiceTyping}
//               fullWidth
//               sx={{ mt: 2 }}
//             >
//               {voiceTyping ? "Listening..." : <MicIcon />}
//               {voiceTyping ? "" : "Start Voice Recognition"}
//             </Button>
//           </StyledPaper>
//         </Grow>
//       </Box>
//     </Box>
//   );
// };

// export default AnswerQuestions;

// import React, { useState, useEffect } from "react";
// import {
//   TextField,
//   Button,
//   Typography,
//   Box,
//   CircularProgress,
//   Paper,
//   Grow,
// } from "@mui/material";
// import { styled } from "@mui/system";
// import axios from "axios";
// import { motion } from "framer-motion";
// import MicIcon from "@mui/icons-material/Mic";
// import SendIcon from "@mui/icons-material/Send";
// import { useNavigate } from "react-router-dom";

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   padding: theme.spacing(4),
//   borderRadius: 24,
//   backgroundColor: "#ffffff",
//   boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
//   transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//   "&:hover": {
//     transform: "translateY(-5px)",
//     boxShadow: "0 15px 35px rgba(0, 0, 0, 0.12)",
//   },
// }));

// const AnswerQuestions = ({ onAllAnswersSubmitted }) => {
//   const [questions, setQuestions] = useState(() => {
//     const savedQuestions = localStorage.getItem("generatedQuestions");
//     return savedQuestions ? JSON.parse(savedQuestions) : [];
//   });
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(() => {
//     return parseInt(localStorage.getItem("currentQuestionIndex") || "0", 10);
//   });
//   const [answers, setAnswers] = useState(() => {
//     const savedAnswers = localStorage.getItem("interviewAnswers");
//     return savedAnswers ? JSON.parse(savedAnswers) : [];
//   });
//   const [currentAnswer, setCurrentAnswer] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [questionRead, setQuestionRead] = useState(false);
//   const [interviewComplete, setInterviewComplete] = useState(false);
//   const [voiceTyping, setVoiceTyping] = useState(false);
//   const [displayedWords, setDisplayedWords] = useState([]);
//   const [showAnswerSection, setShowAnswerSection] = useState(false); // To display answer section
//   const navigate = useNavigate(); // Hook for navigation

//   useEffect(() => {
//     localStorage.setItem(
//       "currentQuestionIndex",
//       currentQuestionIndex.toString()
//     );
//     localStorage.setItem("interviewAnswers", JSON.stringify(answers));
//   }, [currentQuestionIndex, answers]);

//   useEffect(() => {
//     if (!questionRead && questions.length > 0) {
//       const words = questions[currentQuestionIndex].split(" ");
//       revealWords(words);
//       setQuestionRead(true);
//     }
//   }, [currentQuestionIndex, questions, questionRead]);

//   const revealWords = (words) => {
//     let index = 0;
//     const interval = setInterval(() => {
//       if (index < words.length) {
//         setDisplayedWords((prev) => [...prev, words[index]]);
//         index++;
//       } else {
//         clearInterval(interval);
//         setShowAnswerSection(true); // Show answer section after question is fully displayed
//       }
//     }, 300); // Adjust delay between words
//   };

//   const handleSubmitAnswer = async () => {
//     if (questions.length === 0) {
//       console.error("No questions available");
//       return;
//     }

//     const question = questions[currentQuestionIndex];
//     const userId = JSON.parse(localStorage.getItem("user"))?.id;
//     setLoading(true);
//     try {
//       const response = await axios.post(
//         `https://hirehack.io/api/interview/analyze-answer/${userId}`,
//         {
//           question,
//           answer: currentAnswer,
//         }
//       );

//       const newAnswers = [
//         ...answers,
//         {
//           question,
//           answer: currentAnswer,
//           analysis: response.data.analysis || {
//             feedback: "No feedback available",
//             score: 0,
//           },
//         },
//       ];
//       setAnswers(newAnswers);
//       setCurrentAnswer("");
//       if (currentQuestionIndex < questions.length - 1) {
//         setCurrentQuestionIndex(currentQuestionIndex + 1);
//         setDisplayedWords([]); // Reset displayed words for the next question
//         setShowAnswerSection(false); // Hide answer section for the next question
//         setQuestionRead(false);
//       } else {
//         setInterviewComplete(true);
//         onAllAnswersSubmitted(newAnswers);
//         navigate("/analysis"); // Navigate to the analysis page
//         localStorage.removeItem("generatedQuestions");
//         localStorage.removeItem("currentQuestionIndex");
//         localStorage.removeItem("interviewAnswers");
//       }
//     } catch (error) {
//       console.error("Error analyzing answer:", error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const startVoiceRecognition = () => {
//     if ("webkitSpeechRecognition" in window) {
//       const recognition = new window.webkitSpeechRecognition();
//       recognition.lang = "en-US";
//       recognition.interimResults = false;
//       recognition.maxAlternatives = 1;

//       recognition.onstart = () => {
//         setVoiceTyping(true);
//         console.log("Voice typing started.");
//       };

//       recognition.onend = () => {
//         setVoiceTyping(false);
//         console.log("Voice typing ended.");
//       };

//       recognition.onerror = (event) => {
//         console.error("Speech recognition error:", event.error);
//         setVoiceTyping(false);
//       };

//       recognition.onresult = (event) => {
//         const speechToText = event.results[0][0].transcript;
//         console.log("Recognized speech:", speechToText);
//         setCurrentAnswer(speechToText);
//       };

//       recognition.start();
//     } else {
//       console.error("Speech recognition is not supported in this browser.");
//     }
//   };

//   if (questions.length === 0) {
//     return (
//       <Box
//         display="flex"
//         flexDirection="column"
//         alignItems="center"
//         justifyContent="center"
//         minHeight="100vh"
//       >
//         <Typography variant="h5" gutterBottom>
//           No questions available. Please generate questions first.
//         </Typography>
//       </Box>
//     );
//   }

//   if (interviewComplete) {
//     return null; // This will be handled by redirection
//   }

//   return (
//     <Box
//       component={motion.div}
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1 }}
//       transition={{ duration: 0.5 }}
//       display="flex"
//       flexDirection="column"
//       alignItems="center"
//       justifyContent="center"
//       minHeight="100vh"
//       p={4}
//       sx={{ background: "linear-gradient(145deg, #f3f4f6, #ffffff)" }}
//       marginTop="15px"
//     >
//       <Typography
//         variant="h3"
//         gutterBottom
//         fontWeight="800"
//         color="primary"
//         mb={4}
//         fontFamily="'Poppins', sans-serif"
//       >
//         Interview Session
//       </Typography>
//       <Box
//         display="flex"
//         flexDirection="row"
//         alignItems="stretch"
//         justifyContent="center"
//         width="100%"
//         maxWidth="1200px"
//         mb={4}
//         sx={{ flexWrap: "wrap", gap: 4 }}
//       >
//         {/* Question */}
//         <Grow in={true} timeout={700}>
//           <StyledPaper
//             elevation={3}
//             sx={{ flex: 1, minWidth: "300px", maxWidth: "600px" }}
//           >
//             <Typography
//               variant="h4"
//               gutterBottom
//               fontWeight="bold"
//               fontFamily="'Poppins', sans-serif"
//             >
//               Question {currentQuestionIndex + 1} of {questions.length}
//             </Typography>
//             <Typography
//               variant="h5"
//               gutterBottom
//               mb={3}
//               color="text.secondary"
//               fontFamily="'Roboto', sans-serif"
//             >
//               {displayedWords.join(" ")}
//             </Typography>

//             {/* Answer Section (only shown after the question is fully displayed) */}
//             {showAnswerSection && (
//               <>
//                 {/* Answer Input */}
//                 <TextField
//                   label="Your Answer"
//                   value={currentAnswer}
//                   onChange={(e) => setCurrentAnswer(e.target.value)}
//                   fullWidth
//                   margin="normal"
//                   multiline
//                   rows={4}
//                   variant="outlined"
//                 />
//                 {/* Submit Button */}
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleSubmitAnswer}
//                   startIcon={
//                     loading ? <CircularProgress size={24} /> : <SendIcon />
//                   }
//                   disabled={loading}
//                   fullWidth
//                   sx={{ mt: 2 }}
//                 >
//                   {loading ? "Submitting..." : "Submit Answer"}
//                 </Button>

//                 {/* Voice Typing Button */}
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={startVoiceRecognition}
//                   disabled={voiceTyping}
//                   fullWidth
//                   sx={{ mt: 2 }}
//                 >
//                   {voiceTyping ? "Listening..." : <MicIcon />}
//                   {voiceTyping ? "" : "Start Voice Typing"}
//                 </Button>
//               </>
//             )}
//           </StyledPaper>
//         </Grow>
//       </Box>

//       {/* Conditional rendering for interview completion */}
//       {interviewComplete && (
//         <Typography
//           variant="h4"
//           gutterBottom
//           color="success"
//           textAlign="center"
//           sx={{ mt: 4 }}
//         >
//           Congratulations! You've completed the interview.
//         </Typography>
//       )}
//     </Box>
//   );
// };

// export default AnswerQuestions;
