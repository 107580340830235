import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import "@fontsource/poppins";
import Footer from "../components/Footer/Footer";

const Home = () => {
  const [started, setStarted] = useState(false);
  const navigate = useNavigate();
  const [optionIndex, setOptionIndex] = useState(0);
  const options = [
    "Technical Interviews",
    "HR Interviews",
    "Managerial Interviews",
    "Aptitude Tests",
    "Coding Challenges",
    "Product Based Interviews",
    "Job Description Based Interviews",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setOptionIndex((prevIndex) => (prevIndex + 1) % options.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [options.length]);

  const startPracticing = () => {
    setStarted(true);
    setTimeout(() => navigate("/dashboard-layout/generate"), 1000);
  };
  const hangleNavigate = () => {
    navigate("/how-it-works");
  };

  return (
    <div>
      <div className="flex flex-col min-h-screen pt-4 bg-gradient-to-b from-white to-gray-50 overflow-hidden font-poppins">
        <main className="flex-grow">
          <Hero
            started={started}
            startPracticing={startPracticing}
            options={options}
            optionIndex={optionIndex}
          />
          <Features />
          <HowItWorks />
          <Testimonials />
          <CallToAction />
        </main>
      </div>
      <Footer />
    </div>
  );
};

const Hero = ({ started, startPracticing, options, optionIndex }) => (
  <section className="flex flex-col items-center justify-center bg-gradient-to-b from-blue-200 to-white py-16 min-h-screen relative">
    {!started ? (
      <motion.div
        className="text-center w-full max-w-5xl px-4"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.7, ease: "easeInOut" }}
      >
        <h1 className="text-4xl md:text-6xl lg:text-7xl font-extrabold text-gray-800 mb-6">
          Welcome to{" "}
          <motion.span
            className="text-indigo-600"
            initial={{ x: -20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
          >
            HireHack
          </motion.span>
        </h1>
        <p className="text-2xl md:text-3xl text-gray-700 mb-4 max-w-3xl mx-auto">
          Hack Your Way to Job Success with AI-Powered Interview Preparation
        </p>
        <motion.div
          className="flex justify-center items-center mb-12"
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            duration: 0.8,
            ease: "easeInOut",
            staggerChildren: 0.2,
          }}
        >
          <motion.div
            className="text-2xl md:text-3xl text-indigo-600 font-semibold"
            key={optionIndex}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            {options[optionIndex]}
          </motion.div>
        </motion.div>
        <p className="text-base md:text-lg text-gray-600 mb-10 max-w-3xl mx-auto">
          Are you ready to land your dream job? Our cutting-edge, AI-driven
          platform offers personalized practice sessions, real-time feedback,
          and comprehensive performance insights to help you master your next
          interview.
        </p>
        <motion.button
          className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-64 btn-alt"
          style={{
            fontSize: "20px",
            fontWeight: "550",
            borderRadius: "32px",
          }}
          initial={{ backgroundPosition: "200% center" }}
          animate={{ backgroundPosition: "0% center" }}
          transition={{ duration: 0.2, ease: "linear" }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={startPracticing}
        >
          Start My Free Trial
        </motion.button>
      </motion.div>
    ) : (
      <motion.div
        className="w-full max-w-4xl mx-auto px-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div>Redirecting...</div>
      </motion.div>
    )}
  </section>
);

const Features = () => (
  <section className="bg-gray-100 py-16 md:py-24">
    <div className="max-w-6xl mx-auto px-4">
      <motion.h2
        className="text-3xl md:text-5xl font-bold text-gray-900 mb-12 text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Why Choose HireHack?
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {[
          {
            title: "AI-Powered Mock Interviews",
            description:
              "Simulate real-life interview scenarios across different formats.",
          },
          {
            title: "Real-Time Feedback",
            description:
              "Receive instant feedback on your responses and skills.",
          },
          {
            title: "Comprehensive Question Bank",
            description:
              "Access an extensive database of questions from top companies.",
          },
          {
            title: "Personalized Performance Reports",
            description:
              "Track your progress with detailed analytics and recommendations.",
          },
        ].map((feature, index) => (
          <motion.div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <h3 className="text-xl font-semibold mb-4 text-indigo-600">
              {feature.title}
            </h3>
            <p className="text-gray-600">{feature.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </section>
);

const HowItWorks = () => (
  <section className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 py-16 md:py-24 text-white">
    <div className="max-w-6xl mx-auto text-center px-4">
      <motion.h2
        className="text-3xl md:text-5xl font-extrabold mb-12"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        How It Works
      </motion.h2>
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 text-left"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        {[
          {
            step: "1",
            title: "Choose Your Interview Type",
            description: "Select the type of interview you want to practice.",
          },
          {
            step: "2",
            title: "Take the AI-Driven Mock Interview",
            description:
              "Engage in a real-time simulated interview with tailored questions.",
          },
          {
            step: "3",
            title: "Get Instant Feedback",
            description:
              "Our AI evaluates your answers and provides immediate, actionable feedback.",
          },
          {
            step: "4",
            title: "Improve and Track Your Progress",
            description:
              "Use personalized performance reports to sharpen your skills.",
          },
        ].map((step, index) => (
          <motion.div
            key={index}
            className="p-6 rounded-lg shadow-xl hover:shadow-2xl transition-shadow duration-300"
            style={{
              background: "rgb(220 220 220 / 25%)",
              backdropFilter: "blur(32px)",
            }}
            whileHover={{ scale: 1.05 }}
          >
            <h3 className="text-xl md:text-2xl font-bold mb-4">
              Step {step.step}
            </h3>
            <h4 className="text-lg font-semibold mb-2">{step.title}</h4>
            <p>{step.description}</p>
          </motion.div>
        ))}
      </motion.div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="mt-4"
      >
        <motion.button
          className="bg-white text-indigo-600 py-3 px-6 md:py-4 md:px-12 rounded-full text-lg md:text-xl font-semibold hover:bg-gray-100 transition duration-300 shadow-lg"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          // onClick={{ hangleNavigate }}
        >
          Learn More
        </motion.button>
      </div>
    </div>
  </section>
);

const Testimonials = () => (
  <section className="bg-gray-100 py-16 md:py-24">
    <div className="max-w-6xl mx-auto px-4">
      <motion.h2
        className="text-3xl md:text-5xl font-bold text-gray-900 mb-12 text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Success Stories
      </motion.h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {[
          {
            name: "John D.",
            role: "Software Engineer at XYZ Tech",
            quote:
              "I used HireHack to prepare for my coding interviews, and the real-time feedback was a game changer! I felt confident walking into every interview.",
          },
          {
            name: "Sarah L.",
            role: "Marketing Associate at ABC Corp",
            quote:
              "The AI analysis of my behavioral responses helped me refine my answers and stand out from other candidates.",
          },
        ].map((testimonial, index) => (
          <motion.div
            key={index}
            className="bg-white p-6 rounded-lg shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
            <p className="font-semibold">{testimonial.name}</p>
            <p className="text-sm text-gray-500">{testimonial.role}</p>
          </motion.div>
        ))}
      </div>
    </div>
  </section>
);

const CallToAction = () => (
  <motion.section
    className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 py-16 md:py-24 text-white"
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <div className="max-w-6xl mx-auto text-center px-4">
      <h2 className="text-3xl md:text-5xl font-bold mb-6">
        Ready to Ace Your Interview?
      </h2>
      <p className="text-xl mb-8">
        Sign up now and start your 7-day free trial to experience the power of
        AI-driven interview preparation.
      </p>
      <motion.button
        className="bg-white text-indigo-600 py-3 px-6 md:py-4 md:px-12 rounded-full text-lg md:text-xl font-semibold hover:bg-gray-100 transition duration-300 shadow-lg"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        Start My Free Trial
      </motion.button>
      <p className="mt-4 text-sm">No credit card required.</p>
    </div>
  </motion.section>
);

export default Home;
