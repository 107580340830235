// data.js
export const companies = [
  "AARBEE",
  "ACCENTURE",
  "ACCOLITE",
  "AFCONS",
  "AJ Institute of Engineering and Technology, Mangalore",
  "ALLGOVISION",
  "AMAZON",
  "AMC Engineering College, Bangalore",
  "AMD",
  "APRA LABS",
  "ASIANPAINTS",
  "AU College of Engineering for Women, Visakhapatnam",
  "AURIGO",
  "AXISXADES",
  "Accurate Institute of Management and Technology, Greater Noida (Delhi NCR)",
  "Acharya Institute of Technology, Bangalore",
  "Acharya Institute of Technology, Shivamogga",
  "Acharya Narendra Dev College, Delhi",
  "Aditya College of Engineering, Surampalem",
  "Alliance University, Bangalore",
  "Alva’s Institute of Engineering and Technology, Mangalore",
  "Amity Law School, Noida (Delhi NCR)",
  "Amity University Online, Guntur",
  "Amity University, Noida (Delhi NCR)",
  "Andhra Mahila Sabha Arts and Science College for Women (AMSASCW), Hyderabad",
  "Andhra University School of Management, Visakhapatnam",
  "Andhra University, Visakhapatnam",
  "Anil Neerukonda Institute of Technology & Science, Visakhapatnam",
  "Annamacharya Institute of Technology and Sciences, Rajampet",
  "Anurag University, Hyderabad",
  "Apeejay School of Management, Delhi",
  "Army Institute of Management and Technology (AIMT), Greater Noida (Delhi NCR)",
  "Asian Business School, Delhi",
  "Atma Ram Sanatan Dharma College (ARSD), Delhi",
  "Atria Institute of Technology, Bangalore",
  "Audhisankara College of Engineering & Technology, Hyderabad",
  "Audisankara College Of Engineering For Women, Hyderabad",
  "Aurora’s Scientific Technological and Research Academy, Hyderabad",
  "Aurora’s Technological and Research Institute, Hyderabad",
  "B. M. S. College of Engineering, Belagavi",
  "B.E. College, Shivamogga",
  "BAJAJ FINANCE",
  "BETA",
  "BITS Pilani Hyderabad Campus, Hyderabad",
  "BLDEA's V.P. Dr. P.G. Halakatti College of Engineering & Technology, Vijayapura",
  "BLS Institute of Management, Delhi NCR",
  "BML Munjal University, Gurugram (Delhi NCR)",
  "BMS College of Commerce & Management, Bangalore",
  "BMS College of Engineering, Bangalore",
  "BNM Institute of Technology, Bangalore",
  "BOSCH",
  "BRILLIO",
  "BROSE IND automotive systems",
  "BVBCET (BVB College of Engineering & Technology), Hubli",
  "Baba Saheb Ambedkar Institute of Technology and Management, Delhi",
  "Banarsidas Chandiwala Institute of Professional Studies, Delhi",
  "Bangalore Institute of Technology, Bangalore",
  "Bangalore University, Bangalore",
  "Bapuji Institute of Engineering & Technology (BIET), Davangere",
  "Basava Academy of Engineering, Bagalkot",
  "Basaveshwar Engineering College, Bagalkot",
  "Bennett University, Greater Noida (Delhi NCR)",
  "Besant Women’s College, Mangalore",
  "Bhagini Nivedita College, Delhi",
  "Bhagwan Parshuram Institute of Technology (BPIT), Delhi",
  "Bharat Institute of Engineering and Technology, Hyderabad",
  "Bharathi Vidyapeeth College of Engineering, Davangere",
  "Bharati College, Delhi",
  "Bharatiya Vidya Bhavan’s S.P. Jain Institute of Management, Delhi NCR",
  "Bharti Vidyapeeth’s Institute of Management and Research, Delhi",
  "Bhaskaracharya College of Applied Sciences, Delhi",
  "Bhavan’s Vivekananda College of Science, Humanities & Commerce, Hyderabad",
  "Brahmaiah College of Engineering, Kurnool",
  "Brindavan College of Engineering, Bangalore",
  "CISCO",
  "CMR College of Engineering & Technology, Hyderabad",
  "CMR Institute of Technology, Bangalore",
  "CMR University, Bangalore",
  "CODECRAFT",
  "COGNIZANT",
  "COMVIVA",
  "CONTENTSTACK",
  "CONTINENTAL",
  "COREEL TECHNOLOGIES",
  "CVR College of Engineering, Hyderabad",
  "Canara Engineering College, Mangalore",
  "Chaitanya Bharathi Institute of Technology (CBIT), Hyderabad",
  "Chalapathi Institute of Technology, Guntur",
  "Chanderprabhu Jain College of Higher Studies & School of Law, Delhi",
  "Chandigarh University (CU), Delhi NCR",
  "Chirala Engineering College, Chirala",
  "Christ University, Bangalore",
  "Coorg Institute of Technology, Coorg",
  "DAIMLER",
  "DASSAULT SYSTEMS",
  "DELL TECHNOLOGIES",
  "DELOITTE",
  "DHIOMICS",
  "DISH NETWORK",
  "DRAUP",
  "DXC TECHNOLOGIES",
  "Daulat Ram College, Delhi",
  "Dayananda Sagar College of Engineering, Bangalore",
  "Deen Dayal Upadhyaya College, Delhi",
  "Delhi College of Arts and Commerce (DCAC), Delhi",
  "Delhi Institute of Advanced Studies, Delhi",
  "Delhi Institute of Pharmaceutical Sciences and Research (Excluding Pharmacy), Delhi",
  "Delhi Institute of Rural Development (DIRD), Delhi",
  "Delhi Institute of Technology and Management (DITM), Delhi NCR",
  "Delhi School of Business, Delhi",
  "Delhi School of Journalism, Delhi",
  "Delhi School of Professional Studies and Research (DSPSR), Delhi",
  "Delhi Technological University (DTU), Delhi",
  "Dhanekula Institute of Engineering and Technology, Vijayawada",
  "Don Bosco Institute of Technology, Bangalore",
  "Dr. Akhilesh Das Gupta Institute of Technology & Management (ADGITM), Delhi",
  "Dr. S. R. Chandrasekhar Institute of Technology, Tumkur",
  "EDUSTATION",
  "ELASTICRUN",
  "EMPI Business School, Delhi",
  "EPSILON",
  "East Point College of Engineering and Technology, Bangalore",
  "Esko",
  "Eswar College of Engineering, Guntur",
  "FALABELLA",
  "Fairfield Institute of Management and Technology (FIMT), Delhi",
  "Father Muller College, Mangalore",
  "Fore School of Management, Delhi",
  "Fostiima Business School, Delhi",
  "G.Pullaiah College of Engineering and Technology, Kurnool",
  "GITAM (Deemed to be University), Visakhapatnam",
  "GITAM School of Architecture and Design, Visakhapatnam",
  "GITAM School of Aviation Management, Visakhapatnam",
  "GITAM School of Biotechnology, Visakhapatnam",
  "GITAM School of Business, Visakhapatnam",
  "GITAM School of Computer Applications, Visakhapatnam",
  "GITAM School of Design, Visakhapatnam",
  "GITAM School of Energy Studies, Visakhapatnam",
  "GITAM School of Environmental Studies, Visakhapatnam",
  "GITAM School of Fine Arts, Visakhapatnam",
  "GITAM School of Hotel Management, Visakhapatnam",
  "GITAM School of Humanities and Social Sciences, Visakhapatnam",
  "GITAM School of International Studies, Visakhapatnam",
  "GITAM School of Journalism and Mass Communication, Visakhapatnam",
  "GITAM School of Law, Visakhapatnam",
  "GITAM School of Logistics and Supply Chain Management, Visakhapatnam",
  "GITAM School of Management, Visakhapatnam",
  "GITAM School of Performing Arts, Visakhapatnam",
  "GITAM School of Pharmacy, Visakhapatnam",
  "GITAM School of Rural Development, Visakhapatnam",
  "GITAM School of Science and Technology, Visakhapatnam",
  "GITAM School of Science, Visakhapatnam",
  "GITAM School of Technology, Visakhapatnam",
  "GITAM School of Tourism Management, Visakhapatnam",
  "GITAM School of Urban Studies, Visakhapatnam",
  "GITAM University, Hyderabad Campus",
  "GL Bajaj Institute of Management and Research, Greater Noida (Delhi NCR)",
  "GLA University, Mathura (Delhi NCR)",
  "GNIOT College of Management, Greater Noida (Delhi NCR)",
  "GOOGLE",
  "Galgotias University, Greater Noida (Delhi NCR)",
  "Garden City University, Bangalore",
  "Gargi College, Delhi",
  "Gayathri Vidya Parishad (GVP) College of Engineering, Visakhapatnam",
  "Gayatri Vidya Parishad College of Engineering For Women, Visakhapatnam",
  "Geethanjali College of Engineering and Technology, Hyderabad",
  "Geethanjali Institute of Science & Technology, Hyderabad",
  "Gitarattan International Business School (GIBS), Delhi",
  "Global Academy of Technology, Bangalore",
  "Gogte Institute of Technology (GIT), Belagavi",
  "Gogte Institute of Technology, Belagavi",
  "Gokaraju Rangaraju Institute of Engineering and Technology, Hyderabad",
  "Gopalan College of Engineering and Management, Bangalore",
  "Government Engineering College, Hassan",
  "Government Engineering College, Ramnagara",
  "Government Polytechnic, Bagalkot",
  "Govinda Dasa College, Mangalore",
  "Greater Noida Institute of Technology (GNIOT), Greater Noida (Delhi NCR)",
  "Gudlavalleru Engineering College, Gudlavalleru",
  "Guru Nanak Institute of Management, Delhi",
  "Guru Tegh Bahadur Institute of Technology (GTBIT), Delhi",
  "Gurugram University, Gurugram (Delhi NCR)",
  "HARMAN",
  "HASHEDIN",
  "HCL",
  "HEXAWARE TECHNOLOGIES",
  "HITECH",
  "HKBK College of Engineering, Hubli",
  "HP",
  "Hansraj College, Delhi",
  "Hindu College, Delhi",
  "Hyderabad Institute of Technology and Management, Hyderabad",
  "IBM",
  "IBS (ICFAI Business School), Delhi NCR",
  "ICFAI Business School (IBS), Hyderabad",
  "IFIM College, Bangalore",
  "IILM Institute for Higher Education, Delhi",
  "IMPACT ANALYTICS",
  "IMS Engineering College, Ghaziabad (Delhi NCR)",
  "IMS Law College, Noida (Delhi NCR)",
  "IMS Unison University, Roorkee (Delhi NCR)",
  "IN TIME TEC",
  "INFOYS",
  "INTEL",
  "ISBR Business School, Bangalore",
  "ITC INFOTECH",
  "ITM University, Gwalior (Delhi NCR)",
  "ITTIAM",
  "Ideal Institute of Management and Technology, Delhi",
  "Indian Institute of Management (IIM), Bangalore",
  "Indian Institute of Management (IIM), Visakhapatnam",
  "Indian Institute of Management and Commerce (IIMC), Hyderabad",
  "Indian Institute of Science (IISc), Bangalore",
  "Indian Institute of Technology Delhi (IIT Delhi), Delhi",
  "Indian School of Business (ISB), Hyderabad",
  "Indraprastha College for Women, Delhi",
  "Indraprastha Institute of Information Technology (IIIT Delhi), Delhi",
  "Institute of Aeronautical Engineering, Hyderabad",
  "Institute of Chartered Financial Analysts of India (ICFAI), Delhi NCR",
  "Institute of Home Economics, Delhi",
  "Institute of Innovation in Technology & Management (IITM), Delhi",
  "Institute of Management Studies (IMS) Noida, Noida (Delhi NCR)",
  "Institute of Management Studies, Davangere",
  "Institute of Marketing and Management (IMM), Delhi",
  "Institute of Public Enterprise (IPE), Hyderabad",
  "Institute of Public Health and Hygiene, Delhi",
  "Institute of Technology and Science (ITS), Ghaziabad (Delhi NCR)",
  "International Institute of Information Technology (IIIT), Bangalore",
  "International Institute of Information Technology (IIIT), Hyderabad",
  "International Management Institute (IMI), Delhi",
  "JPMORGAN",
  "JSS Academy of Technical Education, Bangalore",
  "JSS Academy of Technical Education, Dharwad",
  "JSS Academy of Technical Education, Mysore",
  "JSS Academy of Technical Education, Noida (Delhi NCR)",
  "JSS College of Arts, Commerce and Science, Mysore",
  "JSS Science and Technology University (JSS STU), Mysore",
  "Jagan Institute of Management Studies (JIMS), Rohini",
  "Jagannath International Management School (JIMS), Kalkaji",
  "Jagannath University, Bahadurgarh (Delhi NCR)",
  "Jagans College of Engineering & Technology, Anantapur",
  "Jain College of Engineering, Belagavi",
  "Jain University, Bangalore",
  "Jaipuria Institute of Management, Noida (Delhi NCR)",
  "Jamia Hamdard University, Delhi",
  "Jamia Millia Islamia, Delhi",
  "Jawaharlal Nehru Institute of Technology, Hyderabad",
  "Jawaharlal Nehru Medical College, Belagavi",
  "Jawaharlal Nehru National College of Engineering, Shivamogga",
  "Jawaharlal Nehru Technological University (JNTU), Hyderabad",
  "Jawaharlal Nehru Technological University, Kakinada",
  "Jawaharlal Nehru University (JNU), Delhi",
  "Jaypee Institute of Information Technology, Noida (Delhi NCR)",
  "Jesus and Mary College, Delhi",
  "Jindal Global Business School, Sonipat (Delhi NCR)",
  "Jindal Global University, Sonipat (Delhi NCR)",
  "K L University (KLH), Hyderabad",
  "K.R. Mangalam University, Gurugram (Delhi NCR)",
  "KIET Group of Institutions, Ghaziabad (Delhi NCR)",
  "KKR & KSR Institute of Technology and Sciences, Guntur",
  "KL University, Vijayawada",
  "KLE College of Pharmacy, Belagavi",
  "KLE College of Pharmacy, Hubli",
  "KLE Dr. M. S. Sheshgiri College of Engineering & Technology, Belagavi",
  "KLE Institute of Technology, Hubli",
  "KLE Society's Law College, Hubli",
  "KLE Society’s Law College, Bangalore",
  "KLE University, Belagavi",
  "KNEST",
  "KOTAK LIFE",
  "KPIT",
  "Kakatiya Institute of Technology & Science, Hyderabad",
  "Kakatiya University, Warangal",
  "Kalindi College, Delhi",
  "Kamala Nehru College, Delhi",
  "Karavali Institute of Technology, Mangalore",
  "Karnatak University, Dharwad",
  "Karnataka Institute of Medical Sciences, Hubli",
  "Karnataka Institute of Technology, T.B. Dam, Kolar",
  "Karnataka Polytechnic, Mangalore",
  "Karnataka State Open University, Mysore",
  "Karnataka Theological College, Mangalore",
  "Kasturba Medical College, Manipal (Udupi)",
  "Kasturi Ram College of Higher Education, Delhi",
  "Keshav Mahavidyalaya, Delhi",
  "Keshav Memorial Institute of Technology, Hyderabad",
  "Kirori Mal College, Delhi",
  "Krishna Chaitanya Institute of Technology and Sciences, Markapur",
  "Kristu Jayanti College, Bangalore",
  "LUMEN",
  "Lady Shri Ram College for Women (LSR), Delhi",
  "Lakireddy Bali Reddy College of Engineering, Mylavaram",
  "Lakshmibai College, Delhi",
  "Lal Bahadur Shastri Institute of Management, Delhi",
  "Lingaya’s Vidyapeeth, Faridabad (Delhi NCR)",
  "Lloyd Institute of Management and Technology, Greater Noida (Delhi NCR)",
  "Lords Institute of Engineering and Technology, Hyderabad",
  "Lovely Professional University (LPU), Delhi NCR",
  "Loyola Institute of Technology and Management, Guntur",
  "M.S. Ramaiah Institute of Technology, Bangalore",
  "MAPLE LABS",
  "MARUTI SUZUKI",
  "MERCEDES BENZ",
  "MINDTREE",
  "MLR Institute of Technology, Hyderabad",
  "MVJ College of Engineering, Bangalore",
  "MVR College of Engineering and Technology, Paritala",
  "Madanapalle Institute of Technology & Science, Madanapalle",
  "Maharaja Agrasen Institute of Technology, Delhi",
  "Maharaja Agrasen University, Delhi NCR",
  "Maharaja Institute of Technology, Mysore",
  "Maharaja Surajmal Institute of Technology, Delhi",
  "Maharaja Surajmal Institute, Delhi",
  "Maharishi University of Information Technology, Noida (Delhi NCR)",
  "Mahatma Gandhi Institute of Technology (MGIT), Hyderabad",
  "Mahindra University, Hyderabad",
  "Maitreyi College, Delhi",
  "Malla Reddy Engineering College for Women, Hyderabad",
  "Malla Reddy Engineering College, Hyderabad",
  "Manav Rachna International Institute of Research and Studies (MRIIRS), Delhi NCR",
  "Mangalmay Group of Institutions, Greater Noida (Delhi NCR)",
  "Mangalmay Institute of Management and Technology, Greater Noida (Delhi NCR)",
  "Mangalore Institute of Technology and Engineering (MITE), Mangalore",
  "Mangalore Marine College and Technology, Mangalore",
  "Manipal College of Dental Sciences, Manipal",
  "Manipal Institute of Technology (MIT), Udupi",
  "Manipal Institute of Technology (Manipal University), Mangalore",
  "Maratha Mandal Engineering College, Belagavi",
  "Maris Stella College, Vijayawada",
  "Marri Laxman Reddy Institute of Technology & Management, Hyderabad",
  "Maulana Azad National Urdu University (MANUU), Hyderabad",
  "Microsoft",
  "Milagres College, Mangalore",
  "Miranda House, Delhi",
  "Mody University of Science and Technology, Delhi NCR",
  "Motilal Nehru College, Delhi",
  "Mount Carmel College, Bangalore",
  "Muffakham Jah College of Engineering and Technology, Hyderabad",
  "Mysore Medical College and Research Institute, Mysore",
  "NBKR Institute of Science & Technology, Nellore",
  "NRI Institute of Technology, Vijayawada",
  "NTELLIPAAT",
  "NTR College of Veterinary Science, Krishna",
  "NUCLEI",
  "Narasaraopeta Engineering College, Narasaraopeta",
  "Narayana Engineering College, Nellore",
  "Narayana Medical College, Mysore",
  "Narsee Monjee Institute of Management Studies (NMIMS), Hyderabad",
  "National Institute of Fashion Technology (NIFT), Bangalore",
  "National Institute of Fashion Technology (NIFT), Delhi",
  "National Institute of Fashion Technology (NIFT), Hyderabad",
  "National Institute of Fashion Technology, Gandhinagar",
  "National Institute of Technology Karnataka (NITK), Surathkal (Mangalore)",
  "National Law School of India University (NLSIU), Bangalore",
  "National Law University (NLU), Delhi",
  "Nehru Institute of Technology, Coorg",
  "Netaji Subhas University of Technology (NSUT), Delhi",
  "New Delhi Institute of Management (NDIM), Delhi",
  "New Horizon College of Engineering, Bangalore",
  "Nitte Institute of Technology, Mangalore",
  "Nitte Institute of Technology, Udupi",
  "Nitte Meenakshi Institute of Technology, Udupi",
  "Noida International University, Greater Noida (Delhi NCR)",
  "Noida International University, Noida (Delhi NCR)",
  "Northern India Engineering College (NIEC), Delhi",
  "ORACLE",
  "Osmania University, Hyderabad",
  "Oxford College of Engineering, Bangalore",
  "PA College of Engineering, Mangalore",
  "PACE Institute of Technology and Sciences, Ongole",
  "PALO ALTO",
  "PEGA",
  "PERSISTENT",
  "PES College of Engineering, Mandya",
  "PES Institute of Technology, Shivamogga",
  "PES University, Bangalore",
  "PIDILITE",
  "PRINCIPAL",
  "PVP Siddhartha Institute of Technology, Vijayawada",
  "PWC",
  "Parvathareddy Babul Reddy (PBR) Visvodaya Institute of Technology & Sciences, Nellore",
  "Pearl Academy, Delhi",
  "Prakasam Engineering College, Kandukur",
  "Presidency University, Bangalore",
  "Pydah College of Engineering & Technology, Vijayawada",
  "QUEST",
  "Qualcomm",
  "Quantum University, Roorkee (Delhi NCR)",
  "R.V. Institute of Management, Bangalore",
  "RADCAM TECHNOLOGIES",
  "RAKUTEN",
  "REDBUS",
  "RICS School of Built Environment, Noida (Delhi NCR)",
  "RV College of Engineering, Bangalore",
  "Raghu Engineering College (REC), Visakhapatnam",
  "Raghu Engineering College, Visakhapatnam",
  "Raghu Institute of Technology (RIT), Visakhapatnam",
  "Rai University, Delhi NCR",
  "Raja Bahadur Venkat Rama Reddy Women's College (RBVRR), Hyderabad",
  "RajaRajeswari College of Engineering, Bangalore",
  "Rajdhani College, Delhi",
  "Rajeev Gandhi Memorial College of Engineering and Technology, Nandyal",
  "Ramachandra College of Engineering, Eluru",
  "Ramaiah Institute of Technology, Bangalore",
  "Ramanujan College, Delhi",
  "Rami Reddy Subbarami Reddy Engineering College, Kurnool",
  "Ramjas College, Delhi",
  "Rani Channamma University, Belagavi",
  "Rao Bahadur Y. Mahabaleshwarappa Engineering College, Ballari",
  "Rattan Institute of Technology and Management, Faridabad (Delhi NCR)",
  "Reva University, Bangalore",
  "Rosario College of Management Studies, Mangalore",
  "Rukmini Devi Institute of Advanced Studies, Delhi",
  "S. G. Balekundri Institute of Technology, Belagavi",
  "SABRE",
  "SAMSUNG",
  "SANSERA",
  "SCHNEIDER ELECTRIC",
  "SDM College of Business Management, Mangalore",
  "SDM College of Engineering and Technology, Dharwad",
  "SIEMENS",
  "SIR M Visvesvaraya Institute of Technology, Bangalore",
  "SMARTSOC",
  "SONY",
  "SREE VENKATESWARA COLLEGE OF ENGINEERING, Tirupati",
  "SRM Institute of Science and Technology, Sonipat (Delhi NCR)",
  "SRM University, Delhi NCR Campus",
  "Sahyadri College of Engineering & Management, Mangalore",
  "Sahyadri College of Engineering and Management, Mangalore",
  "Sahyadri Science College, Shivamogga",
  "Sai Spurthi Institute of Technology, Khammam",
  "Samskruti College of Engineering and Technology, Hyderabad",
  "Sarla Anil Modi School of Economics, Delhi",
  "Satyawati College, Delhi",
  "School of Inspired Leadership (SOIL), Gurugram (Delhi NCR)",
  "School of Planning and Architecture (SPA), Delhi",
  "Shaheed Bhagat Singh College, Delhi",
  "Shaheed Rajguru College of Applied Sciences for Women, Delhi",
  "Shaheed Sukhdev College of Business Studies, Delhi",
  "Shaikh College of Engineering & Technology, Belagavi",
  "Sharada Group of Institutions, Mangalore",
  "Sharda University, Greater Noida (Delhi NCR)",
  "Shiv Nadar University, Greater Noida (Delhi NCR)",
  "Shiv Nadar University, Noida (Delhi NCR)",
  "Shivaji College, Delhi",
  "Shree Devi College, Mangalore",
  "Shree Devi Institute of Technology, Mangalore",
  "Shri Jayachamarajendra College of Engineering, Mysore",
  "Shri Ram College of Commerce (SRCC), Delhi",
  "Shri Ramakrishna Institute of Technology, Coimbatore",
  "Shyama Prasad Mukherji College for Women, Delhi",
  "Shyamlal College, Delhi",
  "Siddartha Institute of Engineering and Technology, Puttur",
  "Sir M Visvesvaraya Institute of Technology, Bangalore",
  "Siva Sivani Institute of Management, Hyderabad",
  "Skyline Business School, Gurugram (Delhi NCR)",
  "Sree Kavitha Engineering College, Khammam",
  "Sreenidhi Institute of Science and Technology, Hyderabad",
  "Sri Adichunchanagiri College of Pharmacy, Mandya",
  "Sri Aurobindo College, Delhi",
  "Sri Bhagawan Mahaveer Jain College, Bangalore",
  "Sri Chaitanya Engineering College, Hyderabad",
  "Sri Durga Malleswara Siddhartha Mahila Kalasala, Vijayawada",
  "Sri Guru Gobind Singh College of Commerce, Delhi",
  "Sri Guru Nanak Dev Khalsa College, Delhi",
  "Sri Guru Tegh Bahadur Institute of Management and IT, Delhi",
  "Sri Guru Tegh Bahadur Khalsa College, Delhi",
  "Sri Jayachamarajendra College of Engineering (SJCE), Mysore",
  "Sri K. R. S. College of Engineering, Belagavi",
  "Sri Padmavati Mahila Visvavidyalayam, Tirupati",
  "Sri Raghavendra Institute of Science & Technology, Anantapur",
  "Sri Ramakrishna Institute of Technology, Coorg",
  "Sri Sathya Sai Institute of Higher Learning, Anantapur",
  "Sri Siddhartha Institute of Technology, Tumkur",
  "Sri Siddhartha Medical College, Tumkur",
  "Sri Taralabalu Jagadguru Institute of Technology, Ranebennur",
  "Sri Vasavi Engineering College, Tadepalligudem",
  "Sri Venkateswara College, Delhi",
  "Sri Venkateswara Engineering College for Women, Tirupati",
  "Sri Venkateswara Institute of Medical Sciences (SVIMS), Tirupati",
  "Sri Venkateswara University, Tirupati",
  "Srinivas Institute of Technology, Mangalore",
  "St. Agnes College, Mangalore",
  "St. Aloysius College, Mangalore",
  "St. Francis College for Women, Hyderabad",
  "St. Joseph Engineering College, Mangalore",
  "St. Joseph's Degree & PG College, Hyderabad",
  "St. Joseph’s College, Bangalore",
  "St. Mary's College, Yousufguda, Hyderabad",
  "St. Mary’s Group of Institutions, Guntur",
  "St. Stephen’s College, Delhi",
  "Stanley College of Engineering and Technology for Women, Hyderabad",
  "Sunder Deep Group of Institutions, Ghaziabad (Delhi NCR)",
  "Sushant School of Business, Gurugram (Delhi NCR)",
  "Sushant University, Gurugram (Delhi NCR)",
  "Swarnandhra College of Engineering and Technology, Narsapur",
  "Symbiosis Centre for Management Studies (SCMS), Noida (Delhi NCR)",
  "Symbiosis Institute of Business Management (SIBM), Hyderabad",
  "T. John Institute of Technology, Bangalore",
  "TATA ELXSI",
  "TATA TCS NINJA",
  "TCS DIGITAL",
  "TECH MAHINDRA",
  "TEJAS NETWORKS",
  "TESSOLVE",
  "TEXAS INSTRUMENTS",
  "TOSHIBA",
  "TOYOTA KIRLOSKAR MOTOR",
  "TVS ELECTRONICS",
  "Tecnia Institute of Advanced Studies, Delhi",
  "The National Institute of Engineering (NIE), Mysore",
  "The Oxford College of Science, Bangalore",
  "Times School of Media, Delhi",
  "Tirumala Engineering College, Guntur",
  "Trinity College of Engineering, Delhi",
  "Trinity Institute of Management and Technology, Delhi",
  "Trinity Institute of Professional Studies (TIPS), Delhi",
  "UBER",
  "UNBXD",
  "United College of Engineering & Research, Greater Noida (Delhi NCR)",
  "University College, Mangalore",
  "University of Agricultural Sciences, Dharwad",
  "University of Delhi (North and South Campus), Delhi",
  "University of Horticultural Sciences, Bagalkot",
  "University of Hyderabad, Hyderabad",
  "University of Mysore, Mysore",
  "Usharama College of Engineering and Technology, Telaprolu",
  "VAYAVYA",
  "VIRTUSA",
  "VNR Vignana Jyothi Institute of Engineering and Technology, Hyderabad",
  "VR Siddhartha Engineering College, Vijayawada",
  "VVIT Nambur, Guntur",
  "Vardhaman College of Engineering, Hyderabad",
  "Vasavi College of Engineering, Hyderabad",
  "Vasireddy Venkatadri Institute of Technology, Guntur",
  "Venkateshwara Open University, Delhi",
  "Vidya Vikas Institute of Engineering & Technology, Mysore",
  "Vidyamandir Classes, Delhi",
  "Vignan's Institute of Engineering for Women, Visakhapatnam",
  "Vignan's Lara Institute of Technology and Science, Guntur",
  "Vignans Institute of Information Technology, Guntur",
  "Vijaya College, Bangalore",
  "Vikas PU College, Mangalore",
  "Visakha Institute of Engineering & Technology, Visakhapatnam",
  "Vishnu Institute of Technology, Bhimavaram",
  "Visvesvaraya Technological University (VTU) affiliated colleges, Bangalore",
  "Vivekananda College of Engineering and Technology, Puttur",
  "Vivekananda Institute of Professional Studies (VIPS), Delhi",
  "Vivekananda School of Journalism and Mass Communication (VSJMC), Delhi",
  "WESTERN DIGITAL",
  "WIPRO",
  "Yenepoya Institute of Technology, Mangalore",
  "Yogi Vemana University, Kadapa",
  "ZAGGALE",
  "Zakir Husain College, Delhi",
  "facebook meta",
];
export const comapny = [
  "AARBEE",
  "ACCENTURE",
  "ACCOLITE",
  "AFCONS",
  "ALLGOVISION",
  "AMAZON",
  "AMD",
  "APRA LABS",
  "ASIANPAINTS",
  "AURIGO",
  "AXISXADES",
  "BAJAJ FINANCE",
  "BETA",
  "BOSCH",
  "BRILLIO",
  "BROSE IND automotive systems",
  "CISCO",
  "CODECRAFT",
  "COGNIZANT",
  "COMVIVA",
  "CONTENTSTACK",
  "CONTINENTAL",
  "COREEL TECHNOLOGIES",
  "DAIMLER",
  "DASSAULT SYSTEMS",
  "DELL TECHNOLOGIES",
  "DELOITTE",
  "DHIOMICS",
  "DISH NETWORK",
  "DRAUP",
  "DXC TECHNOLOGIES",
  "EDUSTATION",
  "ELASTICRUN",
  "EPSILON",
  "Esko",
  "facebook meta",
  "FALABELLA",
  "GOOGLE",
  "HARMAN",
  "HASHEDIN",
  "HCL",
  "HEXAWARE TECHNOLOGIES",
  "HITECH",
  "HP",
  "IBM",
  "IMPACT ANALYTICS",
  "INFOYS",
  "IN TIME TEC",
  "INTEL",
  "ITC INFOTECH",
  "ITTIAM",
  "JPMORGAN",
  "KNEST",
  "KOTAK LIFE",
  "KPIT",
  "LUMEN",
  "MAPLE LABS",
  "MARUTI SUZUKI",
  "MERCEDES BENZ",
  "Microsoft",
  "MINDTREE",
  "NTELLIPAAT",
  "NUCLEI",
  "ORACLE",
  "PALO ALTO",
  "PEGA",
  "PERSISTENT",
  "PIDILITE",
  "PRINCIPAL",
  "PWC",
  "Qualcomm",
  "QUEST",
  "RADCAM TECHNOLOGIES",
  "RAKUTEN",
  "REDBUS",
  "SABRE",
  "SAMSUNG",
  "SANSERA",
  "SCHNEIDER ELECTRIC",
  "SIEMENS",
  "SMARTSOC",
  "SONY",
  "TATA ELXSI",
  "TATA TCS NINJA",
  "TCS DIGITAL",
  "TECH MAHINDRA",
  "TEJAS NETWORKS",
  "TESSOLVE",
  "TEXAS INSTRUMENTS",
  "TOYOTA KIRLOSKAR MOTOR",
  "TOSHIBA",
  "TVS ELECTRONICS",
  "UBER",
  "UNBXD",
  "VAYAVYA",
  "VIRTUSA",
  "WESTERN DIGITAL",
  "WIPRO",
  "ZAGGALE",
];
export const topics = [
  "Data Science",
  "Software Engineering",
  "Machine Learning",
  "Artificial Intelligence",
  "Cloud Computing",
  "Cybersecurity",
  "Blockchain",
  "DevOps",
  "Full Stack Development",
  "Front-end Development",
  "Back-end Development",
  "Mobile App Development",
  "Augmented Reality",
  "Virtual Reality",
  "Data Analysis",
  "Big Data",
  "Internet of Things (IoT)",
  "Quantum Computing",
  "Natural Language Processing",
  "Robotics",
  "Digital Marketing",
  "Game Development",
  "Web Development",
  "UI/UX Design",
  "Embedded Systems",
  "Computer Vision",
  "Database Management",
  "Automation",
  "Agile Methodologies",
  "Business Intelligence",
  "Software Testing",
  "API Development",
  "Cloud Infrastructure",
  "Edge Computing",
  "Network Security",
  "Penetration Testing",
  "Human-Computer Interaction",
  "Autonomous Systems",
  "Deep Learning",
  "Neural Networks",
  "Computer Graphics",
  "Software Architecture",
  "Product Management",
  "Quantum Machine Learning",
  "Augmented Analytics",
  "Edge AI",
  "Distributed Systems",
  "Serverless Computing",
  "Data Mining",
  "Software Project Management",
  "Mobile Security",
  "Digital Twin Technology",
  "Containerization",
  "Microservices",
  "Ethical Hacking",
  "Smart Contracts",
  "Data Governance",
  "5G Technology",
  "API Design",
  "Computer Networks",
  "Predictive Analytics",
  "Innovation Management",
  "SaaS (Software as a Service)",
];

// Departments
export const fieldsOfStudy = [
  "Computer Science",
  "Electrical and Electronics",
  "Mechanical Engineering",
  "Civil Engineering",
  "Information Technology",
  "Biomedical Engineering",
  "Chemical Engineering",
];

// Intersets
export const interestsOptions = [
  "Computer Science",
  "Electronics",
  "Electrical",
  "Mechanical",
  "Civil",
  "Instrumentation",
  "Chemical",
  "Aerospace",
  "Aeronautical",
  "Agricultural",
  "Mining",
  "Naval and Marine",
  "Metallurgy",
  "Other",
];

export const topicsByInterest = {
  "Computer Science": [
    "Algorithms",
    "Data Structures",
    "Theory of Computation",
    "Compiler Design",
    "Operating Systems",
    "Databases",
    "Computer Networks",
    "Digital Logic",
    "Software Engineering",
    "Web Technologies",
    "Machine Learning",
    "Artificial Intelligence",
    "Cloud Computing",
    "Cybersecurity",
    "Blockchain",
    "Data Science",
    "Natural Language Processing",
    "Robotics",
    "Quantum Computing",
    "Distributed Systems",
    "Big Data",
    "Automation",
    "API Development",
    "Network Security",
    "Penetration Testing",
    "Deep Learning",
    "Data Mining",
    "Mobile Security",
    "Containerization",
    "Ethical Hacking",
    "DevOps",
    "Full Stack Development",
    "Front-end Development",
    "Back-end Development",
    "Mobile App Development",
    "Augmented Reality",
    "Virtual Reality",
    "Data Analysis",
    "Internet of Things (IoT)",
    "Computer Vision",
    "Database Management",
    "Cloud Infrastructure",
    "Edge Computing",
    "Human-Computer Interaction",
    "Autonomous Systems",
    "Computer Graphics",
    "Software Architecture",
    "Quantum Machine Learning",
    "SaaS (Software as a Service)",
    "Predictive Analytics",
    "Smart Contracts",
    "Data Governance",
    "5G Technology",
    "Microservices",
  ],
  Electronics: [
    "Networks and Systems",
    "Control Systems",
    "Electronic Devices and Circuits",
    "Digital Circuits",
    "Analog Circuits",
    "Signals and Systems",
    "Communication Systems",
    "Electromagnetic Theory",
    "Embedded Systems",
    "Microprocessors and Microcontrollers",
    "VLSI Design",
    "Digital Signal Processing (DSP)",
    "Wireless Communication",
    "Optical Communication",
    "Antenna and Wave Propagation",
    "Automation",
    "Robotics",
    "Internet of Things (IoT)",
    "5G Technology",
    "Quantum Computing",
    "Cybersecurity",
    "Edge Computing",
    "Smart Contracts",
    "Blockchain",
    "Digital Twin Technology",
  ],
  Electrical: [
    "Electric Circuits",
    "Electromagnetic Fields",
    "Signals and Systems",
    "Electrical Machines",
    "Power Systems",
    "Control Systems",
    "Power Electronics",
    "Electrical and Electronic Measurements",
    "Analog and Digital Electronics",
    "Digital Signal Processing (DSP)",
    "High Voltage Engineering",
    "Microprocessors and Microcontrollers",
    "Embedded Systems",
    "Automation",
    "Robotics",
    "Internet of Things (IoT)",
    "Smart Grids",
    "Renewable Energy Systems",
    "Smart Meters",
    "Power System Protection",
    "Power Quality",
  ],
  Mechanical: [
    "Engineering Mechanics",
    "Strength of Materials",
    "Theory of Machines",
    "Vibrations",
    "Machine Design",
    "Fluid Mechanics",
    "Heat Transfer",
    "Thermodynamics",
    "Refrigeration and Air Conditioning",
    "Manufacturing Technology",
    "Industrial Engineering",
    "Robotics",
    "Automation",
    "Finite Element Analysis (FEA)",
    "Additive Manufacturing",
    "Mechatronics",
    "Mechanical Design Automation",
    "Computer Aided Design (CAD)",
    "Computer Aided Manufacturing (CAM)",
    "Internet of Things (IoT)",
    "Autonomous Systems",
    "Sustainable Engineering",
  ],
  Civil: [
    "Engineering Mechanics",
    "Strength of Materials",
    "Structural Engineering",
    "Concrete Technology",
    "Geotechnical Engineering",
    "Environmental Engineering",
    "Hydraulics and Fluid Mechanics",
    "Transportation Engineering",
    "Surveying",
    "Construction Management",
    "Building Materials",
    "Water Resources Engineering",
    "Automation",
    "Building Information Modeling (BIM)",
    "Augmented Reality",
    "Virtual Reality",
    "Predictive Analytics",
    "Smart Infrastructure",
    "Sustainable Engineering",
    "Remote Sensing and GIS",
  ],
  Instrumentation: [
    "Electrical Circuits and Measurements",
    "Analog Electronics",
    "Digital Electronics",
    "Signals and Systems",
    "Control Systems",
    "Industrial Instrumentation",
    "Sensors and Transducers",
    "Communication Systems",
    "Process Control",
    "Biomedical Instrumentation",
    "Automation",
    "Robotics",
    "Internet of Things (IoT)",
    "Smart Sensors",
    "Wireless Sensor Networks",
  ],
  Chemical: [
    "Process Calculations",
    "Fluid Mechanics",
    "Heat Transfer",
    "Mass Transfer",
    "Chemical Reaction Engineering",
    "Thermodynamics",
    "Chemical Process Industries",
    "Instrumentation and Process Control",
    "Plant Design and Economics",
    "Process Equipment Design",
    "Safety in Chemical Plants",
    "Environmental Engineering",
    "Automation",
    "Nanotechnology",
    "Sustainable Engineering",
  ],
  Aerospace: [
    "Aerodynamics",
    "Flight Mechanics",
    "Aircraft Structures",
    "Space Dynamics",
    "Propulsion",
    "Flight Control Systems",
    "Avionics",
    "Automation",
    "Robotics",
    "Finite Element Analysis (FEA)",
    "Additive Manufacturing",
    "Fluid Dynamics",
    "Thermodynamics",
    "Heat Transfer",
    "Mechatronics",
    "Sustainable Engineering",
    "Autonomous Systems",
  ],
  Aeronautical: [
    "Aerodynamics",
    "Flight Mechanics",
    "Aircraft Structures",
    "Propulsion",
    "Space Dynamics",
    "Control Systems",
    "Avionics",
    "Vibration and Aeroelasticity",
    "Aircraft Design",
    "Materials and Manufacturing",
    "Finite Element Analysis (FEA)",
    "Computational Fluid Dynamics (CFD)",
    "Heat Transfer",
    "Thermodynamics",
    "Automation",
    "Robotics",
    "Additive Manufacturing",
    "Mechatronics",
    "Flight Control Systems",
    "Structural Dynamics",
    "Fluid Dynamics",
    "Sustainable Aerospace Engineering",
    "Autonomous Systems",
    "Smart Materials",
  ],
  Agricultural: [
    "Soil and Water Conservation Engineering",
    "Irrigation and Drainage Engineering",
    "Farm Machinery and Power",
    "Agricultural Process Engineering",
    "Post-Harvest Technology",
    "Renewable Energy in Agriculture",
    "Crop Production and Management",
    "Agro-Processing",
    "Food Engineering",
    "Agricultural Structures",
    "Soil Science",
    "Water Resources Engineering",
    "Hydrology",
    "Automation in Agriculture",
    "Precision Agriculture",
    "Remote Sensing and GIS",
    "Sustainable Agriculture",
    "Robotics in Agriculture",
    "Drones in Agriculture",
    "Smart Irrigation Systems",
    "Biotechnology in Agriculture",
    "Greenhouse Technology",
    "Agro-Informatics",
    "Post-Harvest Automation",
    "Agricultural Supply Chain Management",
    "Internet of Things (IoT) in Agriculture",
    "Mechanization",
  ],
  Mining: [
    "Mine Development and Planning",
    "Mine Surveying",
    "Geomechanics",
    "Rock Mechanics",
    "Surface Mining",
    "Underground Mining",
    "Mine Ventilation",
    "Mine Environment and Safety",
    "Mining Machinery",
    "Mineral Processing",
    "Mine Economics",
    "Mine Closure and Reclamation",
    "Drilling and Blasting",
    "Mine Power Systems",
    "Mineral Exploration",
    "Mine Automation",
    "Remote Sensing and GIS in Mining",
    "Environmental Impact of Mining",
    "Sustainable Mining Practices",
    "Safety Engineering in Mines",
    "Open Pit Mining",
    "Mine Geology",
    "Coal Mining Technology",
    "Automation in Mining Operations",
    "Robotics in Mining",
    "Drones in Mining",
    "Mine Water Management",
    "Mining Laws and Policies",
  ],
  "Naval and Marine": [
    "Ship Design and Construction",
    "Hydrodynamics",
    "Marine Structures",
    "Marine Propulsion",
    "Marine Hydrodynamics",
    "Ship Stability",
    "Offshore Engineering",
    "Vibration and Noise Control",
    "Marine Engineering Systems",
    "Fluid Mechanics",
    "Marine Electrical Systems",
    "Marine Power Plants",
    "Ship Resistance and Propulsion",
    "Coastal Engineering",
    "Shipyard Management",
    "Marine Materials and Corrosion",
    "Naval Architecture",
    "Automation in Shipbuilding",
    "Ocean Engineering",
    "Submarine Design",
    "Ship Navigation Systems",
    "Marine Robotics",
    "Marine Safety and Environmental Protection",
    "Ship Dynamics",
    "Computer-Aided Ship Design (CASD)",
    "Finite Element Analysis (FEA) in Shipbuilding",
    "Sustainable Marine Engineering",
    "Offshore Renewable Energy",
    "Autonomous Vessels",
    "Marine Diesel Engines",
    "Dredging and Port Engineering",
    "Marine Pollution Control",
    "Maritime Laws and Regulations",
  ],
  Metallurgy: [
    "Materials Science",
    "Physical Metallurgy",
    "Mechanical Metallurgy",
    "Thermodynamics of Materials",
    "Phase Diagrams",
    "Metal Casting",
    "Welding Technology",
    "Heat Treatment",
    "Corrosion Engineering",
    "Composite Materials",
    "Powder Metallurgy",
    "Metal Forming",
    "Surface Engineering",
    "Non-Destructive Testing (NDT)",
    "Metallurgical Thermodynamics",
    "Materials Characterization",
    "Metallurgical Processes",
    "Semiconductors and Electronic Materials",
    "Nanomaterials",
    "Bio-materials",
    "Recycling of Metals",
    "Smart Materials",
    "Material Selection and Testing",
    "Failure Analysis and Prevention",
    "Alloy Design and Development",
    "Advanced Manufacturing Processes",
    "Additive Manufacturing (3D Printing)",
    "Automation in Metallurgy",
    "Industrial Metallurgy",
    "Sustainable Metallurgy",
  ],
  Other: [
    "Digital Marketing",
    "Business Intelligence",
    "Innovation Management",
    "Product Management",
    "Software Project Management",
    "API Development",
    "Agile Methodologies",
    "SaaS (Software as a Service)",
    "Data Analytics",
    "Information Systems Management",
    "E-commerce Technologies",
    "Supply Chain Management",
    "Quality Management",
    "Project Risk Management",
    "Systems Engineering",
    "Human Factors Engineering",
    "Technical Communication",
    "Entrepreneurship",
    "Management Information Systems",
    "Operations Research",
    "Financial Engineering",
    "Renewable Energy Systems",
    "Sustainable Development",
    "Smart Cities",
    "Digital Twin Technology",
    "Virtual Collaboration Tools",
    "Blockchain Applications in Business",
    "Cyber-Physical Systems",
    "Health Informatics",
    "Internet of Things (IoT) Applications",
    "Artificial Intelligence in Business",
    "User Experience (UX) Research",
    "Cloud-Based Solutions for Enterprises",
    "Data Privacy and Security",
    "Digital Transformation in Organizations",
  ],
};
