import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Badge,
  CircularProgress,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  LinearProgress,
} from '@mui/material';
import {
  EmojiEvents as TrophyIcon,
  Code as CodeIcon,
  Psychology as BrainIcon, // Correct import for Psychology icon
  QueryBuilder as ClockIcon,
  People as UsersIcon,
  Star as StarIcon,
  MilitaryTech as AwardIcon,
  ChevronRight as ChevronRightIcon,
  Assessment as AssessmentIcon, // Correct import for Assessment icon
  Cancel as CancelIcon,
  Psychology as Psychology
} from '@mui/icons-material';
import { useSnackbar } from 'notistack';

const ChallengeDashboard = () => {
  const [challenges, setChallenges] = useState([]);
  const [activeTab, setActiveTab] = useState('ALL');
  const [loading, setLoading] = useState(true);
  const [selectedChallenge, setSelectedChallenge] = useState(null);
  const [showLeaderboard, setShowLeaderboard] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [userProgress, setUserProgress] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const difficultyColors = {
    EASY: 'success',
    MEDIUM: 'warning',
    HARD: 'error',
  };

  const typeIcons = {
    APTITUDE: BrainIcon,
    CODING: CodeIcon,
    INTERVIEW: Psychology, // Make sure this points to the correct icon component
  };

  useEffect(() => {
    fetchChallenges();
  }, []);

  const fetchChallenges = async () => {
    try {
      setLoading(true);
      const response = await fetch('http://localhost:8000/api/challange/', {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('token')}` // Assuming token auth
        // }
    
      });

      if (!response.ok) {
        throw new Error('Failed to fetch challenges');
      }

      const data = await response.json();
      console.log(data)
      setChallenges(data);
    } catch (error) {
      enqueueSnackbar('Error fetching challenges: ' + error.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const fetchLeaderboard = async (challengeId) => {
    try {
      const response = await fetch(`http://localhost:8000/api/challenge/${challengeId}/leaderboard`, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('token')}`
        // }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch leaderboard');
      }

      const data = await response.json();
      setLeaderboardData(data);
      setShowLeaderboard(true);
    } catch (error) {
      enqueueSnackbar('Error fetching leaderboard: ' + error.message, { variant: 'error' });
    }
  };

  const fetchUserProgress = async (challengeId) => {
    try {
      const response = await fetch(`http://localhost:8000/api/challenge/${challengeId}/progress`, {
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem('token')}`
        // }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch progress');
      }

      const data = await response.json();
      setUserProgress(data);
    } catch (error) {
      enqueueSnackbar('Error fetching progress: ' + error.message, { variant: 'error' });
    }
  };

  const handleJoinChallenge = async (challengeId) => {
    try {
      const response = await fetch(`http://localhost:8000/api/challenge/${challengeId}/join`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to join challenge');
      }

      enqueueSnackbar('Successfully joined the challenge!', { variant: 'success' });
      fetchChallenges();
      fetchUserProgress(challengeId);
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const submitAnswer = async (challengeId, questionIndex, answer) => {
    try {
      const response = await fetch(`http://localhost:8000/api/challenge/${challengeId}/submit`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ questionIndex, answer })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit answer');
      }

      const data = await response.json();
      enqueueSnackbar('Answer submitted successfully!', { variant: 'success' });
      fetchUserProgress(challengeId);
      return data.analysis;
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      return null;
    }
  };

  const getTimeRemaining = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const diff = end - now;

    if (diff < 0) return 'Expired';

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    return `${days}d ${hours}h remaining`;
  };

  const renderLeaderboardDialog = () => (
    <Dialog
      open={showLeaderboard}
      onClose={() => setShowLeaderboard(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <AssessmentIcon />
          Challenge Leaderboard
        </div>
      </DialogTitle>
      <DialogContent>
        <List>
          {leaderboardData.map((entry) => (
            <ListItem key={entry.rank}>
              <ListItemAvatar>
                <Avatar src={entry.profilePic} />
              </ListItemAvatar>
              <ListItemText
                primary={`#${entry.rank} ${entry.name}`}
                secondary={`Score: ${entry.score}`}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowLeaderboard(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderProgressDialog = () => (
    <Dialog
      open={!!userProgress}
      onClose={() => setUserProgress(null)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <AssessmentIcon />
          Your Progress
        </div>
      </DialogTitle>
      <DialogContent>
        {userProgress && (
          <>
            <Typography variant="h6">
              Status: {userProgress.status}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(userProgress.answeredQuestions / userProgress.totalQuestions) * 100}
              style={{ marginTop: '16px', marginBottom: '8px' }}
            />
            <Typography variant="body2" color="textSecondary">
              {userProgress.answeredQuestions} of {userProgress.totalQuestions} questions completed
            </Typography>
            {userProgress.score && (
              <Typography variant="h6" style={{ marginTop: '16px' }}>
                Current Score: {userProgress.score}
              </Typography>
            )}
            <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
              Time Remaining: {Math.max(0, Math.floor(userProgress.timeRemaining / (1000 * 60)))} minutes
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setUserProgress(null)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div style={{ maxWidth: '1200px', margin: 'auto', padding: '16px' }}>
      {/* Header Section */}
      <div style={{ backgroundColor: '#673ab7', color: 'white', padding: '16px', borderRadius: '8px' }}>
        <Typography variant="h4" component="h1" style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <TrophyIcon />
          Challenge Arena
        </Typography>
        <Typography variant="body1" style={{ marginTop: '8px' }}>
          Compete, Learn, and Earn Rewards!
        </Typography>
      </div>

      {/* Challenge Type Tabs */}
      <div style={{ display: 'flex', gap: '16px', margin: '16px 0', overflowX: 'auto', paddingBottom: '8px' }}>
        <Button variant={activeTab === 'ALL' ? 'contained' : 'outlined'} onClick={() => setActiveTab('ALL')}>
          All
        </Button>
        <Button variant={activeTab === 'CODING' ? 'contained' : 'outlined'} onClick={() => setActiveTab('CODING')}>
          Coding
        </Button>
        <Button variant={activeTab === 'APTITUDE' ? 'contained' : 'outlined'} onClick={() => setActiveTab('APTITUDE')}>
          Aptitude
        </Button>
        <Button variant={activeTab === 'INTERVIEW' ? 'contained' : 'outlined'} onClick={() => setActiveTab('INTERVIEW')}>
          Interview
        </Button>
      </div>

      {/* Loading Indicator */}
      {loading ? (
        <CircularProgress />
      ) : (
        challenges.filter(challenge => activeTab === 'ALL' || challenge.type === activeTab).map((challenge) => (
          <Card key={challenge.id} style={{ margin: '16px 0' }}>
            <CardHeader
              title={challenge.name}
              subheader={`Type: ${challenge.type} | Difficulty: ${challenge.difficulty}`}
              action={
                <IconButton onClick={() => fetchLeaderboard(challenge.id)}>
                  <UsersIcon />
                </IconButton>
              }
            />
            <CardContent>
              <Typography variant="body2">
                {challenge.description}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ marginTop: '8px' }}>
                {getTimeRemaining(challenge.endDate)}
              </Typography>
              <Button
                variant="contained"
                color={difficultyColors[challenge.difficulty]}
                onClick={() => handleJoinChallenge(challenge.id)}
                style={{ marginTop: '16px' }}
              >
                Join Challenge
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => fetchUserProgress(challenge.id)}
                style={{ marginTop: '8px', marginLeft: '8px' }}
              >
                View Progress
              </Button>
            </CardContent>
          </Card>
        ))
      )}

      {renderLeaderboardDialog()}
      {renderProgressDialog()}
    </div>
  );
};

export default ChallengeDashboard;

// import React, { useState, useEffect } from 'react';
// import {
//   Card,
//   CardContent,
//   CardHeader,
//   Typography,
//   Button,
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   List,
//   ListItem,
//   ListItemText,
//   ListItemAvatar,
//   Avatar,
//   LinearProgress,
//   IconButton,
//   CircularProgress,
// } from '@/components/ui/card';
// import { TrophyIcon, Code as CodeIcon, Brain as BrainIcon, Clock as ClockIcon, Users as UsersIcon, ChevronRight as ChevronRightIcon, Assessment as AssessmentIcon } from 'lucide-react';

// const ChallengeDashboard = () => {
//   const [challenges, setChallenges] = useState([]);
//   const [activeTab, setActiveTab] = useState('ALL');
//   const [loading, setLoading] = useState(true);
//   const [showLeaderboard, setShowLeaderboard] = useState(false);
//   const [leaderboardData, setLeaderboardData] = useState([]);
//   const [userProgress, setUserProgress] = useState(null);
//   const [error, setError] = useState(null);

//   const difficultyColors = {
//     EASY: 'text-green-500',
//     MEDIUM: 'text-yellow-500',
//     HARD: 'text-red-500',
//   };

//   const typeIcons = {
//     APTITUDE: BrainIcon,
//     CODING: CodeIcon,
//     INTERVIEW: UsersIcon,
//   };

//   useEffect(() => {
//     fetchChallenges();
//   }, []);

//   const fetchChallenges = async () => {
//     try {
//       setLoading(true);
//       const response = await fetch('http://localhost:8000/api/challange/');
//       if (!response.ok) throw new Error('Failed to fetch challenges');
//       const data = await response.json();
//       setChallenges(data);
//     } catch (error) {
//       setError(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const fetchLeaderboard = async (challengeId) => {
//     try {
//       const response = await fetch(`http://localhost:8000/api/challange/${challengeId}/leaderboard`);
//       if (!response.ok) throw new Error('Failed to fetch leaderboard');
//       const data = await response.json();
//       setLeaderboardData(data);
//       setShowLeaderboard(true);
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const fetchUserProgress = async (challengeId) => {
//     try {
//       const response = await fetch(`http://localhost:8000/api/challange/${challengeId}/progress`);
//       if (!response.ok) throw new Error('Failed to fetch progress');
//       const data = await response.json();
//       setUserProgress(data);
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const handleJoinChallenge = async (challengeId) => {
//     try {
//       const response = await fetch(`http://localhost:8000/api/challange/${challengeId}/join`, {
//         method: 'POST',
//       });
//       if (!response.ok) {
//         const errorData = await response.json();
//         throw new Error(errorData.message);
//       }
//       fetchChallenges();
//       fetchUserProgress(challengeId);
//     } catch (error) {
//       setError(error.message);
//     }
//   };

//   const getTimeRemaining = (endDate) => {
//     const now = new Date();
//     const end = new Date(endDate);
//     const diff = end - now;
    
//     if (diff < 0) return 'Expired';
    
//     const days = Math.floor(diff / (1000 * 60 * 60 * 24));
//     const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    
//     return `${days}d ${hours}h remaining`;
//   };

//   const renderLeaderboardDialog = () => (
//     <Dialog open={showLeaderboard} onClose={() => setShowLeaderboard(false)}>
//       <DialogTitle className="flex items-center gap-2">
//         <AssessmentIcon className="h-5 w-5" />
//         Challenge Leaderboard
//       </DialogTitle>
//       <DialogContent>
//         <List className="divide-y">
//           {leaderboardData.map((entry) => (
//             <ListItem key={entry.rank} className="py-4">
//               <ListItemAvatar>
//                 <Avatar
//                   src={entry.profilePic}
//                   alt={entry.name}
//                   className="h-10 w-10"
//                 />
//               </ListItemAvatar>
//               <ListItemText
//                 primary={`#${entry.rank} ${entry.name}`}
//                 secondary={`Score: ${entry.score}`}
//                 className="ml-4"
//               />
//             </ListItem>
//           ))}
//         </List>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={() => setShowLeaderboard(false)}>
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );

//   const renderProgressDialog = () => (
//     <Dialog open={!!userProgress} onClose={() => setUserProgress(null)}>
//       <DialogTitle className="flex items-center gap-2">
//         <AssessmentIcon className="h-5 w-5" />
//         Your Progress
//       </DialogTitle>
//       <DialogContent>
//         {userProgress && (
//           <div className="space-y-4">
//             <Typography variant="h6">
//               Status: {userProgress.status}
//             </Typography>
//             <div className="w-full">
//               <LinearProgress
//                 value={(userProgress.answeredQuestions / userProgress.totalQuestions) * 100}
//                 className="h-2"
//               />
//               <Typography className="mt-2 text-sm text-gray-600">
//                 {userProgress.answeredQuestions} of {userProgress.totalQuestions} questions completed
//               </Typography>
//             </div>
//             {userProgress.score && (
//               <Typography className="font-semibold">
//                 Current Score: {userProgress.score}
//               </Typography>
//             )}
//             <Typography className="text-sm text-gray-600">
//               Time Remaining: {Math.max(0, Math.floor(userProgress.timeRemaining / (1000 * 60)))} minutes
//             </Typography>
//           </div>
//         )}
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={() => setUserProgress(null)}>
//           Close
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );

//   return (
//     <div className="max-w-7xl mx-auto p-4">
//       <div className="bg-purple-600 text-white p-4 rounded-lg mb-6">
//         <div className="flex items-center gap-2">
//           <TrophyIcon className="h-8 w-8" />
//           <Typography variant="h4">Challenge Arena</Typography>
//         </div>
//         <Typography className="mt-2">
//           Compete, Learn, and Earn Rewards!
//         </Typography>
//       </div>

//       <div className="flex gap-4 overflow-x-auto pb-2 mb-6">
//         {['ALL', 'CODING', 'APTITUDE', 'INTERVIEW'].map(tab => (
//           <Button
//             key={tab}
//             variant={activeTab === tab ? 'default' : 'outline'}
//             onClick={() => setActiveTab(tab)}
//           >
//             {tab}
//           </Button>
//         ))}
//       </div>

//       {loading ? (
//         <div className="flex justify-center">
//           <CircularProgress />
//         </div>
//       ) : error ? (
//         <div className="text-red-500 text-center">{error}</div>
//       ) : (
//         <div className="space-y-4">
//           {challenges
//             .filter(challenge => activeTab === 'ALL' || challenge.type === activeTab)
//             .map((challenge) => (
//               <Card key={challenge._id}>
//                 <CardHeader>
//                   <div className="flex justify-between items-center">
//                     <div>
//                       <Typography variant="h5">{challenge.title}</Typography>
//                       <div className="flex items-center gap-2 mt-1">
//                         <span className={difficultyColors[challenge.difficulty]}>
//                           {challenge.difficulty}
//                         </span>
//                         <span>•</span>
//                         <span>{challenge.type}</span>
//                       </div>
//                     </div>
//                     <IconButton onClick={() => fetchLeaderboard(challenge._id)}>
//                       <UsersIcon className="h-5 w-5" />
//                     </IconButton>
//                   </div>
//                 </CardHeader>
//                 <CardContent>
//                   <Typography className="mb-4">
//                     {challenge.description}
//                   </Typography>
//                   <Typography className="text-sm text-gray-600 mb-4">
//                     {getTimeRemaining(challenge.endDate)}
//                   </Typography>
//                   <div className="flex gap-2">
//                     <Button
//                       variant="default"
//                       onClick={() => handleJoinChallenge(challenge._id)}
//                     >
//                       Join Challenge
//                     </Button>
//                     <Button
//                       variant="outline"
//                       onClick={() => fetchUserProgress(challenge._id)}
//                     >
//                       View Progress
//                     </Button>
//                   </div>
//                 </CardContent>
//               </Card>
//             ))}
//         </div>
//       )}

//       {renderLeaderboardDialog()}
//       {renderProgressDialog()}
//     </div>
//   );
// };

// export default ChallengeDashboard;