import React from "react";

const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-gray-700 mb-4">404</h1>
        <p className="text-2xl font-semibold text-gray-600 mb-8">
          Oops! The page you're looking for doesn't exist.
        </p>
        <a
          href="/"
          className="bg-clip-text bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 text-transparent 
          border border-transparent hover:border-blue-500 p-3 rounded-lg font-semibold transition-all duration-300 w-48 block mx-auto"
        >
          Go Back Home
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
