import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { token } = useSelector((state) => state.auth); // Getting token from the Redux state

  // If token is available, allow access to the route, otherwise redirect to the login page
  return token ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
