// import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   Typography,
//   Box,
//   Card,
//   CardContent,
//   MenuItem,
//   Select,
//   InputLabel,
//   FormControl,
//   useTheme,
//   ThemeProvider,
//   createTheme,
//   alpha,
//   Container,
//   Grid,
// } from "@mui/material";
// import { Autocomplete } from "@mui/material";
// import { motion } from "framer-motion";
// import axios from "axios";
// import { QuestionMark as QuestionIcon } from "@mui/icons-material";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
// import { useNavigate } from "react-router-dom";

// const topics = [
//   "Software Engineering",
//   "Data Science",
//   "Product Management",
//   "Design",
//   "Marketing",
//   "Sales",
//   "Finance",
//   "Human Resources",
// ];

// const interviewTypes = [
//   "Technical",
//   "Behavioral",
//   "HR",
//   "Case Study",
//   "Product",
// ];

// const presetCompanies = [
//   "TCS",
//   "Accenture",
//   "Infosys",
//   "Wipro",
//   "Cognizant",
//   "HCL",
//   "Capgemini",
//   "IBM",
//   "Oracle",
//   "SAP",
// ];

// // Enhanced custom theme
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#4A148C", // A deeper purple
//     },
//     secondary: {
//       main: "#FF6D00", // A vibrant orange for accents
//     },
//     background: {
//       default: "#F0F4F8", // A light blue-gray background
//       paper: "#FFFFFF",
//     },
//   },
//   typography: {
//     fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
//     h4: {
//       fontWeight: 700,
//     },
//   },
//   shape: {
//     borderRadius: 16,
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           textTransform: "none",
//           fontWeight: 600,
//         },
//       },
//     },
//     MuiOutlinedInput: {
//       styleOverrides: {
//         root: {
//           "&:hover .MuiOutlinedInput-notchedOutline": {
//             borderColor: alpha("#4A148C", 0.5),
//           },
//         },
//       },
//     },
//   },
// });

// const QuestionGenerator = ({ onQuestionsGenerated }) => {
//   const navigate = useNavigate();
//   const [topic, setTopic] = useState("");
//   const [companyName, setCompanyName] = useState("");
//   const [interviewType, setInterviewType] = useState("");
//   const [difficultyLevel, setDifficultyLevel] = useState("");

//   // Retrieve userId from localStorage
//   const userId = JSON.parse(localStorage.getItem("user"))?.id;

//   const handleGenerateQuestions = async () => {
//     let minQuestions, maxQuestions;

//     switch (difficultyLevel) {
//       case "Easy":
//         minQuestions = 2;
//         maxQuestions = 5;
//         break;
//       case "Medium":
//         minQuestions = 5;
//         maxQuestions = 10;
//         break;
//       case "Hard":
//         minQuestions = 10;
//         maxQuestions = 20;
//         break;
//       default:
//         minQuestions = 1;
//         maxQuestions = 1;
//     }

//     const numQuestions =
//       Math.floor(Math.random() * (maxQuestions - minQuestions + 1)) +
//       minQuestions;

//     try {
//       const response = await axios.post(
//         `https://hirehack.io/api/interview/generate-questions/${userId}`,
//         {
//           topic,
//           companyName,
//           interviewType,
//           numQuestions,
//         }
//       );

//       const generatedQuestions = response.data.questions;

//       // Store questions in localStorage
//       localStorage.setItem(
//         "generatedQuestions",
//         JSON.stringify(generatedQuestions)
//       );

//       // Redirect to /answer
//       navigate("/answer");

//       onQuestionsGenerated(generatedQuestions);
//     } catch (error) {
//       if (error.response && error.response.status === 403) {
//         toast.error(
//           "Free interview already used. Please make a payment to continue."
//         );
//       } else {
//         toast.error("An error occurred while generating questions.");
//       }
//       console.error("Error generating questions:", error);
//     }
//   };

//   return (
//     <ThemeProvider theme={theme}>
//       <Container maxWidth="md">
//         <Box
//           component={motion.div}
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//           display="flex"
//           justifyContent="center"
//           alignItems="center"
//           minHeight="88vh"
//           py={4}
//         >
//           <Card
//             component={motion.div}
//             whileHover={{ boxShadow: theme.shadows[10] }}
//             transition={{ type: "spring", stiffness: 300 }}
//             sx={{
//               width: "100%",
//               overflow: "hidden",
//               boxShadow: theme.shadows[3],
//               background: "linear-gradient(145deg, #ffffff 0%, #f0f0f0 100%)",
//             }}
//           >
//             <CardContent sx={{ p: 4 }}>
//               <Box display="flex" alignItems="center" mb={4}>
//                 <QuestionIcon
//                   sx={{ fontSize: 48, color: "primary.main", mr: 2 }}
//                 />
//                 <Typography
//                   component={motion.h1}
//                   initial={{ opacity: 0 }}
//                   animate={{ opacity: 1 }}
//                   transition={{ delay: 0.2 }}
//                   variant="h4"
//                   color="primary"
//                 >
//                   Interview Q&A Generator
//                 </Typography>
//               </Box>

//               <Grid container spacing={3}>
//                 <Grid item xs={12} sm={6}>
//                   <Autocomplete
//                     freeSolo
//                     options={presetCompanies}
//                     value={companyName}
//                     onChange={(event, newValue) => setCompanyName(newValue)}
//                     renderInput={(params) => (
//                       <TextField
//                         {...params}
//                         label="Company Name"
//                         fullWidth
//                         variant="outlined"
//                       />
//                     )}
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <FormControl fullWidth variant="outlined">
//                     <InputLabel id="interview-type-select-label">
//                       Interview Type
//                     </InputLabel>
//                     <Select
//                       labelId="interview-type-select-label"
//                       value={interviewType}
//                       onChange={(e) => setInterviewType(e.target.value)}
//                       label="Interview Type"
//                     >
//                       <MenuItem value="" disabled>
//                         Select an interview type
//                       </MenuItem>
//                       {interviewTypes.map((type) => (
//                         <MenuItem key={type} value={type}>
//                           {type}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <FormControl fullWidth variant="outlined">
//                     <InputLabel id="topic-select-label">
//                       Select a Topic
//                     </InputLabel>
//                     <Select
//                       labelId="topic-select-label"
//                       value={topic}
//                       onChange={(e) => setTopic(e.target.value)}
//                       label="Select a Topic"
//                     >
//                       <MenuItem value="" disabled>
//                         Select a topic
//                       </MenuItem>
//                       {topics.map((topicOption) => (
//                         <MenuItem key={topicOption} value={topicOption}>
//                           {topicOption}
//                         </MenuItem>
//                       ))}
//                     </Select>
//                   </FormControl>
//                 </Grid>
//                 <Grid item xs={12} sm={6}>
//                   <FormControl fullWidth variant="outlined">
//                     <InputLabel id="difficulty-level-select-label">
//                       Difficulty Level
//                     </InputLabel>
//                     <Select
//                       labelId="difficulty-level-select-label"
//                       value={difficultyLevel}
//                       onChange={(e) => setDifficultyLevel(e.target.value)}
//                       label="Difficulty Level"
//                     >
//                       <MenuItem value="" disabled>
//                         Select a difficulty level
//                       </MenuItem>
//                       <MenuItem value="Easy">Easy</MenuItem>
//                       <MenuItem value="Medium">Medium</MenuItem>
//                       <MenuItem value="Hard">Hard</MenuItem>
//                     </Select>
//                   </FormControl>
//                 </Grid>
//               </Grid>

//               <Box mt={4}>
//                 <Button
//                   component={motion.button}
//                   whileHover={{ scale: 1.02, boxShadow: theme.shadows[8] }}
//                   whileTap={{ scale: 0.98 }}
//                   variant="contained"
//                   color="primary"
//                   onClick={handleGenerateQuestions}
//                   fullWidth
//                   size="large"
//                   sx={{
//                     py: 2,
//                     fontWeight: "bold",
//                     background:
//                       "linear-gradient(45deg, #4A148C 30%, #7B1FA2 90%)",
//                     "&:hover": {
//                       background:
//                         "linear-gradient(45deg, #7B1FA2 30%, #4A148C 90%)",
//                     },
//                   }}
//                 >
//                   Start Interview
//                 </Button>
//               </Box>
//             </CardContent>
//           </Card>
//         </Box>
//       </Container>
//       <ToastContainer />
//     </ThemeProvider>
//   );
// };

// export default QuestionGenerator;

import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  useTheme,
  ThemeProvider,
  createTheme,
  alpha,
  Container,
  Grid,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { motion } from "framer-motion";
import axios from "axios";
import { QuestionMark as QuestionIcon } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Title from "../../Header/Title";
import BASE_URL from "../../../API/BaseUrl";
import {
  comapny as presetCompanies,
  topicsByInterest,
} from "../../data/CampanyTopic";

const topics = [
  "Software Engineering",
  "Data Science",
  "Product Management",
  "Design",
  "Marketing",
  "Sales",
  "Finance",
  "Human Resources",
];

const interviewTypes = [
  "Technical",
  "Behavioral",
  "HR",
  "Case Study",
  "Product",
];

// const presetCompanies = [
//   "TCS",
//   "Accenture",
//   "Infosys",
//   "Wipro",
//   "Cognizant",
//   "HCL",
//   "Capgemini",
//   "IBM",
//   "Oracle",
//   "SAP",
// ];

// Enhanced custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#4A148C", // A deeper purple
    },
    secondary: {
      main: "#FF6D00", // A vibrant orange for accents
    },
    background: {
      default: "#F0F4F8", // A light blue-gray background
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "'Poppins', 'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 700,
      color: "white", // Change h4 color to white
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontWeight: 600,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: alpha("#4A148C", 0.5),
          },
        },
      },
    },
  },
});

const QuestionGenerator = ({ onQuestionsGenerated }) => {
  const navigate = useNavigate();
  const [topic, setTopic] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [interviewType, setInterviewType] = useState("");
  const [difficultyLevel, setDifficultyLevel] = useState("Medium");
  const user = useSelector((state) => state.auth.user);
  const userInterest = user.interests[0];
  // Retrieve userId from localStorage
  // const userId = JSON.parse(localStorage.getItem("user"))?.id;
  let userId;

  if (user.id === undefined) {
    userId = user._id;
  } else {
    userId = user.id;
  }
  const handleGenerateQuestions = async () => {
    // Enable full screen mode
    enableFullScreen();

    // Set minimum and maximum number of questions based on difficulty level
    let minQuestions, maxQuestions;

    switch (difficultyLevel) {
      case "Easy":
        minQuestions = 2;
        maxQuestions = 5;
        break;
      case "Medium":
        minQuestions = 5;
        maxQuestions = 10;
        break;
      case "Hard":
        minQuestions = 10;
        maxQuestions = 20;
        break;
      default:
        minQuestions = 1;
        maxQuestions = 1;
    }
    const numQuestions =
      Math.floor(Math.random() * (maxQuestions - minQuestions + 1)) +
      minQuestions;
    // Example of getting user's selected interest (you can get this from user data or form input)
    // const userInterest = "Computer Science"; // This will be dynamic, based on user's actual interest

    // Get topics related to the user's interest
    const interestTopics = topicsByInterest[userInterest];

    // Choose 4 random unique topics based on the user's interest
    const selectedTopics = [];
    while (selectedTopics.length < 1) {
      const randomTopic =
        interestTopics[Math.floor(Math.random() * interestTopics.length)];
      if (!selectedTopics.includes(randomTopic)) {
        selectedTopics.push(randomTopic);
      }
    }

    // Join the selected topics with " and " and store them in localStorage
    const topicsString = selectedTopics.join(" and ");
    localStorage.setItem("selectedTopics", topicsString); // Store the selected topics

    // Proceed with API call to generate questions (similar to the previous logic)
    try {
      const response = await axios.post(
        `${BASE_URL}/api/interview/generate-questions/${userId}`,
        {
          topic: topicsString, // Send the joined string of selected topics
          companyName,
          interviewType,
          numQuestions, // Assuming numQuestions logic is already in place
        }
      );

      const generatedQuestions = response.data.questions;

      // Store questions in localStorage
      localStorage.setItem(
        "generatedQuestions",
        JSON.stringify(generatedQuestions)
      );

      // Redirect to /answer
      navigate("/dashboard-layout/answer");

      // Callback to notify that questions have been generated
      onQuestionsGenerated(generatedQuestions);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        toast.error(
          "Free interview already used. Please make a payment to continue."
        );
      } else {
        toast.error("An error occurred while generating questions.");
      }
      console.error("Error generating questions:", error);
    }
  };

  // Function to enable fullscreen mode
  const enableFullScreen = () => {
    const docEl = document.documentElement;

    if (docEl.requestFullscreen) {
      docEl.requestFullscreen();
    } else if (docEl.mozRequestFullScreen) {
      // Firefox
      docEl.mozRequestFullScreen();
    } else if (docEl.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      docEl.webkitRequestFullscreen();
    } else if (docEl.msRequestFullscreen) {
      // IE/Edge
      docEl.msRequestFullscreen();
    }
  };

  // Function to display the fullscreen warning message
  const displayWarningMessage = () => {
    alert(
      "You are now in fullscreen mode. Please do not exit fullscreen mode during the exam to avoid disqualification."
    );
  };
  return (
    <>
      <div className="">
        <Title title="Practice Interview"></Title>
      </div>
      <ThemeProvider theme={theme}>
        <Container maxWidth="md">
          <Box
            component={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={10}
            // minHeight="60vh"
            py={4}
          >
            <Card
              component={motion.div}
              whileHover={{ boxShadow: theme.shadows[10] }}
              transition={{ type: "spring", stiffness: 300 }}
              sx={{
                width: "100%",
                overflow: "hidden",
                boxShadow: theme.shadows[3],
                background: "#FFFFFF12", // Semi-transparent white background
              }}
            >
              <CardContent sx={{ p: 4 }}>
                <Box display="flex" alignItems="center" mb={4}>
                  <QuestionIcon sx={{ fontSize: 48, color: "white", mr: 2 }} />
                  <Typography
                    component={motion.h1}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    variant="h4"
                  >
                    Interview Q&A Generator
                  </Typography>
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Autocomplete
                      freeSolo
                      options={presetCompanies}
                      value={companyName}
                      onChange={(event, newValue) => setCompanyName(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label="Company Name"
                          variant="outlined"
                          InputLabelProps={{
                            style: { color: "white" }, // Label color
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              color: "white", // Text color for input
                              "& fieldset": {
                                borderColor: "white", // Border color
                              },
                              "&:hover fieldset": {
                                borderColor: "white", // Border color on hover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "white", // Border color when focused
                              },
                            },
                            "& .MuiInputLabel-root": {
                              color: "white", // Label color
                            },
                            "& .MuiAutocomplete-popupIndicator": {
                              color: "white", // Dropdown arrow color
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="interview-type-select-label"
                        style={{ color: "white" }} // Label color
                      >
                        Interview Type
                      </InputLabel>
                      <Select
                        labelId="interview-type-select-label"
                        value={interviewType}
                        onChange={(e) => setInterviewType(e.target.value)}
                        label="Interview Type"
                        sx={{
                          color: "white", // Text color for select
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color when focused
                          },
                          "& .MuiSelect-icon": {
                            color: "white", // Dropdown icon color
                          },
                        }}
                      >
                        <MenuItem value="" disabled style={{ color: "black" }}>
                          Select an interview type
                        </MenuItem>
                        {interviewTypes.map((type) => (
                          <MenuItem
                            key={type}
                            value={type}
                            style={{ color: "black" }}
                          >
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="topic-select-label"
                        style={{ color: "white" }} // Label color
                      >
                        Select a Topic
                      </InputLabel>
                      <Select
                        labelId="topic-select-label"
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                        label="Select a Topic"
                        sx={{
                          color: "white", // Text color for select
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color when focused
                          },
                          "& .MuiSelect-icon": {
                            color: "white", // Dropdown icon color
                          },
                        }}
                      >
                        <MenuItem value="" disabled style={{ color: "black" }}>
                          Select a topic
                        </MenuItem>
                        {topics.map((topicOption) => (
                          <MenuItem
                            key={topicOption}
                            value={topicOption}
                            style={{ color: "black" }} // Keep menu item color as black
                          >
                            {topicOption}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid> */}
                  {/* <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel
                        id="difficulty-level-select-label"
                        style={{ color: "white" }}
                      >
                        Difficulty Level
                      </InputLabel>
                      <Select
                        labelId="difficulty-level-select-label"
                        value={difficultyLevel}
                        onChange={(e) => setDifficultyLevel(e.target.value)}
                        label="Difficulty Level"
                        // sx={{ color: "white" }} // Text color for select
                        sx={{
                          color: "white", // Text color for select
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color on hover
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white", // Border color when focused
                          },
                          "& .MuiSelect-icon": {
                            color: "white", // Dropdown icon color
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select a difficulty level
                        </MenuItem>
                        <MenuItem value="Easy" style={{ color: "black" }}>
                          Easy
                        </MenuItem>
                        <MenuItem value="Medium" style={{ color: "black" }}>
                          Medium
                        </MenuItem>
                        <MenuItem value="Hard" style={{ color: "black" }}>
                          Hard
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid> */}
                </Grid>

                <Box mt={4}>
                  <Button
                    component={motion.button}
                    variant="contained"
                    onClick={handleGenerateQuestions}
                    fullWidth
                    size="large"
                    sx={{
                      fontSize: "18px",
                      height: "60px",
                      textTransform: "none",
                      fontWeight: 600,
                      boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Adding white shadow
                    }}
                    className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-48 font-semibold btn-alt"
                    initial={{ backgroundPosition: "200% center" }}
                    animate={{ backgroundPosition: "0% center" }}
                    transition={{ duration: 0.2, ease: "linear" }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Start Interview
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
        <ToastContainer />
      </ThemeProvider>
    </>
  );
};

export default QuestionGenerator;

// import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   Typography,
//   Box,
//   Card,
//   CardContent,
//   MenuItem,
//   Select,
//   InputLabel,
//   FormControl,
//   useTheme,
//   ThemeProvider,
//   createTheme,
//   Container,
//   Grid,
// } from "@mui/material";
// import { Autocomplete } from "@mui/material";
// import { motion } from "framer-motion";
// import { QuestionMark as QuestionIcon } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import Title from "../Header/Title";

// const interviewTypes = [
//   "Technical",
//   "Behavioral",
//   "HR",
//   "Case Study",
//   "Product",
// ];

// const presetCompanies = [
//   "TCS",
//   "Accenture",
//   "Infosys",
//   "Wipro",
//   "Cognizant",
//   "HCL",
//   "Capgemini",
//   "IBM",
//   "Oracle",
//   "SAP",
// ];

// // Hardcoded random topics
// const randomTopics = "Data Science, Software Engineering, Marketing";

// const theme = createTheme({
//   palette: {
//     primary: { main: "#4A148C" },
//     secondary: { main: "#FF6D00" },
//     background: { default: "#F0F4F8", paper: "#FFFFFF" },
//   },
//   typography: {
//     fontFamily: "'Poppins', 'Roboto', sans-serif",
//     h4: { fontWeight: 700, color: "white" },
//   },
// });

// const QuestionGenerator = ({ onQuestionsGenerated }) => {
//   const navigate = useNavigate();
//   const [companyName, setCompanyName] = useState("");
//   const [interviewType, setInterviewType] = useState("");

//   const handleGenerateQuestions = async () => {
//     // Automatically set difficulty level to "Moderate"
//     const difficultyLevel = "Moderate";

//     // Log or use the company name, interview type, topics, and difficulty level
//     console.log("Company:", companyName);
//     console.log("Interview Type:", interviewType);
//     console.log("Topics:", randomTopics);
//     console.log("Difficulty Level:", difficultyLevel);

//     // const handleGenerateQuestions = async () => {
//     try {
//       // Enable full screen mode
//       enableFullScreen();

//       // Determine number of questions based on difficulty level
//       let minQuestions, maxQuestions;

//       switch (difficultyLevel) {
//         case "Easy":
//           minQuestions = 2;
//           maxQuestions = 5;
//           break;
//         case "Medium":
//           minQuestions = 5;
//           maxQuestions = 10;
//           break;
//         case "Hard":
//           minQuestions = 10;
//           maxQuestions = 20;
//           break;
//         default:
//           minQuestions = 1;
//           maxQuestions = 1;
//       }

//       // Randomly select the number of questions within the range
//       const numQuestions =
//         Math.floor(Math.random() * (maxQuestions - minQuestions + 1)) +
//         minQuestions;

//       // Make API call to generate questions
//       const response = await axios.post(
//         `https://hirehack.io/api/interview/generate-questions/${userId}`,
//         {
//           topic,
//           companyName,
//           interviewType,
//           numQuestions,
//         }
//       );

//       // Extract questions from the API response
//       const generatedQuestions = response.data.questions;

//       // Check if questions are generated and store them in localStorage
//       if (generatedQuestions && generatedQuestions.length > 0) {
//         localStorage.setItem(
//           "generatedQuestions",
//           JSON.stringify(generatedQuestions)
//         );

//         // Navigate to /answer page after generating questions
//         navigate("/dashboard-layout/answer");

//         // Call onQuestionsGenerated callback with the generated questions
//         onQuestionsGenerated(generatedQuestions);
//       } else {
//         throw new Error("No questions generated");
//       }
//     } catch (error) {
//       // Check if error is due to free interview limit
//       if (error.response && error.response.status === 403) {
//         toast.error(
//           "Free interview already used. Please make a payment to continue."
//         );
//       } else {
//         // Show a general error message for any other errors
//         toast.error("An error occurred while generating questions.");
//       }

//       console.error("Error generating questions:", error);
//     }

//     // You can proceed with any further logic here or redirect the user
//     // navigate("/dashboard-layout/answer");
//   };

//   return (
//     <>
//       <div>
//         <Title title="Practice Interview" />
//       </div>
//       <ThemeProvider theme={theme}>
//         <Container maxWidth="md">
//           <Box
//             component={motion.div}
//             initial={{ opacity: 0, y: 20 }}
//             animate={{ opacity: 1, y: 0 }}
//             transition={{ duration: 0.5 }}
//             display="flex"
//             justifyContent="center"
//             alignItems="center"
//             minHeight="88vh"
//             py={4}
//           >
//             <Card
//               sx={{
//                 width: "100%",
//                 boxShadow: theme.shadows[3],
//                 background: "#FFFFFF12",
//               }}
//             >
//               <CardContent sx={{ p: 4 }}>
//                 <Box display="flex" alignItems="center" mb={4}>
//                   <QuestionIcon sx={{ fontSize: 48, color: "white", mr: 2 }} />
//                   <Typography variant="h4">Interview Q&A Generator</Typography>
//                 </Box>
//                 <Grid container spacing={3}>
//                   <Grid item xs={12} sm={6}>
//                     <Autocomplete
//                       freeSolo
//                       options={presetCompanies}
//                       value={companyName}
//                       onChange={(event, newValue) => setCompanyName(newValue)}
//                       renderInput={(params) => (
//                         <TextField
//                           {...params}
//                           fullWidth
//                           label="Company Name"
//                           variant="outlined"
//                           InputLabelProps={{ style: { color: "white" } }}
//                           sx={{
//                             "& .MuiOutlinedInput-root": {
//                               color: "white",
//                               "& fieldset": { borderColor: "white" },
//                             },
//                           }}
//                         />
//                       )}
//                     />
//                   </Grid>

//                   <Grid item xs={12} sm={6}>
//                     <FormControl fullWidth variant="outlined">
//                       <InputLabel style={{ color: "white" }}>
//                         Interview Type
//                       </InputLabel>
//                       <Select
//                         value={interviewType}
//                         onChange={(e) => setInterviewType(e.target.value)}
//                         label="Interview Type"
//                         sx={{
//                           color: "white",
//                           "& .MuiOutlinedInput-notchedOutline": {
//                             borderColor: "white",
//                           },
//                         }}
//                       >
//                         <MenuItem value="" disabled style={{ color: "black" }}>
//                           Select an interview type
//                         </MenuItem>
//                         {interviewTypes.map((type) => (
//                           <MenuItem
//                             key={type}
//                             value={type}
//                             style={{ color: "black" }}
//                           >
//                             {type}
//                           </MenuItem>
//                         ))}
//                       </Select>
//                     </FormControl>
//                   </Grid>
//                 </Grid>

//                 <Box mt={4}>
//                   <Button
//                     component={motion.button}
//                     variant="contained"
//                     onClick={handleGenerateQuestions}
//                     fullWidth
//                     size="large"
//                     sx={{
//                       fontSize: "18px",
//                       height: "60px",
//                       textTransform: "none",
//                       fontWeight: 600,
//                       boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Adding white shadow
//                     }}
//                     className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-48 font-semibold btn-alt"
//                     initial={{ backgroundPosition: "200% center" }}
//                     animate={{ backgroundPosition: "0% center" }}
//                     transition={{ duration: 0.2, ease: "linear" }}
//                     whileHover={{ scale: 1.05 }}
//                     whileTap={{ scale: 0.95 }}
//                   >
//                     Start Interview
//                   </Button>
//                 </Box>
//               </CardContent>
//             </Card>
//           </Box>
//         </Container>
//       </ThemeProvider>
//     </>
//   );
// };

// export default QuestionGenerator;
