import React from "react";
import { Container, Typography, Paper, Divider } from "@mui/material";
import { motion } from "framer-motion";
import "tailwindcss/tailwind.css";
import Footer from "../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
const containerVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5 } },
};

const TermsAndConditions = () => {
  const navigate = useNavigate();
  const redirectiontocontactpage = () => {
    navigate("/contact");
  };
  return (
    <>
      <Container maxWidth="md" className="my-8 mt-20 p-4">
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="bg-white shadow-lg rounded-lg p-6"
        >
          <Typography variant="h4" component="h1" color="primary" gutterBottom>
            Terms and Conditions
          </Typography>

          <Typography
            variant="h5"
            component="h2"
            color="textSecondary"
            gutterBottom
          >
            Privacy Policy
          </Typography>
          <Paper elevation={3} className="p-4 mb-4 bg-gray-100">
            <Typography variant="body1" paragraph>
              At HireHack, we are committed to protecting your privacy. This
              Privacy Policy outlines how we collect, use, and safeguard your
              personal information when you use our platform. We encourage you
              to read this policy carefully to understand our practices
              regarding your data.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              1. Information We Collect
            </Typography>
            <Typography variant="body1" paragraph>
              We may collect personal information such as your name, email
              address, phone number, and payment information when you sign up
              for an account or subscribe to a plan. Additionally, we collect
              information about your usage of the platform, including your
              interview sessions, feedback, and progress tracking.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              2. How We Use Your Information
            </Typography>
            <Typography variant="body1" paragraph>
              We use your personal information to provide and improve our
              services, personalize your experience, and communicate with you
              about your account. Your data is also used to generate feedback
              and performance reports, as well as to recommend resources
              tailored to your needs.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              3. Data Security
            </Typography>
            <Typography variant="body1" paragraph>
              We implement security measures to protect your personal
              information from unauthorized access, alteration, or disclosure.
              Your data is encrypted and stored securely on our servers.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              4. Sharing of Information
            </Typography>
            <Typography variant="body1" paragraph>
              We do not sell or share your personal information with third
              parties without your consent, except as required by law or to
              provide our services (e.g., payment processing).
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              5. Your Rights
            </Typography>
            <Typography variant="body1" paragraph>
              You have the right to access, update, or delete your personal
              information at any time. You can manage your data through your
              account settings or contact our support team for assistance.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              6. Changes to This Policy
            </Typography>
            <Typography variant="body1" paragraph>
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page, and we will notify you of significant
              updates via email or platform notifications.
            </Typography>
          </Paper>

          <Typography
            variant="h5"
            component="h2"
            color="textSecondary"
            gutterBottom
          >
            Terms of Service
          </Typography>
          <Paper elevation={3} className="p-4 mb-4 bg-gray-100">
            <Typography variant="body1" paragraph>
              By using the HireHack platform, you agree to comply with the
              following Terms of Service. These terms govern your access to and
              use of our services. Please read them carefully before using the
              platform.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              1. User Accounts
            </Typography>
            <Typography variant="body1" paragraph>
              To access HireHack, you must create an account and provide
              accurate, up-to-date information. You are responsible for
              maintaining the confidentiality of your account login details and
              for all activities that occur under your account.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              2. Subscription Plans
            </Typography>
            <Typography variant="body1" paragraph>
              HireHack offers both free and paid subscription plans. By
              subscribing to a paid plan, you agree to provide valid payment
              information and authorize us to charge your payment method for the
              subscription fees.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              3. Acceptable Use
            </Typography>
            <Typography variant="body1" paragraph>
              You agree to use the platform only for lawful purposes and in
              accordance with these terms. You may not use HireHack to
              distribute harmful or malicious content, violate the rights of
              others, or engage in any activity that interferes with the
              platform’s functionality.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              4. Termination
            </Typography>
            <Typography variant="body1" paragraph>
              We reserve the right to suspend or terminate your account if you
              violate these terms or engage in any conduct that we deem harmful
              to the platform or other users.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              5. Limitation of Liability
            </Typography>
            <Typography variant="body1" paragraph>
              HireHack is not liable for any indirect, incidental, or
              consequential damages arising from your use of the platform. Our
              total liability for any claim related to your use of HireHack
              shall not exceed the amount paid for your subscription.
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              6. Governing Law
            </Typography>
            <Typography variant="body1" paragraph>
              These Terms of Service are governed by and construed in accordance
              with the laws of [Jurisdiction]. Any legal actions related to
              these terms must be brought in the courts located in
              [Jurisdiction].
            </Typography>
            <Typography
              variant="h6"
              component="h3"
              color="textSecondary"
              gutterBottom
            >
              7. Changes to the Terms
            </Typography>
            <Typography variant="body1" paragraph>
              We may update these Terms of Service from time to time. Any
              changes will be posted on this page, and we will notify you of
              significant updates via email or platform notifications.
            </Typography>
          </Paper>

          <Typography
            variant="h5"
            component="h2"
            color="textSecondary"
            gutterBottom
          >
            Contact Us
          </Typography>
          <Typography variant="body1" paragraph>
            If you have any questions or concerns about this Privacy Policy or
            Terms of Service, please contact us at:
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Email:</strong> admin@hirehack.io
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Phone:</strong> +91 7019713085
          </Typography>
          <motion.button
            className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-3.5 w-64 font-bold btn-alt"
            style={{
              borderRadius: "32px",
            }}
            initial={{ backgroundPosition: "200% center" }}
            animate={{ backgroundPosition: "0% center" }}
            transition={{ duration: 0.2, ease: "linear" }}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.95 }}
            onClick={redirectiontocontactpage}
          >
            Contact
          </motion.button>
        </motion.div>
      </Container>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
