import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Line } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import CustomTitle from "../../Header/Title";
import BASE_URL from "../../../API/BaseUrl";
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding: 30px;
	/* background-color: #1c1e2b; Darker background */
	min-height: 100vh; /* Cover full screen height */

	@media (max-width: 768px) {
		padding: 15px;
		gap: 15px;
	}

	@media (max-width: 320px) {
		padding: 0.5rem;
		gap: 15px;
	}
`;

const Block = styled.div`
	background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent */
	backdrop-filter: blur(20px); /* Glass effect */
	color: #fff;
	border-radius: 12px;
	padding: 25px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s, box-shadow 0.3s;

	&:hover {
		transform: translateY(-5px);
		box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Subtle lift effect */
	}

	@media (max-width: 768px) {
		padding: 15px;
	}

	@media (max-width: 320px) {
		padding: 8px;
	}
`;

const NoDataContainer = styled(Block)`
	width: 100%;
	max-width: 100%;
	padding: 2.5rem 2.5rem 5rem 2.5rem;
	color: #ffffff;
	text-align: center;
	font-size: 1.2rem;
	margin: 20px auto;
`;

const GraphContainer = styled(Block)`
	width: 70%;
	max-width: 70%;
	height: 320px;
	padding: 2.5rem 2.5rem 5rem 2.5rem;

	@media (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
		padding: 2.5rem 2.5rem 5rem 2.5rem;
	}

	@media (max-width: 768px) {
		width: 100%;
		max-width: 100%;
		height: 350px;
		padding: 2.5rem 2.5rem 5rem 2.5rem;
	}

	@media (max-width: 320px) {
		height: 260px;
		padding: 0.5rem;
	}
`;

const PerformanceContainer = styled(Block)`
	width: 30%;
	max-width: 30%;
	height: 320px;
	padding: 2.5rem;
	display: flex;
	flex-direction: column;

	@media (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
		height: 200px;
	}

	@media (max-width: 768px) {
		height: 200px;
		padding: 1.5rem;
	}

	@media (max-width: 320px) {
		height: 180px;
		padding: 0.5rem;
	}
`;

const InterviewListContainer = styled(Block)`
	width: 100%;
	height: 450px;
	overflow-y: auto;

	/* Hide scrollbar for Chrome, Safari, Edge */
	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for Firefox */
	scrollbar-width: none;

	/* Hide scrollbar for Internet Explorer 10+ */
	-ms-overflow-style: none;

	@media (max-width: 768px) {
		height: 330px;
		padding: 1.5rem;
	}

	@media (max-width: 320px) {
		height: 200px;
		padding: 0.5rem;
	}
`;

const AnalysisContainer = styled(Block)`
	width: 100%;
	height: 450px;
	overflow-y: auto;

	/* Hide scrollbar for Chrome, Safari, Edge */
	&::-webkit-scrollbar {
		display: none;
	}

	/* Hide scrollbar for Firefox */
	scrollbar-width: none;

	/* Hide scrollbar for Internet Explorer 10+ */
	-ms-overflow-style: none;

	@media (max-width: 768px) {
		height: 330px;
		padding: 1.5rem;
	}

	@media (max-width: 320px) {
		height: 250px;
		padding: 0.5rem;
	}

	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const InterviewItem = styled.div`
	padding: 15px;
	cursor: pointer;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	transition: background-color 0.3s, color 0.3s;

	&:hover {
		background-color: #24075b;
		color: #ffffff;
		border-bottom: none;
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(255, 255, 255, 0.1); /* Highlight hover */
	}
`;
const SuggestionList = styled.ul`
	color: #dddddd;
	font-size: 1rem;
	margin-left: 10px;
	@media (max-width: 320px) {
		font-size: 0.8rem;
	}
`;

const SuggestionItem = styled.li`
	margin-bottom: 1px;
`;

const ResponsiveFlexContainer = styled.div`
	display: flex;
	gap: 30px;
	justify-content: space-between;

	@media (max-width: 1024px) {
		flex-direction: column;
	}

	@media (max-width: 320px) {
		gap: 15px;
	}
`;

const Strong = styled.strong`
	font-size: 1.1rem;
	font-weight: 600;
	letter-spacing: 0.08rem;
	color: #d08aff;

	@media (max-width: 320px) {
		font-size: 1rem;
	}
`;
const StrongWhite = styled.strong`
	font-size: 1.1rem;
	font-weight: 600;
	letter-spacing: 0.08rem;
	color: #fff;

	@media (max-width: 320px) {
		font-size: 1rem;
	}
`;

const Heading = styled.h1`
	color: #ffffff;
	margin-bottom: 1.5rem;

	${(props) =>
		props.as === "h1" &&
		css`
			font-size: 1.8rem;
			font-weight: 600;

			@media (max-width: 768px) {
				font-size: 1.5rem;
			}

			@media (max-width: 320px) {
				font-size: 1rem;
			}
		`}

	${(props) =>
		props.as === "h2" &&
		css`
			font-size: 1.2rem;
			font-weight: 500;
		`}
    
    ${(props) =>
		props.as === "h3" &&
		css`
			font-size: 2.5rem;
			font-weight: 600;

			@media (max-width: 320px) {
				font-size: 1.6rem;
			}
		`}

	${(props) =>
		props.as === "h4" &&
		css`
			font-size: 3.2rem;
			font-weight: 700;
			text-align: center;

			@media (max-width: 320px) {
				font-size: 2rem;
			}
		`}

	line-height: 1.4;
`;

const StyledPerfectScrollbarInterview = styled(PerfectScrollbar)`
	height: 100%;
	width: 25%;
	max-width: 25%;
	flex: 1;

	@media (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
	}

	@media (max-width: 320px) {
		width: 100%;
		max-width: 100%;
	}
`;

const StyledPerfectScrollbarAnalysis = styled(PerfectScrollbar)`
	height: 100%;
	width: 75%;
	max-width: 75%;
	flex: 1;

	@media (max-width: 1024px) {
		width: 100%;
		max-width: 100%;
	}
`;

const HeadingAnalysis = styled.h1`
	font-size: 2.2rem;
	font-weight: 600;
	margin-bottom: 1.5rem;

	@media (max-width: 320px) {
		font-size: 1.2rem;
	}
`;

const SubHeadingAnaylsis = styled.h4`
	font-size: 1.4rem;
	letter-spacing: 0.04rem;
	font-weight: 600;
	color: #e0e0e0;

	@media (max-width: 320px) {
		font-size: 1rem;
	}
`;
const Score = styled.p`
	font-size: 1.2rem;
	font-weight: bold;
	color: #f1c40f;

	@media (max-width: 320px) {
		font-size: 1rem;
	}
`;

const QuestionContainer = styled.div`
	/* margin-top: 1.5rem; */
	padding: 1.5rem;
	background: rgba(255, 255, 255, 0.1);
	border-radius: 10px;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
	&:not(:first-child) {
		margin-top: 1.5rem; /* Apply top margin to every element except the first one */
	}
	@media (max-width: 320px) {
		padding: 1.2rem;
	}
`;

const QuestionTitle = styled.h3`
	font-size: 1.3rem;
	letter-spacing: 0.04rem;
	font-weight: bold;
	color: white;

	@media (max-width: 320px) {
		font-size: 1rem;
	}
`;

const AnswerText = styled.p`
	font-size: 1rem;
	color: #dddddd;
	margin: 0.5rem 0;

	@media (max-width: 320px) {
		font-size: 0.8rem;
	}
`;

const FeedbackText = styled.p`
	font-size: 0.9rem;
	letter-spacing: 0.02rem;
	font-style: italic;
	color: #b2beb5;
	margin: 0.5rem 0;
`;

const InfoContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	font-size: 1.2rem;
	width: 100%; /* Ensure it spans the full width of the container */

	@media (max-width: 320px) {
		align-items: flex-start;
		flex-direction: column;
	}
`;

const InfoText = styled.div`
	display: flex;
	align-items: center;
	font-weight: bold;

	@media (max-width: 320px) {
		font-size: 1rem;
	}
`;

const Label = styled.span`
	font-weight: bold;
	color: #ffffff;
	margin-right: 0.5rem; /* Adds some spacing between the label and the value */
`;

const Value = styled.span`
	color: #e0e0e0; /* Light gray for the value text */
`;

const ScoreText = styled.div`
	display: flex;
	align-items: center;
	font-weight: bold;

	strong {
		color: #d08aff; /* Bright yellow or orange, can be adjusted to your preference */
	}

	@media (max-width: 320px) {
		font-size: 1rem;
	}
`;

const ScoreValue = styled.span`
	color: #ffffff;
	margin-left: 0.5rem;
`;

const InterviewItemContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	width: 100%; /* Full width */
`;

const InfoTextList = styled.div`
	display: flex;
	align-items: center;
	font-weight: bold;
	color: #ffffff; /* Ensure text is white for visibility */
`;

const LabelList = styled.span`
	font-weight: bold;
	color: #ffffff;
	margin-right: 0.5rem; /* Adds spacing between the label and dash */
`;

const DateContainer = styled.div`
	font-size: 0.8rem;
	font-weight: bold;
	color: #ffffff; /* Ensure text is white for visibility */
`;

const ChartWrapper = styled.div`
	width: 60%;
	overflow: hidden;

	@media (max-width: 1024px) {
		width: 60%;
		overflow: auto;
	}

	@media (max-width: 768px) {
		width: 75%;
		overflow: hidden;
		padding: 10px;
	}

	@media (max-width: 480px) {
		width: 100%;
		padding: 8px;
	}

	@media (max-width: 320px) {
		width: 100%;
		padding: 5px;
		overflow: auto;

		canvas {
			width: 100% !important;
			height: auto !important;
		}
	}

	canvas {
		width: 100%; // Default to 100% width to adapt to the container
		height: auto;
	}
`;

const Dashboard = () => {
	const [interviews, setInterviews] = useState([]);
	const [aptitude, setAptitude] = useState([]);
	const [selectedInterview, setSelectedInterview] = useState(null);
	const [analysisData, setAnalysisData] = useState(null);
	const [performanceComparison, setPerformanceComparison] = useState(null);
	const user = useSelector((state) => state.auth.user);
	let userId;

	if (user.id === undefined) {
		userId = user._id;
	} else {
		userId = user.id;
	}
	useEffect(() => {
		const fetchInterviews = async () => {
			try {
				const response = await fetch(
					`${BASE_URL}/api/interview/interviews/${userId}`
				);
				const data = await response.json();
				const reversedAptitude = data;
				setAptitude(reversedAptitude);

				const reversedInterviews = data.regularInterviews.slice().reverse();
				setInterviews(reversedInterviews);
				setSelectedInterview(reversedInterviews[0]);
				fetchAnalysis(reversedInterviews[0].id);
			} catch (error) {
				console.error("Error fetching interviews:", error);
			}
		};

		fetchInterviews();
	}, []);

	const fetchAnalysis = async (interviewId) => {
		try {
			const response = await fetch(
				`${BASE_URL}/api/interview/assessment-analysis/${userId}/${interviewId}`
			);
			const data = await response.json();
			setAnalysisData(data);
			setPerformanceComparison(data.performanceComparison);
		} catch (error) {
			console.error("Something went wrong !");
		}
	};

	const handleInterviewClick = (interview) => {
		setSelectedInterview(interview);
		fetchAnalysis(interview.id);
	};

	const lineGraphOptions = {
		scales: {
			x: {
				ticks: {
					color: "#ffffff",
				},
				grid: {
					color: "rgba(255, 255, 255, 0.1)",
				},
			},
			y1: {
				type: "linear",
				position: "left",
				ticks: {
					color: "#ffffff",
				},
				grid: {
					color: "rgba(255, 255, 255, 0.1)",
				},
			},
			y2: {
				type: "linear",
				position: "right",
				ticks: {
					color: "#ffffff",
				},
				grid: {
					drawOnChartArea: false,
				},
			},
		},
		plugins: {
			legend: {
				labels: {
					color: "#ffffff",
				},
			},
		},
	};

	const lineGraphData = {
		labels:
			performanceComparison?.historicalTrend?.recentScores?.map((scoreObj) =>
				new Date(scoreObj.date).toLocaleDateString()
			) || [],
		datasets: [
			{
				label: "Interview Scores",
				data:
					performanceComparison?.historicalTrend?.recentScores?.map(
						(scoreObj) => scoreObj.score
					) || [],
				borderColor: "#d08aff",
				backgroundColor: "#d08aff",
				fill: true,
				tension: 0.3,
				yAxisID: "y1",
			},
			{
				label: "Aptitude Test Scores",
				data:
					aptitude?.aptitudeTests?.map(
						(scoreObj) => scoreObj.percentage ?? 0
					) || [],
				borderColor: "#FFA500",
				backgroundColor: "#FFA500",
				fill: true,
				tension: 0.3,
				yAxisID: "y2",
			},
		],
	};

	const capitalizeFirstLetter = (string) => {
		return string
			? string.charAt(0).toUpperCase() + string.slice(1)
			: "Data not available";
	};

	return (
		<>
			<div>
				<CustomTitle title="Analytics"></CustomTitle>
			</div>
			<Container>
				{!performanceComparison || interviews.length === 0 || !analysisData ? (
					<NoDataContainer>
						<Heading as={"h1"}>
							No data available! Please give an interview.
						</Heading>
					</NoDataContainer>
				) : (
					<>
						<ResponsiveFlexContainer>
							<GraphContainer>
								<Heading as={"h1"} style={{ color: "#ffffff" }}>
									Interview Progress Over Time
								</Heading>
								<ChartWrapper>
									<Line data={lineGraphData} options={lineGraphOptions} />
								</ChartWrapper>
							</GraphContainer>

							<PerformanceContainer>
								<Heading as={"h1"} style={{ color: "#ffffff" }}>
									Performance Review
								</Heading>
								{performanceComparison ? (
									<>
										<p>
											<Strong>Current Status :</Strong>{" "}
											{capitalizeFirstLetter(
												performanceComparison?.historicalTrend?.trend ||
													"Data not available"
											)}
										</p>
										<p>
											<Strong>Comparison :</Strong>{" "}
											{capitalizeFirstLetter(performanceComparison.comparison)}
										</p>
										<p>
											<Strong>Percentile :</Strong>{" "}
											{performanceComparison.percentile}
										</p>
									</>
								) : (
									<p>Data not available</p>
								)}
							</PerformanceContainer>
						</ResponsiveFlexContainer>

						<ResponsiveFlexContainer>
							<StyledPerfectScrollbarInterview>
								<InterviewListContainer style={{ flex: 1 }}>
									<Heading as={"h1"} style={{ color: "#ffffff" }}>
										Interviews
									</Heading>
									{interviews.length > 0 ? (
										interviews.map((interview, index) => (
											<InterviewItem
												key={index}
												onClick={() => handleInterviewClick(interview)}
											>
												<InterviewItemContainer>
													<InfoTextList>
														<LabelList>
															{interview.companyName || "Data not available"}
														</LabelList>
														<span> - </span>
														{interview.interviewType ||
															"Data not available"}{" "}
													</InfoTextList>
													<InfoTextList>
														<DateContainer>
															{new Date(interview.createdAt).toLocaleDateString(
																undefined,
																{
																	month: "2-digit",
																	day: "2-digit",
																}
															)}
														</DateContainer>
													</InfoTextList>
												</InterviewItemContainer>
											</InterviewItem>
										))
									) : (
										<p style={{ color: "#ffffff" }}>No interviews available</p>
									)}
								</InterviewListContainer>
							</StyledPerfectScrollbarInterview>

							{selectedInterview && analysisData && performanceComparison ? (
								<StyledPerfectScrollbarAnalysis>
									<AnalysisContainer>
										<HeadingAnalysis>
											Interview Analysis -{" "}
											{selectedInterview.companyName ?? "Data not available"}
										</HeadingAnalysis>

										<InfoContainer>
											<InfoText>
												<Label>Topic :</Label>{" "}
												<Value>
													{analysisData.basicInfo.topic ?? "Data not available"}
												</Value>
											</InfoText>
											<ScoreText>
												<strong>Average Score :</strong>{" "}
												<ScoreValue>
													{analysisData.basicInfo.averageScore ??
														"Data not available"}
												</ScoreValue>
											</ScoreText>
										</InfoContainer>

										<SubHeadingAnaylsis>
											Question & Answer Analysis
										</SubHeadingAnaylsis>
										{analysisData?.answerAnalysis?.answers.map(
											(answer, index) => (
												<QuestionContainer key={index}>
													<QuestionTitle>
														{index + 1}.{" "}
														{answer.question ?? "Data not available"}
													</QuestionTitle>
													<AnswerText>
														<strong>Answer :</strong>{" "}
														{answer.answer ?? "Data not available"}
													</AnswerText>
													<FeedbackText>
														<strong>Feedback :</strong>{" "}
														{answer.feedback ?? "Data not available"}
													</FeedbackText>
													<Score>
														<strong>Score:</strong>{" "}
														{answer.score ?? "Data not available"}
													</Score>
													<StrongWhite>Suggestions :</StrongWhite>
													<SuggestionList>
														{answer.suggestions.length > 0 ? (
															answer.suggestions.map((suggestion, idx) => (
																<SuggestionItem key={idx}>
																	{suggestion ?? "Data not available"}
																</SuggestionItem>
															))
														) : (
															<SuggestionItem>
																Data not available
															</SuggestionItem>
														)}
													</SuggestionList>
												</QuestionContainer>
											)
										)}
									</AnalysisContainer>
								</StyledPerfectScrollbarAnalysis>
							) : (
								<StyledPerfectScrollbarAnalysis>
									<AnalysisContainer>
										<HeadingAnalysis>Interview Analysis</HeadingAnalysis>
										<SubHeadingAnaylsis>Data not available</SubHeadingAnaylsis>
									</AnalysisContainer>
								</StyledPerfectScrollbarAnalysis>
							)}
						</ResponsiveFlexContainer>
					</>
				)}
			</Container>
		</>
	);
};

export default Dashboard;
