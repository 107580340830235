// import React, { useState } from "react";
// import { Box, CircularProgress } from "@mui/material";
// import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { Provider } from "react-redux";
// import store from "./redux/store";
// import { GoogleOAuthProvider } from "@react-oauth/google";
// import TermsAndConditions from "./components/TermsAndConditions";
// import Login from "./components/Authentication/Login";
// import Register from "./components/Authentication/Register";
// import Dashboard from "./components/Dashboard/Dashboard";
// import Home from "./components/Home";
// import Navbar from "./components/Navbar/Navabar";
// import ProtectedRoute from "./components/Authentication/ProtectedRoute";

// import Faq from "./components/Faq";
// import QuestionGenerator from "./components/UserInterview/QuestionGenerator";
// import AnswerQuestions from "./components/UserInterview/AnswerQuestions";
// import AnalysisSummary from "./components/UserInterview/AnalysisSummary";
// import NewComponent from "./components/Home";
// import Footer from "./components/Footer/Footer";
// import About from "./components/About";
// import Contact from "./components/Contact";
// import HowItWorks from "./components/HowItWorks";
// import BlogComponent from "./components/Blog";
// import TestimonialComponent from "./components/Testimonial";
// import PricingChart from "./components/Payment/PricingChart";
// import PaymentSuccessPage from "./components/Payment/paymentsuccess";
// import DashboardLayout from "./components/DashboardLayout";
// function App() {
//   const [step, setStep] = useState(1);
//   const [questions, setQuestions] = useState([]);
//   const [analyses, setAnalyses] = useState([]);
//   const [loading, setLoading] = useState(false);

//   const handleQuestionsGenerated = (questions) => {
//     setQuestions(questions);
//     setStep(2);
//   };

//   const handleAllAnswersSubmitted = (answers) => {
//     setAnalyses(answers);
//     setLoading(true);
//     setTimeout(() => {
//       setLoading(false);
//       setStep(3);
//     }, 1000);
//   };

//   return (
//     <Box
//       justifyContent="center"
//       alignItems="center"
//       minHeight="100vh"
//       // width="100vw"
//     >
//       {/* <Navbar /> */}
//       {loading ? (
//         <CircularProgress />
//       ) : (
//         <Routes>
//           <Route exact path="/" element={<Home />} />
//           <Route exact path="/login" element={<Login />} />
//           <Route exact path="/register" element={<Register />} />
//           <Route exact path="/pricing" element={<PricingChart />} />
//           <Route exact path="/faq" element={<Faq />} />
//           <Route exact path="/about" element={<About />} />
//           <Route exact path="/contact" element={<Contact />} />
//           <Route exact path="/how-it-works" element={<HowItWorks />} />
//           <Route exact path="/testimonial" element={<TestimonialComponent />} />
//           <Route exact path="/blog" element={<BlogComponent />} />
//           <Route exact path="/paymentsuss" element={<PaymentSuccessPage />} />

//           {/* Protected routes */}
//           <Route exact path="/dashboard" element={<Dashboard />} />
//           <Route
//             exact path="/generate"
//             element={
//               <QuestionGenerator
//                 onQuestionsGenerated={handleQuestionsGenerated}
//               />
//             }
//           />
//           <Route
//             exact path="/answer"
//             element={
//               <AnswerQuestions
//                 questions={questions}
//                 onAllAnswersSubmitted={handleAllAnswersSubmitted}
//               />
//             }
//           />
//           <Route
//             exact path="/analysis"
//             element={<AnalysisSummary analyses={analyses} />}
//           />
//           <Route exact path="/" element={<DashboardLayout />}>
//             <Route
//               exact path="/terms-and-condition"
//               element={<TermsAndConditions />}
//             />
//             <Route exact path="/new-component" element={<NewComponent />} />
//           </Route>
//         </Routes>
//       )}
//       <Footer />
//     </Box>
//   );
// }

// function AppWrapper() {
//   return (
//     <Provider store={store}>
//       {/* <GoogleOAuthProvider clientId={clientId}> */}
//       <Router>
//         <App />
//       </Router>
//       {/* </GoogleOAuthProvider> */}
//     </Provider>
//   );
// }

// export default AppWrapper;

import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import TermsAndConditions from "./staticPages/TermsAndConditions";
import Login from "./components/Authentication/Login";
import Register from "./components/Authentication/Register";
import Dashboard from "./components/Header/Dashboard";
import Home from "./staticPages/Home";
import Faq from "./staticPages/Faq";
import QuestionGenerator from "./components/Dashboard_Layout/Practice_Interview/QuestionGenerator";
import AnswerQuestions from "./components/Dashboard_Layout/Practice_Interview/AnswerQuestions";
import AnalysisSummary from "./components/Dashboard_Layout/Practice_Interview/AnalysisSummary";
import NewComponent from "./staticPages/Home"; // Ensure this is the correct import
import Footer from "./components/Footer/Footer";
import About from "./staticPages/About";
import Contact from "./staticPages/Contact";
import HowItWorks from "./staticPages/HowItWorks";
import BlogComponent from "./staticPages/Blog";
import TestimonialComponent from "./staticPages/Testimonial";
import LoadingWrapper from "./LoadingWrapper";
import PricingChart from "./components/Payment/PricingChart";
import PaymentSuccessPage from "./components/Payment/paymentsuccess";
import DashboardLayout from "./components/Dashboard_Layout/DashboardLayout";

import Navbar from "./components/Navbar/Navabar"; // Ensure correct import for Navbar
import Analytics from "./components/Dashboard_Layout/Dashboard/Analytics";
import ScrollToTop from "./ScrollToTop";
import ProfileEdit from "./components/Dashboard_Layout/Profile/ProfileContainer";
import PaymentSuccessful from "./components/Payment/paymentsuccess";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";
import AptitudeTest from "./components/Dashboard_Layout/Playground/Aptitute";
import CompanySelection from "./components/Dashboard_Layout/Playground/CompanySelection";
import InterviewRound from "./components/Dashboard_Layout/Playground/InterviewRound";
import ResultScreen from "./components/Dashboard_Layout/Playground/ResultScreen";
import PageNotFound from "./staticPages/PageNotFound";
import PaymentError from "./components/Payment/paymenterror";
import InterviewPerformance from "./components/Dashboard_Layout/Interview Analytics/InterviewPerformance";
import BillingStatus from "./components/Dashboard_Layout/Billing";
import ChallengeDashboard from "./components/Dashboard_Layout/ChallangeDashboard";
function App() {
  const [step, setStep] = useState(1);
  const [questions, setQuestions] = useState([]);
  const [analyses, setAnalyses] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleQuestionsGenerated = (questions) => {
    setQuestions(questions);
    setStep(2);
  };

  const handleAllAnswersSubmitted = (answers) => {
    setAnalyses(answers);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setStep(3);
    }, 1000);
  };

  return (
    <Box justifyContent="center" alignItems="center" minHeight="100vh">
      {loading ? (
        <CircularProgress />
      ) : (
        <Routes>
          <Route exact path="/" element={<LoadingWrapper />}>
            <Route path="*" element={<PageNotFound />} />
            {/* Routes outside the dashboard layout */}
            <Route
              index
              element={
                <>
                  <Navbar />
                  <Home />
                </>
              }
            />
            <Route
              exact
              path="/login"
              element={
                <>
                  <Navbar />
                  <Login />
                </>
              }
            />
            <Route
              exact
              path="/register"
              element={
                <>
                  <Navbar />
                  <Register />
                </>
              }
            />
            <Route
              exact
              path="/payments"
              element={<PaymentSuccessPage />}
            ></Route>
            <Route
              exact
              path="/paymenterror"
              element={<PaymentError />}
            ></Route>
            <Route
              exact
              path="/pricing"
              element={
                <>
                  <Navbar />
                  <PricingChart />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/faq"
              element={
                <>
                  <Navbar />
                  <Faq />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/about"
              element={
                <>
                  <Navbar />
                  <About />
                </>
              }
            />
            <Route
              exact
              path="/contact"
              element={
                <>
                  <Navbar />
                  <Contact />
                </>
              }
            />
            <Route
              exact
              path="/how-it-works"
              element={
                <>
                  <Navbar />
                  <HowItWorks />
                </>
              }
            />
            <Route
              exact
              path="/testimonial"
              element={
                <>
                  <Navbar />
                  <TestimonialComponent />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/blog"
              element={
                <>
                  <Navbar />
                  <BlogComponent />
                  <Footer />
                </>
              }
            />
            <Route
              exact
              path="/paymentsuccess"
              element={<PaymentSuccessPage />}
            />
            <Route
              exact
              path="/dashboard"
              element={
                <>
                  <Navbar />
                  <Dashboard />
                </>
              }
            />

            <Route
              exact
              path="/answer"
              element={
                <>
                  <Navbar />
                  <AnswerQuestions
                    questions={questions}
                    onAllAnswersSubmitted={handleAllAnswersSubmitted}
                  />
                </>
              }
            />
            <Route
              exact
              path="/terms-and-condition"
              element={
                <>
                  <Navbar />
                  <TermsAndConditions />
                </>
              }
            />
            {/* Protected dashboard routes without Navbar and Footer */}
            <Route
              exact
              path="/dashboard-layout"
              element={
                <ProtectedRoute>
                  <DashboardLayout />
                </ProtectedRoute>
              }
            >
              {/* <Route index element={<Dashboard />} /> */}
              <Route exact path="analytics" element={<Analytics />}></Route>

              <Route
                exact
                path="aptitude"
                element={
                  <>
                    <AptitudeTest userId="66e7daf0f79a0b363c80dc5e" />
                  </>
                }
              />
              <Route
                exact
                path="generate"
                element={
                  <>
                    {/* <Navbar /> */}
                    <QuestionGenerator
                      onQuestionsGenerated={handleQuestionsGenerated}
                    />
                  </>
                }
              />
              <Route
                exact
                path="edit-profile"
                element={
                  <>
                    {/* <Navbar /> */}
                    <ProfileEdit />
                  </>
                }
              />
              <Route
                exact
                path="billing-status"
                element={
                  <>
                    {/* <Navbar /> */}
                    <BillingStatus />
                  </>
                }
              />
              <Route
                exact
                path="challenge"
                element={
                  <>
                    {/* <Navbar /> */}
                    <ChallengeDashboard />
                  </>
                }
              />
              <Route
                exact
                path="answer"
                element={
                  <>
                    {/* <Navbar /> */}
                    <AnswerQuestions
                      questions={questions}
                      onAllAnswersSubmitted={handleAllAnswersSubmitted}
                    />
                  </>
                }
              />
              <Route
                exact
                path="analysis"
                element={
                  <>
                    {/* <Navbar /> */}
                    <AnalysisSummary analyses={analyses} />
                  </>
                }
              />
              <Route exact path="new-component" element={<NewComponent />} />
              <Route exact path="faq" element={<Faq />} />
              <Route
                exact
                path="interview-analytics"
                element={<InterviewPerformance />}
              />

              <Route
                exact
                path="companyselect"
                element={<CompanySelection />}
              />
              <Route exact path="interview" element={<InterviewRound />} />
              <Route exact path="result" element={<ResultScreen />} />
            </Route>
          </Route>
        </Routes>
      )}
      {/* Footer only shown outside the dashboard */}
      {/* {!loading &&
        !window.location.exact pathname.startsWith("/dashboard-layout") && <Footer />} */}
    </Box>
  );
}

function AppWrapper() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <App />
      </Router>
    </Provider>
  );
}

export default AppWrapper;
