// import React from "react";

// const Pricing = () => {
//   return (
//     <section className="relative z-10 overflow-hidden bg-white pb-12 pt-20 dark:bg-dark lg:pb-[90px] lg:pt-[120px]">
//       <div className="container mx-auto">
//         <div className="-mx-4 flex flex-wrap">
//           <div className="w-full px-4">
//             <div className="mx-auto mb-[60px] max-w-[510px] text-center">
//               <span className="mb-2 block text-lg font-semibold text-primary">
//                 Pricing Table
//               </span>
//               <h2 className="mb-3 text-3xl font-bold leading-[1.208] text-dark dark:text-white sm:text-4xl md:text-[40px]">
//                 Our Pricing Plans
//               </h2>
//               <p className="text-base text-body-color dark:text-dark-6">
//                 Choose the best plan that suits your needs and get started with
//                 your interview preparation journey.
//               </p>
//             </div>
//           </div>
//         </div>

//         <div className="-mx-4 flex flex-wrap justify-center">
//           <div className="-mx-4 flex flex-wrap">
//             <PricingCard
//               type="Basic"
//               price="₹500"
//               subscription="month"
//               description="Ideal for a short-term trial or one-off projects."
//               buttonText="Choose Basic"
//             >
//               <List>1 User</List>
//               <List>All UI components</List>
//               <List>1 Month access</List>
//               <List>Free updates</List>
//               <List>Use on 1 project</List>
//               <List>1 Month support</List>
//             </PricingCard>
//             <PricingCard
//               type="Standard"
//               price="₹1200"
//               subscription="3 months"
//               description="Best for a medium-term engagement with extended features."
//               buttonText="Choose Standard"
//               active
//             >
//               <List>5 Users</List>
//               <List>All UI components</List>
//               <List>3 Months access</List>
//               <List>Free updates</List>
//               <List>Use on 3 projects</List>
//               <List>3 Months support</List>
//             </PricingCard>
//             <PricingCard
//               type="Premium"
//               price="₹2000"
//               subscription="6 months"
//               description="Perfect for long-term use with full support and benefits."
//               buttonText="Choose Premium"
//             >
//               <List>Unlimited Users</List>
//               <List>All UI components</List>
//               <List>6 Months access</List>
//               <List>Free updates</List>
//               <List>Use on unlimited projects</List>
//               <List>6 Months support</List>
//             </PricingCard>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Pricing;

// const PricingCard = ({
//   children,
//   description,
//   price,
//   type,
//   subscription,
//   buttonText,
//   active,
// }) => {
//   return (
//     <div className="w-full px-4 md:w-1/2 lg:w-1/3">
//       <div className="relative z-10 mb-10 overflow-hidden rounded-[10px] border-2 border-stroke bg-white px-8 py-10 shadow-pricing dark:border-dark-3 dark:bg-dark-2 sm:p-12 lg:px-6 lg:py-10 xl:p-[50px]">
//         <span className="mb-3 block text-lg font-semibold text-primary">
//           {type}
//         </span>
//         <h2 className="mb-5 text-[42px] font-bold text-dark dark:text-white">
//           {price}
//           <span className="text-base font-medium text-body-color dark:text-dark-6">
//             / {subscription}
//           </span>
//         </h2>
//         <p className="mb-8 border-b border-stroke pb-8 text-base text-body-color dark:border-dark-3 dark:text-dark-6">
//           {description}
//         </p>
//         <div className="mb-9 flex flex-col gap-[14px]">{children}</div>
//         <a
//           href="/#"
//           className={` ${
//             active
//               ? "block w-full rounded-md border border-primary bg-primary p-3 text-center text-base font-medium text-white transition hover:bg-opacity-90"
//               : "block w-full rounded-md border border-stroke bg-transparent p-3 text-center text-base font-medium text-primary transition hover:border-primary hover:bg-primary hover:text-white dark:border-dark-3"
//           }`}
//         >
//           {buttonText}
//         </a>
//         <div>
//           <span className="absolute right-0 top-7 z-[-1]">
//             <svg
//               width={77}
//               height={172}
//               viewBox="0 0 77 172"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <circle cx={86} cy={86} r={86} fill="url(#paint0_linear)" />
//               <defs>
//                 <linearGradient
//                   id="paint0_linear"
//                   x1={86}
//                   y1={0}
//                   x2={86}
//                   y2={172}
//                   gradientUnits="userSpaceOnUse"
//                 >
//                   <stop stopColor="#3056D3" stopOpacity="0.09" />
//                   <stop offset={1} stopColor="#C4C4C4" stopOpacity={0} />
//                 </linearGradient>
//               </defs>
//             </svg>
//           </span>
//           <span className="absolute right-4 top-4 z-[-1]">
//             <svg
//               width={41}
//               height={89}
//               viewBox="0 0 41 89"
//               fill="none"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <circle
//                 cx="38.9138"
//                 cy="87.4849"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 87.4849)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="74.9871"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 74.9871)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="62.4892"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 62.4892)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="38.3457"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 38.3457)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="13.634"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 13.634)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="50.2754"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 50.2754)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="26.1319"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 26.1319)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="38.9138"
//                 cy="1.42021"
//                 r="1.42021"
//                 transform="rotate(180 38.9138 1.42021)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="87.4849"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 87.4849)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="74.9871"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 74.9871)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="62.4892"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 62.4892)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="38.3457"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 38.3457)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="13.634"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 13.634)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="50.2754"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 50.2754)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="26.1319"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 26.1319)"
//                 fill="#3056D3"
//               />
//               <circle
//                 cx="26.4157"
//                 cy="1.42021"
//                 r="1.42021"
//                 transform="rotate(180 26.4157 1.42021)"
//                 fill="#3056D3"
//               />
//             </svg>
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// const List = ({ children }) => (
//   <div className="relative flex items-center">
//     <svg
//       width="8"
//       height="8"
//       viewBox="0 0 8 8"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//       className="mr-2.5 flex-shrink-0"
//     >
//       <circle cx="4" cy="4" r="4" fill="#3056D3" />
//     </svg>
//     <span className="text-base text-body-color dark:text-dark-6">
//       {children}
//     </span>
//   </div>
// );
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import BASE_URL from "../../API/BaseUrl";
// Alert Component
const Alert = ({ variant = "error", title, description }) => (
  <div
    className={`p-4 rounded-lg mb-6 ${
      variant === "error"
        ? "bg-red-50 text-red-800 border border-red-200"
        : "bg-green-50 text-green-800 border border-green-200"
    }`}
  >
    <div className="font-semibold mb-1">{title}</div>
    <div className="text-sm">{description}</div>
  </div>
);

// Check Icon Component
const CheckIcon = () => (
  <svg
    className="h-5 w-5 text-green-500 mr-2 flex-shrink-0"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

const PricingChart = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const calculateGST = (amount) => Math.round(amount * 0.18);

  // New: Calculate Discounted Price
  const applyDiscount = (price, discount) =>
    Math.round(price - (price * discount) / 100);

  const handlePayment = async (planName, amount, duration, offer = 0) => {
    setError("");
    setLoading(true);

    if (!user) {
      navigate("/login", { state: { from: "/pricing", plan: planName } });
      return;
    }

    try {
      const finalAmount = applyDiscount(amount, offer);
      const paymentAmount = finalAmount + calculateGST(finalAmount);

      const orderResponse = await fetch(
        `${BASE_URL}/api/payment/create-order`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            userId: user.id,
            amount: paymentAmount,
            duration,
          }),
        }
      );

      if (!orderResponse.ok) throw new Error("Failed to create order");

      const orderData = await orderResponse.json();

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: orderData.amount,
        currency: orderData.currency,
        name: "Interview Prep Pro",
        description: `${planName} Subscription`,
        order_id: orderData.orderId,
        handler: async (response) => {
          try {
            const verifyResponse = await fetch(
              `${BASE_URL}/api/payment/payment-success`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                body: JSON.stringify({
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                }),
              }
            );

            if (!verifyResponse.ok)
              throw new Error("Payment verification failed");

            const verifyData = await verifyResponse.json();
            navigate("/paymentsuccess", {
              state: {
                paymentExpiry: verifyData.paymentExpiry,
                plan: planName,
              },
            });
          } catch (error) {
            setError("Payment verification failed. Please contact support.");
            navigate("/payment-error");
          }
        },
        prefill: {
          name: user.name,
          email: user.email,
          contact: user.phoneNumber || "",
        },
        theme: { color: "#4F46E5" },
        modal: { ondismiss: () => setLoading(false) },
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      setError("Failed to initiate payment. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const plans = [
    // {
    //   name: "Free Plan",
    //   price: 0,
    //   description: "Perfect for those just getting started, it provides a simple way to begin your journey.",
    //   features: [
    //     "Access to 3 Mock Interviews per month",
    //     "Basic Question Bank (Technical, HR, and Behavioral)",
    //     "Limited AI Feedback and Analysis",
    //     "Basic Resume Review",
    //     "Progress Tracking for 1 Profile",
    //   ],
    //   duration: 0,
    // },
    {
      name: "Monthly Plan",
      price: 29,
      description: "Unlock additional features for comprehensive preparation.",
      features: [
        "Unlimited Mock Interviews",
        "Full Access to Advanced Question Bank",
        "Detailed AI Feedback on Communication, Coding, and Behavioral Skills",
        "Advanced Resume and Cover Letter Review",
        "Company-Specific Interview Prep",
        "Detailed Performance Reports and Study Plans",
        "Progress Tracking for up to 3 Profiles",
      ],
      duration: 1,
      offer: 70, // 95% discount for launching offer
      // popular: true,
      launchingOffer: true,
    },
    {
      name: "Quarterly Plan",
      price: 79,
      description:
        "Best value for serious preparation, offering resources to help you achieve your goals.",
      features: [
        "All features of Monthly Plan",
        "Priority AI Feedback and Insights",
        "1 One-on-One Career Coaching Session",
        "Access to Premium Question Bank with Exclusive Content",
      ],
      duration: 3,
      offer: 25, // 10% discount
      // popular: true,
      launchingOffer: true,
    },
    {
      name: "Half-Yearly Plan",
      price: 149,
      description: "Comprehensive preparation for multiple interview cycles.",
      features: [
        "All features of Quarterly Plan",
        "2 One-on-One Career Coaching Sessions",
        "Advanced Company-Specific Interview Prep",
        "Resume and Cover Letter Optimization for Specific Roles",
      ],
      duration: 6,
      offer: 40,
      launchingOffer: true,
    },
    {
      name: "Annual Plan",
      price: 299,
      description:
        "Full year of premium interview preparation and career support.",
      features: [
        "All features of Half-Yearly Plan",
        "4 One-on-One Career Coaching Sessions",
        "Career Progression Reports",
        "Invite-Only Challenges and Leaderboards",
        "Priority Support",
      ],
      duration: 12,
      offer: 50, // 10% discount
      launchingOffer: true,
    },
  ];

  return (
    <div className="min-h-screen bg-slate-50 ">
      <div className="max-w-7xl mx-auto px-4 py-16">
        {error && <Alert variant="error" title="Error" description={error} />}
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 my-10">
          {plans.map((plan, index) => {
            const finalPrice = applyDiscount(plan.price, plan.offer);

            return (
              <div
                key={index}
                className="relative bg-white rounded-2xl shadow-lg border border-gray-200"
              >
                {plan.popular && (
                  <div className="absolute -top-4 right-6 bg-gradient-to-r from-yellow-400 to-yellow-500 text-white text-sm font-semibold py-1 px-4 rounded-full">
                    Popular
                  </div>
                )}
                {plan.launchingOffer && (
                  <div className="absolute -top-4 left-6 bg-gradient-to-r from-green-400 to-green-500 text-white text-sm font-semibold py-1 px-4 rounded-full">
                    Launching Offer
                  </div>
                )}

                <div className="p-6">
                  <h3 className="text-xl font-semibold text-gray-900">
                    {plan.name}
                  </h3>
                  <div className="mt-2 flex items-baseline">
                    <span
                      className={`text-3xl font-bold ${
                        plan.offer
                          ? "line-through text-gray-500"
                          : "text-gray-900"
                      }`}
                    >
                      ₹{plan.price}
                    </span>
                    {plan.offer > 0 && (
                      <span className="ml-2 text-3xl font-bold text-green-600">
                        ₹{finalPrice}
                      </span>
                    )}
                  </div>
                  <p className="mt-2 text-sm text-gray-500">
                    {plan.description}
                  </p>

                  <button
                    className={`w-full py-2.5 px-4 mt-4 rounded-lg font-medium text-white ${
                      loading
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700"
                    }`}
                    onClick={() =>
                      handlePayment(
                        plan.name,
                        plan.price,
                        plan.duration,
                        plan.offer
                      )
                    }
                    disabled={loading}
                  >
                    {loading ? "Processing..." : "Purchase Plan"}
                  </button>

                  <ul className="mt-6 space-y-3">
                    {plan.features.map((feature, i) => (
                      <li key={i} className="flex text-sm text-gray-600">
                        <CheckIcon />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PricingChart;
