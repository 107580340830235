import React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  Avatar,
  Grid,
  Container,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { motion } from "framer-motion";

const testimonials = [
  {
    name: "John D.",
    position: "Software Engineer at XYZ Tech",
    content:
      "HireHack was a game-changer for me! The coding challenges and real-time feedback helped me perfect my coding skills. The detailed AI feedback after every mock interview gave me the confidence I needed to excel in my actual interviews. Thanks to HireHack, I secured a position as a Software Engineer at XYZ Tech, and I couldn't be happier!",
  },
  {
    name: "Sarah L.",
    position: "Marketing Associate at ABC Corp.",
    content:
      "I had always struggled with behavioral interviews, but HireHack's mock interviews and behavioral feedback gave me the tools I needed to succeed. The AI analysis of my answers helped me structure my responses more effectively, and the company-specific interview questions were incredibly helpful. I'm now working as a Marketing Associate at ABC Corp. and I'm grateful for everything HireHack has done for me.",
  },
  {
    name: "Raj S.",
    position: "Data Scientist at Global Analytics",
    content:
      "As a data science professional, technical interviews can be tough, but HireHack made it easier for me to practice in a structured way. The coding practice environment was perfect for preparing for my technical interview at Global Analytics. The AI-driven coding feedback helped me improve my problem-solving skills, and I felt fully prepared going into the real interview. I landed the job, and I owe a lot of it to HireHack!",
  },
  {
    name: "Emily K.",
    position: "Finance Analyst at Top Finance Firm",
    content:
      "The financial industry is highly competitive, and interviews can be intense. HireHack's company-specific questions and detailed performance reports helped me prepare effectively. I practiced mock interviews tailored to my target companies, and the insights I received were incredibly valuable. I'm now a Finance Analyst at a top firm, and I couldn't have done it without HireHack!",
  },
  {
    name: "Arjun M.",
    position: "Campus Placement Success",
    content:
      "I used HireHack to prepare for my campus placements, and it made all the difference. The mock interviews were so realistic, and the feedback on my communication skills helped me refine my approach. I ended up getting multiple offers from top companies during placement season, and HireHack was a huge part of that success. I highly recommend it to anyone preparing for campus placements!",
  },
];

const TestimonialComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="lg">
      <Box py={4} mt={8}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant={isMobile ? "h4" : "h3"}
            component="h1"
            gutterBottom
            align="center"
            fontWeight="bold"
          >
            Real Stories from Real Candidates
          </Typography>
        </motion.div>
        <Typography variant="body1" paragraph align="center">
          At HireHack, we pride ourselves on helping candidates achieve their
          career goals. But don't just take our word for it—read the success
          stories from users who have landed their dream jobs using HireHack's
          AI-powered interview preparation tools.
        </Typography>

        <Grid container spacing={4} mt={4}>
          {testimonials.map((testimonial, index) => (
            <Grid item xs={12} md={6} key={index}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Card elevation={3}>
                  <CardContent>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Avatar
                        sx={{ bgcolor: theme.palette.primary.main, mr: 2 }}
                      >
                        {testimonial.name.charAt(0)}
                      </Avatar>
                      <Box>
                        <Typography variant="h6" component="div">
                          {testimonial.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {testimonial.position}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography variant="body2" color="text.secondary">
                      "{testimonial.content}"
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>

        <Box mt={6} textAlign="center">
          <Typography variant="h5" gutterBottom>
            Share Your Success Story
          </Typography>
          <Typography variant="body1" paragraph>
            We love hearing from our users! If HireHack helped you land your
            dream job, we want to hear about it. Share your story with us and
            inspire others on their journey to success.
          </Typography>
          <motion.div>
             <motion.button
                    className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-3.5 w-64 font-bold btn-alt"
                    style={{
                      borderRadius:"32px"
                    }}
                    initial={{ backgroundPosition: "200% center" }}
                    animate={{ backgroundPosition: "0% center" }}
                    transition={{ duration: 0.2, ease: "linear" }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    
                  >
          Share your story
        </motion.button>
          </motion.div>
        </Box>
      </Box>
    </Container>
  );
};

export default TestimonialComponent;
