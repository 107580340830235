// import React from "react";
// import ReactDOM from "react-dom/client";
// import "@fontsource/poppins/400.css";
// import "@fontsource/poppins/700.css";
// import "@fontsource/poppins/800.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import { ThemeProvider, createTheme } from "@mui/material/styles";
// import CssBaseline from "@mui/material/CssBaseline";
// import { Provider } from "react-redux";
// import { GoogleOAuthProvider } from "@react-oauth/google"; // Import GoogleOAuthProvider
// import store from "./redux/store";
// import "./index.css";
// import App from "./App";
// import reportWebVitals from "./reportWebVitals";
// import GoogleOAuthContext from "./context/GoogleOAuthContext";
// // Create a custom theme
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#6200EA", // You can adjust this color
//     },
//     secondary: {
//       main: "#03DAC6", // You can adjust this color
//     },
//     background: {
//       default: "#F5F5F5",
//       paper: "#FFFFFF",
//     },
//   },
//   typography: {
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     h4: {
//       fontWeight: 700,
//     },
//   },
//   shape: {
//     borderRadius: 12,
//   },
//   // You can add more theme customizations here
// });

// const root = ReactDOM.createRoot(document.getElementById("root"));

// // Replace 'YOUR_GOOGLE_CLIENT_ID' with your actual Google client ID

// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <GoogleOAuthContext>
//           <App />
//         </GoogleOAuthContext>
//       </ThemeProvider>
//     </Provider>
//   </React.StrictMode>
// );

// reportWebVitals();

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google"; // Import GoogleOAuthProvider
import store from "./redux/store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GoogleOAuthContext from "./context/GoogleOAuthContext";

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#6200EA", // You can adjust this color
    },
    secondary: {
      main: "#03DAC6", // You can adjust this color
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    h4: {
      fontWeight: 700,
    },
  },
  shape: {
    borderRadius: 12,
  },
});

// Function to disable copy, cut, and paste globally
const disableCopyPaste = () => {
  const handleCopyPaste = (e) => {
    e.preventDefault(); // Prevent the default copy, cut, and paste actions
  };

  // Attach event listeners
  document.addEventListener("copy", handleCopyPaste);
  document.addEventListener("cut", handleCopyPaste);
  document.addEventListener("paste", handleCopyPaste);
};

// Call the function to disable copy-paste
disableCopyPaste();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GoogleOAuthContext>
          <App />
        </GoogleOAuthContext>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
