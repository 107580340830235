import React, { useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaStar,
  FaCode,
  FaUserGraduate,
  FaBook,
  FaUsers,
} from "react-icons/fa";
import { motion } from "framer-motion";
import Footer from "../components/Footer/Footer";

const HowItWorks = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const featuredArticles = [
    {
      id: 1,
      title: "Top 10 Behavioral Interview Questions and How to Answer Them ",
      description:
        "Master the art of responding to common HR questions with our expert guide.",
      image:
        "https://images.pexels.com/photos/5668869/pexels-photo-5668869.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      id: 2,
      title: "How to Nail Your First Technical Interview",
      description:
        "Learn the key strategies to succeed in coding and technical interviews for software development roles.",
      image: "https://images.unsplash.com/photo-1516321318423-f06f85e504b3",
    },
    {
      id: 3,
      title: "Building a Winning Resume: Tips and Best Practices",
      description:
        "Get insider tips on creating a resume that stands out to recruiters.",
      image: "https://images.unsplash.com/photo-1556484687-30636164638b",
    },
  ];

  const interviewGuides = [
    {
      id: 1,
      title: "Complete Guide to Technical Interviews",
      category: "Technique",
    },
    { id: 2, title: "Behavioral Interview Prep", category: "Preparation" },
    { id: 3, title: "Group Discussion Success Tips", category: "Soft Skills" },
    { id: 4, title: "Panel Interview Guide", category: "Specialized" },
  ];

  const practiceResources = [
    { id: 1, title: "Coding Challenges", type: "Technical" },
    {
      id: 2,
      title: "Domain Interview Questions",
      type: "Interactive",
    },
    { id: 3, title: "Mock Interview Scenarios", type: "Interactive" },
  ];

  const successStories = [
    {
      id: 1,
      name: "Ganesh Margale",
      story:
        "Landed a dream job at a top tech company after following our interview guide.",
      image:
        "https://media.licdn.com/dms/image/v2/D4D03AQGJHnDBtPG-Wg/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1690536679219?e=1734566400&v=beta&t=FJB_mP-KdK4jHwg0Z0t7NyEV03DKbXnbHIMey3Kftz0",
    },
    {
      id: 2,
      name: "Dhananjay Dharne",
      story:
        "Secured a 30% salary increase by mastering negotiation techniques.",
      image:
        "https://media.licdn.com/dms/image/v2/D5603AQEtxTy-sPXtMQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1706454668797?e=1734566400&v=beta&t=YjVZMk1h0NDs69TV0Z3RCCoqzcNj3GccIMY9wcrD_dU",
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) =>
      prev === featuredArticles.length - 1 ? 0 : prev + 1
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? featuredArticles.length - 1 : prev - 1
    );
  };

  return (
    <div className="bg-gray-100 min-h-screen mt-10">
      <header className="bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 py-16 md:py-24 text-white">
        <div className="container mx-auto px-4 text-center md:text-left">
          <h1 className="text-2xl md:text-4xl font-bold mb-2">
            Your Ultimate Guide to Interview Success
          </h1>
          <p className="text-base md:text-xl">
            At HireHack, we believe in not only preparing you for interviews but
            also educating and empowering you with the knowledge and insights
            you need to excel in your career journey. Our blog and resources
            section is packed with expert tips, industry trends, success
            stories, and guides to help you stay ahead in your job search. From
            interview tips to coding challenges, explore our comprehensive
            resources to boost your confidence and skills.
          </p>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        {/* Featured Articles Section */}
        <section className="mb-16">
          <h2 className="text-2xl md:text-3xl font-semibold mb-6">
            Featured Articles
          </h2>
          <p className="mb-4">
            Stay updated with the latest trends and insights in the world of
            interviews, career development, and recruitment. Here are some of
            our latest featured articles:
          </p>
          <div className="relative">
            <div className="overflow-hidden rounded-lg shadow-lg">
              <img
                src={featuredArticles[currentSlide].image}
                alt={featuredArticles[currentSlide].title}
                className="w-full h-60 md:h-96 object-cover"
              />
              <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-4 md:p-6">
                <h3 className="text-xl md:text-2xl font-semibold mb-2">
                  {featuredArticles[currentSlide].title}
                </h3>
                <p className="mb-4">
                  {featuredArticles[currentSlide].description}
                </p>
                <motion.div>
                  <motion.button
                    className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-1 p-1 w-64 font-regular btn-alt"
                    style={{
                      width: "120px",
                      borderRadius: "32px",
                    }}
                    initial={{ backgroundPosition: "200% center" }}
                    animate={{ backgroundPosition: "0% center" }}
                    transition={{ duration: 0.2, ease: "linear" }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Join Now
                  </motion.button>
                </motion.div>
              </div>
            </div>
            <button
              onClick={prevSlide}
              className="absolute left-2 md:left-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
            >
              <FaArrowLeft className="text-blue-600" />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-2 md:right-4 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md"
            >
              <FaArrowRight className="text-blue-600" />
            </button>
          </div>
        </section>

        {/* Interview Guides and Tutorials Section */}
        <section className="mb-16">
          <h2 className="text-2xl md:text-3xl font-semibold mb-6">
            Interview Guides and Tutorials
          </h2>
          <p className="mb-4">
            Our step-by-step interview guides and tutorials are designed to help
            you prepare for a wide variety of interviews, from technical roles
            to HR and management positions. Whether you’re just starting out or
            looking to advance your career, these resources will give you the
            knowledge and confidence to succeed.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
            {interviewGuides.map((guide) => (
              <div
                key={guide.id}
                className="bg-white rounded-lg shadow-md p-4 md:p-6 hover:shadow-lg transition duration-300"
              >
                <h3 className="text-lg md:text-xl font-semibold mb-2">
                  {guide.title}
                </h3>
                <span className="inline-block bg-blue-100 text-blue-800 text-sm px-2 py-1 rounded">
                  {guide.category}
                </span>
              </div>
            ))}
          </div>
        </section>

        {/* Practice Resources Section */}
        <section className="mb-16">
          <h2 className="text-2xl md:text-3xl font-semibold mb-6">
            Practice Resources
          </h2>
          <p className="mb-4">
            Practice makes perfect! Take advantage of our curated practice
            resources, including coding challenges, sample interview questions,
            and mock interview scenarios, to sharpen your skills and improve
            your confidence. These resources are tailored to various industries
            and roles to help you prepare specifically for the job you’re
            applying for.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {practiceResources.map((resource) => (
              <div
                key={resource.id}
                className="bg-white rounded-lg shadow-md p-4 md:p-6 hover:shadow-lg transition duration-300"
              >
                <h3 className="text-lg md:text-xl font-semibold mb-2">
                  {resource.title}
                </h3>
                <p className="text-gray-600 mb-4">Type: {resource.type}</p>
                <motion.div>
                  <motion.button
                    className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-2.5 w-60  btn-alt"
                    style={{
                      borderRadius: "32px",
                    }}
                    initial={{ backgroundPosition: "200% center" }}
                    animate={{ backgroundPosition: "0% center" }}
                    transition={{ duration: 0.2, ease: "linear" }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Start Practice
                  </motion.button>
                </motion.div>
              </div>
            ))}
          </div>
        </section>

        {/* Success Stories Section */}
        <section className="mb-16">
          <h2 className="text-2xl md:text-3xl font-semibold mb-6">
            Success Stories
          </h2>
          <p className="mb-4">
            Get inspired by real stories from HireHack users who have
            successfully landed their dream jobs, improved their interview
            skills, and advanced their careers. Discover how they navigated
            their job search journey and overcame obstacles to achieve their
            professional goals.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {successStories.map((story) => (
              <div
                key={story.id}
                className="bg-white rounded-lg shadow-md p-4 md:p-6 flex items-center hover:shadow-lg transition duration-300"
              >
                <img
                  src={story.image}
                  alt={story.name}
                  className="w-16 h-16 md:w-24 md:h-24 rounded-full mr-4"
                />
                <div>
                  <h3 className="text-lg md:text-xl font-semibold">
                    {story.name}
                  </h3>
                  <p className="text-gray-600">{story.story}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Career Development Resources Section */}
        <section className="mb-16">
          <h2 className="text-2xl md:text-3xl font-semibold mb-6">
            Career Development Resources
          </h2>
          <p className="mb-4">
            Enhance your professional growth with our range of career
            development resources, including resume building guides, networking
            strategies, and courses to help you continuously develop your
            skills. Take advantage of these resources to stay competitive and
            thrive in your career.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <div className="bg-white rounded-lg shadow-md p-4 md:p-6 hover:shadow-lg transition duration-300 text-center">
              <FaBook className="text-3xl md:text-4xl text-blue-500 mx-auto mb-4" />
              <h3 className="text-lg md:text-xl font-semibold mb-2">
                Resume Building Guides
              </h3>
              <p className="text-gray-600 mb-4">
                Create a professional resume that stands out to employers.
              </p>
              <motion.div>
                <motion.button
                  className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-2.5 w-64 btn-alt"
                  style={{
                    borderRadius: "32px",
                  }}
                  initial={{ backgroundPosition: "200% center" }}
                  animate={{ backgroundPosition: "0% center" }}
                  transition={{ duration: 0.2, ease: "linear" }}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Learn More
                </motion.button>
              </motion.div>
            </div>
            <div className="bg-white rounded-lg shadow-md p-4 md:p-6 hover:shadow-lg transition duration-300 text-center">
              <FaUsers className="text-3xl md:text-4xl text-blue-500 mx-auto mb-4" />
              <h3 className="text-lg md:text-xl font-semibold mb-2">
                Networking Strategies
              </h3>
              <p className="text-gray-600 mb-4">
                Discover effective networking techniques to build your
                professional connections.
              </p>
              <motion.button
                className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-2.5 w-64 btn-alt"
                style={{
                  borderRadius: "32px",
                }}
                initial={{ backgroundPosition: "200% center" }}
                animate={{ backgroundPosition: "0% center" }}
                transition={{ duration: 0.2, ease: "linear" }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                Learn More
              </motion.button>
            </div>
            <div className="bg-white rounded-lg shadow-md p-4 md:p-6 hover:shadow-lg transition duration-300 text-center">
              <FaUserGraduate className="text-3xl md:text-4xl text-blue-500 mx-auto mb-4" />
              <h3 className="text-lg md:text-xl font-semibold mb-2">
                Professional Dev Courses
              </h3>
              <p className="text-gray-600 mb-4">
                Enroll in online courses to upgrade your skills and stay
                competitive.
              </p>
              <motion.button
                className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-2.5 w-64  btn-alt"
                style={{
                  borderRadius: "32px",
                }}
                initial={{ backgroundPosition: "200% center" }}
                animate={{ backgroundPosition: "0% center" }}
                transition={{ duration: 0.2, ease: "linear" }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                Learn More
              </motion.button>
            </div>
            <div className="bg-white rounded-lg shadow-md p-4 md:p-6 hover:shadow-lg transition duration-300 text-center">
              <FaCode className="text-3xl md:text-4xl text-blue-500 mx-auto mb-4" />
              <h3 className="text-lg md:text-xl font-semibold mb-2">
                Coding Bootcamps
              </h3>
              <p className="text-gray-600 mb-4">
                Get the coding skills you need to excel in tech roles.
              </p>
              <motion.button
                className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-2.5 w-64 font-bold btn-alt"
                style={{
                  borderRadius: "32px",
                }}
                initial={{ backgroundPosition: "200% center" }}
                animate={{ backgroundPosition: "0% center" }}
                transition={{ duration: 0.2, ease: "linear" }}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                Learn More
              </motion.button>
            </div>
          </div>
        </section>

        {/* Join the Community Section */}
      </main>
      <Footer />
    </div>
  );
};

export default HowItWorks;
