import React from "react";
import { Link } from "react-router-dom";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import Logo from "../../Images/LOGO.png";
import { motion } from "framer-motion";

function Footer() {
  const companyRoutes = [
    { name: "About", path: "/about" },
    { name: "Contact", path: "/contact" },
  ];

  const navigationRoutes = [
    { name: "FAQ's", path: "/faq" },
    { name: "How It Works", path: "/how-it-works" },
    // { name: "Blogs", path: "/blog" },
    { name: "Testimonials", path: "/testimonial" },
    { name: "Terms and Condition", path: "/terms-and-condition" },
  ];

  const legalRoutes = [
    { name: "Terms and Condition", path: "/terms-and-condition" },
  ];

  return (
    <div style={{ fontFamily: "Poppins" }} className="relative bg-black">
      <div>
        <div className="grid max-w-screen-xl grid-cols-1 gap-10 pt-10 mx-auto border-t border-gray-100 dark:border-trueGray-700 lg:grid-cols-5">
          <div className="lg:col-span-2">
            <div className="flex items-center justify-center space-x-2 text-2xl font-medium text-white dark:text-gray-100">
              <img src={Logo} alt="Company Logo" width={40} />
              {/* <h1
                style={{
                  color: "white",
                  fontSize: "50px",
                  fontFamily: "Montserrat",
                  fontOpticalSizing: "auto",
                }}
              >
                Hire Hack */}
              <motion.h1
                className="text-2xl md:text-4xl tracking-wide"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.7, ease: "easeOut" }}
              >
                <a href="/">
                  <motion.span
                    className="text-white"
                    initial={{ letterSpacing: "0.1em" }}
                    animate={{ letterSpacing: "normal" }}
                    transition={{ delay: 0.5, duration: 1 }}
                  >
                    Hire
                  </motion.span>
                  <motion.span
                    className="bg-clip-text text-transparent bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 "
                    initial={{ backgroundPosition: "200% center" }}
                    animate={{ backgroundPosition: "0% center" }}
                    transition={{ duration: 3, ease: "linear" }}
                  >
                    Hack
                  </motion.span>
                </a>
              </motion.h1>
              {/* </h1> */}
            </div>
            <div className="flex items-start justify-center flex-col max-w-md mt-8 mx-4 text-white">
              <p>
                1st Floor, Sairaj Landmark, Savarkar Rd, Shivaji Colony,
                Tilakwadi, Belagavi, Karnataka 590006
              </p>
              <a href="tel:+917019713085">+91 7019713085</a>
            </div>
          </div>

          {/* Company Links */}
          <div>
            <h3 className="text-lg font-medium text-white mb-4 text-center">
              Company
            </h3>
            <div className="flex items-center justify-center text-center flex-wrap w-full">
              {companyRoutes.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className="w-full px-4 py-2 text-white"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>

          {/* Navigation Links */}
          <div>
            {/* <h3 className="text-lg font-medium text-white mb-4 text-center">
              Navigation
            </h3> */}
            <div className="flex items-center justify-center text-center flex-wrap w-full">
              {navigationRoutes.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className="w-full px-4 py-2 text-white"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>

          {/* Legal Links */}
          {/* <div>
            <h3 className="text-lg font-medium text-white mb-4 text-center">
              Legal
            </h3>
            <div className="flex items-center justify-center text-center flex-wrap w-full">
              {legalRoutes.map((item, index) => (
                <Link
                  key={index}
                  to={item.path}
                  className="w-full px-4 py-2 text-white"
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div> */}

          {/* Social Icons */}
          <div className="flex items-center justify-center text-center text-white">
            <div className="flex mt-5 space-x-5">
              <a
                href="https://x.com/HirehackTheta"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:relative hover:top-[-3px] hover:transition-[top] hover:duration-300"
              >
                <TwitterIcon fontSize="large" />
              </a>
              <a
                href="https://www.facebook.com/profile.php?id=61567282110629"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:relative hover:top-[-3px] hover:transition-[top] hover:duration-300"
              >
                <FacebookIcon fontSize="large" />
              </a>
              <a
                href="https://www.instagram.com/hirehacktheta/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:relative hover:top-[-3px] hover:transition-[top] hover:duration-300"
              >
                <InstagramIcon fontSize="large" />
              </a>
              <a
                href="https://www.linkedin.com/showcase/hire-hack/"
                target="_blank"
                rel="noopener noreferrer"
                className="hover:relative hover:top-[-3px] hover:transition-[top] hover:duration-300"
              >
                <LinkedInIcon fontSize="large" />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-8 p-6 text-sm text-center text-white">
          Copyright © {new Date().getFullYear()}. Made with ♥ by{" "}
          <a href="/" rel="noopener noreferrer">
            Theta Dynamics
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
