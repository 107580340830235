import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

const ResultScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (document.fullscreenElement) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        // Chrome, Safari, and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE/Edge
        document.msExitFullscreen();
      }
    }

    alert("It's safe to close the window.");
  }, []);

  const handleViewAnalytics = () => {
    navigate("/dashboard-layout/interview-analytics");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "15px" }}>
        Interview Completed!
      </h2>
      <Button variant="contained" color="primary" onClick={handleViewAnalytics}>
        View Analytics
      </Button>
    </div>
  );
};

export default ResultScreen;
