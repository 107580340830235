// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation } from "react-router-dom";
// // import { Alert, AlertDescription, AlertTitle } from "@mui/components/ui/alert";
// import { Alert, AlertTitle } from "@mui/material";

// function PaymentSuccessPage() {
//   const [subscriptionEnd, setSubscriptionEnd] = useState(null);
//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     if (location.state && location.state.subscriptionEnd) {
//       setSubscriptionEnd(new Date(location.state.subscriptionEnd));
//     }
//   }, [location]);

//   return (
//     <div className="min-h-screen flex items-center justify-center bg-gray-100">
//       <div className="max-w-md w-full space-y-8 p-10 bg-white shadow rounded-xl">
//         <Alert>
//           <AlertTitle className="text-2xl font-bold text-green-600">
//             Payment Successful!
//           </AlertTitle>
//           {/* <AlertDescription> */}
//           <p className="mt-2 text-sm text-gray-500">
//             Thank you for your purchase. Your subscription is now active.
//           </p>
//           {subscriptionEnd && (
//             <p className="mt-2 text-sm font-semibold">
//               Your subscription will end on:{" "}
//               {subscriptionEnd.toLocaleDateString()}
//             </p>
//           )}
//           {/* </AlertDescription> */}
//         </Alert>
//         <button
//           onClick={() => navigate("/")}
//           className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//         >
//           Return to Dashboard
//         </button>
//       </div>
//     </div>
//   );
// }

// export default PaymentSuccessPage;
import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Dialog,
  CircularProgress,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
// Import the payment success sound (make sure the path is correct)
import successSound from "../../Images/Profile_Pictures/audio.m4a"; // Example sound file path

function PaymentSuccessful() {
  const [countdown, setCountdown] = useState(5); // Countdown timer state
  const navigate = useNavigate();
  const [play] = useSound(successSound); // Sound hook
  play();
  // Redirect to dashboard after countdown reaches zero
  useEffect(() => {
    // Play the success sound immediately when the component loads
    play();

    // Start the countdown timer
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    // Redirect when countdown reaches zero (after 5 seconds)
    const redirectTimeout = setTimeout(() => {
      navigate("/dashboard-layout/analytics");
    }, 5000); // 5 seconds

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimeout);
    };
  }, [navigate, play]);

  // Framer Motion animation variants
  const successVariants = {
    hidden: { opacity: 0, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  const textVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 1, duration: 1 } },
  };

  const confettiVariants = {
    hidden: { opacity: 0, y: "-100%" },
    visible: {
      opacity: 1,
      y: "0%",
      transition: { duration: 0.8, ease: "easeOut", delay: 0.2 },
    },
  };

  return (
    <Dialog open fullWidth maxWidth="sm">
      <DialogContent sx={{ textAlign: "center" }}>
        {/* Success Icon Animation */}
        <motion.div
          variants={successVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          style={{ display: "inline-block", marginBottom: "20px" }}
        >
          {/* Rotating Check Mark Icon */}
          <motion.div
            initial={{ rotate: 0 }}
            animate={{ rotate: 360 }}
            transition={{ duration: 1, ease: "easeInOut", delay: 0.5 }} // Rotate once with delay
          >
            {/* Material UI Check Circle Icon */}
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "center",
                alignItems: "center",
                width: 100,
                height: 100,
                borderRadius: "50%",
                backgroundColor: "green",
                color: "white",
                fontSize: "40px",
              }}
            >
              ✔
            </Box>
          </motion.div>
        </motion.div>

        {/* Success Text Animation */}
        <motion.div variants={textVariants} initial="hidden" animate="visible">
          <Typography variant="h5" component="h1" gutterBottom>
            Payment Successful!
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Your payment has been processed successfully. You will be redirected
            to the dashboard in {countdown} seconds.
          </Typography>
        </motion.div>

        {/* Confetti Animation */}
        <motion.div
          variants={confettiVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
        >
          {/* Confetti GIF or CSS effect */}
          <Box
            sx={{
              width: "100%",
              height: "150px",
              backgroundImage:
                "url(https://cdn.pixabay.com/animation/2023/08/17/08/51/08-51-41-992_512.gif)", // Replace with a local confetti image or implement a CSS effect
              backgroundSize: "cover",
              backgroundPosition: "center",
              marginTop: "20px",
            }}
          />
        </motion.div>

        {/* Countdown Timer Progress Bar */}
        <Box sx={{ mt: 4 }}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            Redirecting to the dashboard in {countdown} seconds...
          </Typography>
          <CircularProgress
            variant="determinate"
            value={((5 - countdown) * 100) / 5}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {/* Optional Button to manually close the dialog before countdown */}
      </DialogActions>
    </Dialog>
  );
}

export default PaymentSuccessful;
