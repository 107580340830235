import React from "react";
import {
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Container,
  Button,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { motion } from "framer-motion";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const featuredArticles = [
  {
    title: "Top 10 Behavioral Interview Questions and How to Answer Them",
    description:
      "Master the art of responding to common HR questions with our expert guide.",
    image: "/path/to/image1.jpg",
  },
  {
    title: "How to Nail Your First Technical Interview",
    description:
      "Learn the key strategies to succeed in coding and technical interviews for software development roles.",
    image: "/path/to/image2.jpg",
  },
  {
    title: "Building a Winning Resume: Tips and Best Practices",
    description:
      "Get insider tips on creating a resume that stands out to recruiters.",
    image: "/path/to/image3.jpg",
  },
  {
    title: "The Future of AI in Recruitment",
    description:
      "Discover how AI is changing the hiring landscape and what it means for job seekers.",
    image: "/path/to/image4.jpg",
  },
];

const resourceCategories = [
  {
    title: "Interview Guides and Tutorials",
    items: [
      "Complete Guide to Technical Interviews",
      "Behavioral Interview Prep",
      "Group Discussion Success Tips",
      "Panel Interview Guide",
    ],
  },
  {
    title: "Practice Resources",
    items: [
      "Coding Challenges",
      "Industry-Specific Interview Questions",
      "Behavioral Question Bank",
      "Mock Interview Scenarios",
    ],
  },
  {
    title: "Career Development Resources",
    items: [
      "How to Build a Personal Brand",
      "Networking Tips for Job Seekers",
      "Career Growth Strategies",
      "Negotiating Your Salary",
    ],
  },
];

const BlogComponent = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container maxWidth="lg">
      <Box py={4} mt={8}>
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography
            variant={isMobile ? "h4" : "h3"}
            component="h1"
            gutterBottom
            align="center"
            fontWeight="bold"
          >
            Your Ultimate Guide to Interview Success
          </Typography>
        </motion.div>
        <Typography variant="body1" paragraph align="center">
          At HireHack, we believe in not only preparing you for interviews but
          also educating and empowering you with the knowledge and insights you
          need to excel in your career journey. Our blog and resources section
          is packed with expert tips, industry trends, success stories, and
          guides to help you stay ahead in your job search.
        </Typography>

        <Box mt={6}>
          <Typography variant="h4" gutterBottom>
            Featured Articles
          </Typography>
          <Grid container spacing={4}>
            {featuredArticles.map((article, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      image={article.image}
                      alt={article.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {article.title}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {article.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box mt={6}>
          <Typography variant="h4" gutterBottom>
            Resources
          </Typography>
          <Grid container spacing={4}>
            {resourceCategories.map((category, index) => (
              <Grid item xs={12} md={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <Card>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {category.title}
                      </Typography>
                      <List>
                        {category.items.map((item, itemIndex) => (
                          <ListItem key={itemIndex}>
                            <ListItemIcon>
                              <ArrowForwardIosIcon
                                color="primary"
                                fontSize="small"
                              />
                            </ListItemIcon>
                            <ListItemText primary={item} />
                          </ListItem>
                        ))}
                      </List>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box mt={6} textAlign="center">
          <Typography variant="h5" gutterBottom>
            Join the Community
          </Typography>
          <Typography variant="body1" paragraph>
            We're more than just an interview platform—we're a community. Join
            thousands of candidates who are working to improve their skills and
            secure their dream jobs. Engage with our content, share your
            experiences, and connect with other like-minded individuals.
          </Typography>
          <motion.div>
             <motion.button
                    className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-3.5 w-64 font-bold btn-alt"
                    style={{
                      borderRadius:"32px"
                    }}
                    initial={{ backgroundPosition: "200% center" }}
                    animate={{ backgroundPosition: "0% center" }}
                    transition={{ duration: 0.2, ease: "linear" }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    
                  >
          Join Now
        </motion.button>
          </motion.div>
        </Box>
      </Box>
    </Container>
  );
};

export default BlogComponent;
