// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "sonner";

// // Async thunk for logging in the user
// export const loginUser = createAsyncThunk(
//   "auth/loginUser",
//   async ({ email, password }, { rejectWithValue }) => {
//     try {
//       const res = await fetch("https://hirehack.io/api/user/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });
//       const data = await res.json();

//       if (res.status !== 200) {
//         return rejectWithValue(data.message || "Failed to login");
//       }

//       return data; // data should contain the user info and token
//     } catch (error) {
//       return rejectWithValue(error.message || "Something went wrong");
//     }
//   }
// );

// // Async thunk for Google login
// export const googleLogin = createAsyncThunk(
//   "auth/googleLogin",
//   async (tokenId, { rejectWithValue }) => {
//     try {
//       const res = await fetch("https://hirehack.io/api/user/googlelogin", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ tokenId }),
//       });
//       const data = await res.json();

//       if (!data.success) {
//         return rejectWithValue(data.message || "Google login failed");
//       }

//       return data;
//     } catch (error) {
//       return rejectWithValue(error.message || "Something went wrong");
//     }
//   }
// );

// const authSlice = createSlice({
//   name: "auth",
//   initialState: {
//     user: null,
//     token: null,
//     status: null,
//     error: null,
//   },
//   reducers: {
//     logout: (state) => {
//       localStorage.removeItem("token");
//       localStorage.removeItem("user");
//       state.user = null;
//       state.token = null;
//       state.status = null;
//       toast.success("Logged out successfully");
//     },
//   },
//   extraReducers: (builder) => {
//     // Handle login
//     builder
//       .addCase(loginUser.pending, (state) => {
//         state.status = "loading";
//         state.error = null;
//       })
//       .addCase(loginUser.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.user = action.payload.user;
//         state.token = action.payload.token;
//         state.error = null;

//         // Store token and user info in localStorage
//         localStorage.setItem("token", action.payload.token);
//         localStorage.setItem("user", JSON.stringify(action.payload.user));
//       })
//       .addCase(loginUser.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.payload || "Login failed";
//       });

//     // Handle Google login
//     builder
//       .addCase(googleLogin.pending, (state) => {
//         state.status = "loading";
//         state.error = null;
//       })
//       .addCase(googleLogin.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.user = action.payload.user;
//         state.token = action.payload.token;
//         state.error = null;

//         // Store token and user info in localStorage
//         localStorage.setItem("token", action.payload.token);
//         localStorage.setItem("user", JSON.stringify(action.payload.user));
//       })
//       .addCase(googleLogin.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.payload || "Google login failed";
//       });
//   },
// });

// export const { logout } = authSlice.actions;

// export default authSlice.reducer;

// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "sonner";
// import axios from "axios";
// // Async thunk for logging in the user
// export const loginUser = createAsyncThunk(
//   "auth/loginUser",
//   async ({ email, password }, { rejectWithValue }) => {
//     try {
//       const res = await fetch("https://hirehack.io/api/user/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });
//       const data = await res.json();

//       if (res.status !== 200) {
//         return rejectWithValue(data.message || "Failed to login");
//       }

//       return data; // data should contain the user info and token
//     } catch (error) {
//       return rejectWithValue(error.message || "Something went wrong");
//     }
//   }
// );

// // Async thunk for Google login
// // export const googleLogin = createAsyncThunk(
// //   "auth/googlelogin",
// //   async (tokenId, { rejectWithValue }) => {
// //     try {
// //       const res = await fetch("https://hirehack.io/api/user/googlelogin", {
// //         method: "POST",
// //         headers: {
// //           "Content-Type": "application/json",
// //         },
// //         body: JSON.stringify({ tokenId }),
// //       });
// //       const data = await res.json();
// //       console.log(res);

// //       if (!data.success) {
// //         return rejectWithValue(data.message || "Google login failed");
// //       }

// //       return data;
// //     } catch (error) {
// //       return rejectWithValue(error.message || "Something went wrong");
// //     }
// //   }
// // );

// export const googleLogin = createAsyncThunk(
//   "auth/googleLogin",
//   async (tokenId) => {
//     console.log(tokenId);
//     const response = await axios.post(
//       "https://hirehack.io/api/user/googlelogin",
//       { tokenId }
//     );
//     localStorage.setItem("token", response.data.token);
//     return response.data;
//   }
// );
// const authSlice = createSlice({
//   name: "auth",
//   initialState: {
//     user: JSON.parse(localStorage.getItem("user")) || null,
//     token: localStorage.getItem("token") || null,
//     status: null,
//     error: null,
//   },
//   reducers: {
//     logout: (state) => {
//       localStorage.removeItem("token");
//       localStorage.removeItem("user");
//       state.user = null;
//       state.token = null;
//       state.status = null;
//       toast.success("Logged out successfully");
//     },
//   },
//   extraReducers: (builder) => {
//     // Handle login
//     builder
//       .addCase(loginUser.pending, (state) => {
//         state.status = "loading";
//         state.error = null;
//       })
//       .addCase(loginUser.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.user = action.payload.user;
//         state.token = action.payload.token;
//         state.error = null;

//         // Store token and user info in localStorage
//         localStorage.setItem("token", action.payload.token);
//         localStorage.setItem("user", JSON.stringify(action.payload.user));
//         toast.success("Login successful!");
//       })
//       .addCase(loginUser.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.payload || "Login failed";
//         toast.error(state.error);
//       });

//     // Handle Google login
//     builder
//       .addCase(googleLogin.pending, (state) => {
//         state.status = "loading";
//       })
//       .addCase(googleLogin.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.user = action.payload.user;
//         state.token = action.payload.token;
//       })
//       .addCase(googleLogin.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.error.message;
//       });
//   },
// });

// export const { logout } = authSlice.actions;

// export default authSlice.reducer;

// import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { toast } from "sonner";
// import axios from "axios";

// // Async thunk for logging in the user
// export const loginUser = createAsyncThunk(
//   "auth/loginUser",
//   async ({ email, password }, { rejectWithValue }) => {
//     try {
//       const res = await fetch("https://hirehack.io/api/user/login", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({ email, password }),
//       });
//       const data = await res.json();

//       if (res.status !== 200) {
//         return rejectWithValue(data.message || "Failed to login");
//       }

//       // Store token and user in localStorage on successful login
//       localStorage.setItem("token", data.token);
//       localStorage.setItem(
//         "user",
//         JSON.stringify({
//           id: data.user.id,
//           email: data.user.email,
//           name: data.user.name,
//           additionalInfoRequired: data.additionalInfoRequired, // Store the additional info flag
//         })
//       );

//       return data; // return user info and token
//     } catch (error) {
//       return rejectWithValue(error.message || "Something went wrong");
//     }
//   }
// );

// // Async thunk for Google login
// export const googleLogin = createAsyncThunk(
//   "auth/googleLogin",
//   async (tokenId, { rejectWithValue }) => {
//     try {
//       const response = await axios.post(
//         "https://hirehack.io/api/user/googlelogin",
//         { tokenId }
//       );

//       // Store token and user in localStorage
//       localStorage.setItem("token", response.data.token);
//       localStorage.setItem("user", JSON.stringify(response.data.user));

//       return response.data; // return user info and token
//     } catch (error) {
//       return rejectWithValue(
//         error.response.data.message || "Google login failed"
//       );
//     }
//   }
// );
// export const updateInfo = createAsyncThunk(
//   "auth/updateInfo",
//   async ({ userId, company, gender, role, interests }, { rejectWithValue }) => {
//     try {
//       const token = localStorage.getItem("token"); // Get token from localStorage

//       const response = await fetch(
//         "https://hirehack.io/api/user/updateInfo",
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`, // Send token for authentication
//           },
//           body: JSON.stringify({ userId, company, gender, role, interests }),
//         }
//       );

//       const data = await response.json();

//       if (response.status !== 200) {
//         return rejectWithValue(data.message || "Failed to update information");
//       }

//       return data.user; // Return updated user data
//     } catch (error) {
//       return rejectWithValue(error.message || "Something went wrong");
//     }
//   }
// );

// // Slice
// const authSlice = createSlice({
//   name: "auth",
//   initialState: {
//     user: JSON.parse(localStorage.getItem("user")) || null,
//     token: localStorage.getItem("token") || null,
//     status: null,
//     error: null,
//   },
//   reducers: {
//     logout: (state) => {
//       localStorage.removeItem("token");
//       localStorage.removeItem("user");
//       state.user = null;
//       state.token = null;
//       state.status = null;
//       toast.success("Logged out successfully");
//     },
//   },
//   extraReducers: (builder) => {
//     builder
//       .addCase(loginUser.pending, (state) => {
//         state.status = "loading";
//         state.error = null;
//       })
//       .addCase(loginUser.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.user = action.payload.user;
//         state.token = action.payload.token;
//         state.error = null;
//         toast.success("Login successful!");
//       })
//       .addCase(loginUser.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.payload || "Login failed";
//         toast.error(state.error);
//       })
//       .addCase(googleLogin.pending, (state) => {
//         state.status = "loading";
//       })
//       .addCase(googleLogin.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.user = action.payload.user;
//         state.token = action.payload.token;
//         toast.success("Google login successful!");
//       })
//       .addCase(googleLogin.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.payload || "Google login failed";
//         toast.error(state.error);
//       })
//       .addCase(updateInfo.pending, (state) => {
//         state.status = "loading";
//         state.error = null;
//       })
//       .addCase(updateInfo.fulfilled, (state, action) => {
//         state.status = "succeeded";
//         state.user = action.payload; // Update user with new info
//         state.error = null;
//         toast.success("Additional information updated successfully!");
//       })
//       .addCase(updateInfo.rejected, (state, action) => {
//         state.status = "failed";
//         state.error = action.payload || "Update failed";
//         toast.error(state.error);
//       });
//   },
// });

// export const { logout } = authSlice.actions;
// export default authSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "sonner";
import axios from "axios";
import BASE_URL from "../API/BaseUrl";

// Async thunk for logging in the user
export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const res = await fetch(`${BASE_URL}/api/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await res.json();

      if (res.status !== 200) {
        return rejectWithValue(data.message || "Failed to login");
      }

      // Store token and user in localStorage on successful login
      localStorage.setItem("token", data.token);
      localStorage.setItem(
        "user",
        JSON.stringify({
          id: data.user.id,
          email: data.user.email,
          name: data.user.name,
          interests: data.user.interests,
          additionalInfoRequired: data.additionalInfoRequired,
        })
      );

      return {
        user: data.user,
        token: data.token,
        additionalInfoRequired: data.additionalInfoRequired,
      };
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

// Async thunk for Google login
export const googleLogin = createAsyncThunk(
  "auth/googleLogin",
  async (tokenId, { rejectWithValue }) => {
    try {
      // Make the request to the Google login API
      const res = await fetch(`${BASE_URL}/api/user/googlelogin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tokenId }),
      });

      const data = await res.json();

      // Check for errors from the server
      if (res.status !== 200) {
        return rejectWithValue(data.message || "Google login failed");
      }

      // Store token and user in localStorage
      localStorage.setItem("token", data.token);
      localStorage.setItem(
        "user",
        JSON.stringify({
          id: data.user.id,
          email: data.user.email,
          name: data.user.name,
          interests: data.user.interests,
          additionalInfoRequired: data.additionalInfoRequired, // Store additional info flag
        })
      );

      return {
        user: data.user,
        token: data.token,
        additionalInfoRequired: data.additionalInfoRequired, // Return additional info flag
      };
    } catch (error) {
      return rejectWithValue(
        error.message || "Something went wrong with Google login"
      );
    }
  }
);

// Async thunk for updating additional info
export const updateInfo = createAsyncThunk(
  "auth/updateInfo",
  async (
    { userId, company, gender, role, interests, dept },
    { rejectWithValue }
  ) => {
    try {
      const token = localStorage.getItem("token");

      const response = await fetch(`${BASE_URL}/api/user/updateInfo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          userId,
          company,
          gender,
          role,
          interests,
          dept,
        }),
      });

      const data = await response.json();

      if (response.status !== 200) {
        return rejectWithValue(data.message || "Failed to update information");
      }

      // Clear additionalInfoRequired
      localStorage.setItem(
        "user",
        JSON.stringify({ ...data.user, additionalInfoRequired: false })
      );

      return data.user;
    } catch (error) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

// Slice
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: JSON.parse(localStorage.getItem("user")) || null,
    token: localStorage.getItem("token") || null,
    status: null,
    error: null,
  },
  reducers: {
    logout: (state) => {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      state.user = null;
      state.token = null;
      state.status = null;
      toast.success("Logged out successfully");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = {
          ...action.payload.user,
          additionalInfoRequired: action.payload.additionalInfoRequired, // Update with additional info flag
        };
        state.token = action.payload.token;
        state.error = null;
        toast.success("Login successful!");
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Login failed";
        toast.error(state.error);
      })
      .addCase(googleLogin.pending, (state) => {
        state.status = "loading";
      })
      .addCase(googleLogin.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload.user;
        state.token = action.payload.token;
        toast.success("Google login successful!");
      })
      .addCase(googleLogin.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Google login failed";
        toast.error(state.error);
      })
      .addCase(updateInfo.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(updateInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = {
          ...action.payload,
          additionalInfoRequired: false, // Set to false after successful update
        }; // Update user with new info
        state.error = null;
        toast.success("Additional information updated successfully!");
      })
      .addCase(updateInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Update failed";
        toast.error(state.error);
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
