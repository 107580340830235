import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  LinearProgress,
  Grid,
  CircularProgress,
  Button,
  Box,
  Avatar,
  Chip,
  Paper,
  IconButton,
  Tooltip,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  AccessTime,
  CheckCircle,
  CreditCard,
  EmojiEvents,
  TrackChanges,
  Warning,
  Star,
  LocalFireDepartment,
  Diamond,
  Lightbulb,
  Grade,
  TrendingUp,
} from "@mui/icons-material";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import BASE_URL from "../../API/BaseUrl";
import { useNavigate } from "react-router-dom";
const BillingStatus = () => {
  const [billingInfo, setBillingInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAchievement, setShowAchievement] = useState(false);
  const [progressAnimation, setProgressAnimation] = useState(0);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  let userId;

  if (user.id === undefined) {
    userId = user._id;
  } else {
    userId = user.id;
  }
  useEffect(() => {
    fetchBillingInfo();
  }, []);

  // Animate progress bars on load
  useEffect(() => {
    if (billingInfo) {
      const timer = setInterval(() => {
        setProgressAnimation((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, getSubscriptionProgress());
        });
      }, 100);

      return () => {
        clearInterval(timer);
      };
    }
  }, [billingInfo]);

  const fetchBillingInfo = async () => {
    try {
      // const userData = JSON.parse(localStorage.getItem("user"));

      if (!userId) {
        toast.error("User information not found. Please login again.");
        setLoading(false);
        return;
      }

      const response = await axios.get(
        `${BASE_URL}/api/payment/billing-info/${userId}`
      );
      setBillingInfo(response.data);

      // Show achievement dialog for certain milestones
      if (response.data.usageStatistics?.totalInterviews >= 10) {
        setShowAchievement(true);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching billing info:", error);
      toast.error(error.response?.data?.error || "Error fetching billing info");
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const getSubscriptionProgress = () => {
    if (!billingInfo?.currentSubscription?.expiryDate) return 0;

    const start = new Date(billingInfo.paymentHistory[0]?.createdAt);
    const end = new Date(billingInfo.currentSubscription.expiryDate);
    const now = new Date();

    if (billingInfo.currentSubscription.isExpired) return 100;

    const progress = ((now - start) / (end - start)) * 100;
    return Math.min(Math.max(progress, 0), 100);
  };

  const getLevel = () => {
    const interviews = billingInfo?.usageStatistics?.totalInterviews || 0;
    return Math.floor(interviews / 5) + 1;
  };

  const getNextLevelProgress = () => {
    const interviews = billingInfo?.usageStatistics?.totalInterviews || 0;
    return (interviews % 5) * 20;
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress size={60} />
      </Box>
    );
  }
  const navigatpricing = () => {
    navigate("/pricing");
  };
  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", p: 3 }}>
      {/* Level Card */}
      <Card
        sx={{
          mb: 3,
          background: "linear-gradient(135deg, #1976d2 0%, #64b5f6 100%)",
          color: "white",
        }}
      >
        <CardContent>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar sx={{ width: 60, height: 60, bgcolor: "#fff" }}>
                <LocalFireDepartment color="primary" />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography variant="h5" gutterBottom>
                Level {getLevel()}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={getNextLevelProgress()}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  bgcolor: "rgba(255,255,255,0.3)",
                }}
              />
              <Typography variant="body2" sx={{ mt: 1 }}>
                {5 - (billingInfo?.usageStatistics?.totalInterviews % 5)}{" "}
                interviews until next level
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                icon={<Diamond />}
                label={`${
                  billingInfo?.usageStatistics?.totalInterviews || 0
                } Interviews`}
                sx={{ bgcolor: "rgba(255,255,255,0.2)", color: "white" }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* Subscription Status Card */}
      <Card sx={{ mb: 3 }}>
        <CardHeader
          avatar={
            <Avatar
              sx={{
                bgcolor: billingInfo?.currentSubscription?.isActive
                  ? "success.main"
                  : "error.main",
              }}
            >
              {billingInfo?.currentSubscription?.isActive ? (
                <CheckCircle />
              ) : (
                <AccessTime />
              )}
            </Avatar>
          }
          title="Premium Subscription"
          subheader={
            billingInfo?.currentSubscription?.isActive ? "Active" : "Expired"
          }
          action={
            <Button
              variant="contained"
              color="primary"
              startIcon={<Star />}
              sx={{
                background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
              }}
              onClick={navigatpricing}
            >
              Upgrade to Pro
            </Button>
          }
        />
        <CardContent>
          <Fade in={true} timeout={1000}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Subscription Progress
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progressAnimation}
                sx={{ height: 10, borderRadius: 5 }}
              />
              <Box display="flex" justifyContent="space-between" mt={1}>
                <Typography variant="body2" color="textSecondary">
                  Started:{" "}
                  {formatDate(billingInfo?.paymentHistory[0]?.createdAt)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Expires:{" "}
                  {formatDate(billingInfo?.currentSubscription?.expiryDate)}
                </Typography>
              </Box>
            </Box>
          </Fade>
        </CardContent>
      </Card>

      {/* Stats Cards */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{
              p: 2,
              textAlign: "center",
              position: "relative",
              overflow: "hidden",
            }}
          >
            <Box sx={{ position: "relative", zIndex: 1 }}>
              <Avatar
                sx={{
                  width: 56,
                  height: 56,
                  margin: "0 auto",
                  mb: 2,
                  bgcolor: "primary.main",
                }}
              >
                <TrackChanges />
              </Avatar>
              <Typography variant="h4" color="primary">
                {billingInfo?.usageStatistics?.totalInterviews || 0}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Interviews Completed
              </Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, textAlign: "center" }}>
            <Avatar
              sx={{
                width: 56,
                height: 56,
                margin: "0 auto",
                mb: 2,
                bgcolor: "secondary.main",
              }}
            >
              <EmojiEvents />
            </Avatar>
            <Typography variant="h4" color="secondary">
              {billingInfo?.usageStatistics?.totalAptitudeAttempts || 0}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Tests Completed
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper elevation={3} sx={{ p: 2, textAlign: "center" }}>
            <Avatar
              sx={{
                width: 56,
                height: 56,
                margin: "0 auto",
                mb: 2,
                bgcolor: "success.main",
              }}
            >
              <Grade />
            </Avatar>
            <Typography variant="h4" color="success.main">
              {getLevel()}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Current Level
            </Typography>
          </Paper>
        </Grid>
      </Grid>

      {/* Achievements Section */}
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title="Achievements"
          avatar={
            <Avatar sx={{ bgcolor: "warning.main" }}>
              <TrendingUp />
            </Avatar>
          }
        />
        <CardContent>
          <Grid container spacing={2}>
            {["Interview Master", "Quick Learner", "Perfect Score"].map(
              (achievement, index) => (
                <Grid item xs={12} sm={4} key={index}>
                  <Paper
                    elevation={3}
                    sx={{
                      p: 2,
                      textAlign: "center",
                      background:
                        index === 0
                          ? "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)"
                          : "inherit",
                      color: index === 0 ? "white" : "inherit",
                    }}
                  >
                    <Tooltip title="Achievement Unlocked!" arrow>
                      <IconButton color={index === 0 ? "inherit" : "primary"}>
                        {index === 0 ? <CheckCircle /> : <Lightbulb />}
                      </IconButton>
                    </Tooltip>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {achievement}
                    </Typography>
                  </Paper>
                </Grid>
              )
            )}
          </Grid>
        </CardContent>
      </Card>

      {/* Payment History Card */}
      <Card>
        <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: "info.main" }}>
              <CreditCard />
            </Avatar>
          }
          title="Payment History"
        />
        <CardContent>
          {billingInfo?.paymentHistory.length > 0 ? (
            billingInfo.paymentHistory.map((payment) => (
              <Paper key={payment.paymentId} sx={{ p: 2, mb: 2 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="subtitle1">
                      Payment ID: {payment.paymentId}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {formatDate(payment.createdAt)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h6" color="primary">
                      {payment.amount} {payment.currency}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))
          ) : (
            <Typography
              variant="body1"
              textAlign="center"
              color="textSecondary"
            >
              No payment history available
            </Typography>
          )}
        </CardContent>
      </Card>

      {/* Achievement Dialog */}
      <Dialog
        open={showAchievement}
        onClose={() => setShowAchievement(false)}
        TransitionComponent={Fade}
      >
        <DialogTitle sx={{ textAlign: "center" }}>
          🎉 Achievement Unlocked!
        </DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Avatar
            sx={{
              width: 80,
              height: 80,
              margin: "0 auto",
              mb: 2,
              bgcolor: "warning.main",
            }}
          >
            <EmojiEvents sx={{ fontSize: 40 }} />
          </Avatar>
          <Typography variant="h6" gutterBottom>
            Interview Master
          </Typography>
          <Typography variant="body1" color="textSecondary">
            Completed 10 interviews successfully!
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
          <Button
            variant="contained"
            onClick={() => setShowAchievement(false)}
            sx={{ borderRadius: 20 }}
          >
            Continue Your Journey
          </Button>
        </DialogActions>
      </Dialog>

      <ToastContainer />
    </Box>
  );
};

export default BillingStatus;
