import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { AlertCircle } from "lucide-react";
import BASE_URL from "../../../API/BaseUrl";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";
import Title1 from "../../Header/Title";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StyledCard = styled(Card)(({ theme }) => ({
  background: "rgba(255, 255, 255, 0.1)",
  backdropFilter: "blur(10px)",
  borderRadius: "16px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  overflow: "hidden",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
  },
}));

const EmptyStateCard = ({ title, message }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <StyledCard>
      <CardContent className="flex flex-col items-center justify-center p-8 text-center">
        <AlertCircle className="w-12 h-12 mb-4 text-white" />
        <Typography variant="h6" sx={{ color: "white", mb: 2 }}>
          {title}
        </Typography>
        <Typography variant="body1" sx={{ color: "white" }}>
          {message}
        </Typography>
      </CardContent>
    </StyledCard>
  </motion.div>
);

const AnimatedNumber = ({ value }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const end = parseInt(value);
    const duration = 2000;
    const increment = end / (duration / 16);

    const timer = setInterval(() => {
      start += increment;
      setCount(Math.min(Math.floor(start), end));
      if (start >= end) clearInterval(timer);
    }, 16);

    return () => clearInterval(timer);
  }, [value]);

  return <span>{count}</span>;
};

const StatsCard = ({ title, value, variant }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <StyledCard>
      <CardContent>
        <Typography variant="h6" sx={{ color: "white" }}>
          {title}
        </Typography>
        <Typography variant={variant} sx={{ color: "white" }}>
          {value}
        </Typography>
      </CardContent>
    </StyledCard>
  </motion.div>
);

const GamifiedAnalyticsDashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const user = useSelector((state) => state.auth.user);

  const userId = user?.id || user?._id;

  useEffect(() => {
    const fetchAnalytics = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/api/interview/analytics/${userId}`
        );
        setData(response.data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch analytics data");
        setLoading(false);
      }
    };

    fetchAnalytics();
  }, [userId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3, textAlign: "center" }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  // Check if user has no data
  const hasNoData =
    !data ||
    ((!data.totalInterviews || data.totalInterviews === 0) &&
      (!data.totalAptitudeTests || data.totalAptitudeTests === 0) &&
      (!data.recentInterviews || data.recentInterviews.length === 0));

  if (hasNoData) {
    return (
      <>
        <div className="">
          <Title1 title="Dashboard"></Title1>
        </div>
        <Box sx={{ p: { xs: 1, md: 3 }, minHeight: "100vh", color: "white" }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <EmptyStateCard
                title="Welcome to Your Dashboard!"
                message="Start your interview preparation journey by taking practice interviews and aptitude tests. Your progress and analytics will appear here."
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EmptyStateCard
                title="No Interview Data Yet"
                message="Take your first practice interview to see topic breakdowns and performance analytics."
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <EmptyStateCard
                title="Recent Activity"
                message="Your recent interviews and test attempts will be displayed here."
              />
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }

  const topicBreakdownData = {
    labels: data.topicBreakdown?.map((topic) => topic.name) || [],
    datasets: [
      {
        data: data.topicBreakdown?.map((topic) => topic.count) || [],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
        ],
      },
    ],
  };

  const companyPerformanceData = {
    labels: Object.keys(data.detailedAnalytics?.companyStats || {}),
    datasets: [
      {
        label: "Average Score",
        data: Object.values(data.detailedAnalytics?.companyStats || {}).map(
          (company) => company.averageScore
        ),
        backgroundColor: "#9966FF",
        borderColor: "white",
        borderWidth: 2,
      },
    ],
  };

  return (
    <>
      <div className="">
        <Title1 title="Dashboard"></Title1>
      </div>
      <Box sx={{ p: { xs: 1, md: 3 }, minHeight: "100vh", color: "white" }}>
        <Typography variant="h4" gutterBottom sx={{ color: "white" }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <StyledCard>
              <CardContent>
                <Typography variant="h6" sx={{ color: "white" }}>
                  Dashboard
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4}>
                    <StatsCard
                      title="Total Interviews"
                      value={
                        <AnimatedNumber value={data.totalInterviews || 0} />
                      }
                      variant="h3"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <StatsCard
                      title="Total Aptitude Tests"
                      value={
                        <AnimatedNumber value={data.totalAptitudeTests || 0} />
                      }
                      variant="h3"
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <StatsCard
                      title="Today's Interviews"
                      value={
                        <AnimatedNumber value={data.todayInterviews || 0} />
                      }
                      variant="h3"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </StyledCard>
          </motion.div>
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <StyledCard>
                <CardContent>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    Topic Breakdown
                  </Typography>
                  {data.topicBreakdown?.length > 0 ? (
                    <Doughnut
                      data={topicBreakdownData}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false,
                          },
                          tooltip: {
                            callbacks: {
                              label: (context) => {
                                const label = context.label || "";
                                return `${label}: ${context.raw}`;
                              },
                            },
                            bodyFontColor: "white",
                            backgroundColor: "rgba(0, 0, 0, 0.7)",
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box sx={{ p: 3, textAlign: "center" }}>
                      <Typography sx={{ color: "white/70" }}>
                        Complete your first interview to see topic breakdown
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </StyledCard>
            </motion.div>
          </Grid>

          <Grid item xs={12} md={6}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              <StyledCard>
                <CardContent>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    Recent Interviews
                  </Typography>
                  {data.recentInterviews?.length > 0 ? (
                    data.recentInterviews.map((interview, index) => (
                      <Box
                        key={index}
                        sx={{ display: "flex", alignItems: "center", mb: 2 }}
                      >
                        <Avatar sx={{ mr: 2, backgroundColor: "#4BC0C0" }}>
                          {interview.companyName[0]}
                        </Avatar>
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "white" }}
                          >
                            {interview.companyName}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: "rgba(255, 255, 255, 0.7)" }}
                          >
                            {interview.topic} - {interview.interviewType}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{ color: "rgba(255, 255, 255, 0.5)" }}
                          >
                            {new Date(interview.date).toLocaleDateString()}
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  ) : (
                    <Box sx={{ p: 3, textAlign: "center" }}>
                      <Typography sx={{ color: "white/70" }}>
                        No recent interviews yet
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </StyledCard>
            </motion.div>
          </Grid>

          <Grid item xs={12}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <StyledCard>
                <CardContent>
                  <Typography variant="h6" sx={{ color: "white" }}>
                    Company Performance
                  </Typography>
                  {data.detailedAnalytics?.companyStats &&
                  Object.keys(data.detailedAnalytics.companyStats).length >
                    0 ? (
                    <Bar
                      data={companyPerformanceData}
                      options={{
                        responsive: true,
                        scales: {
                          y: {
                            beginAtZero: true,
                            max: 10,
                            title: {
                              display: true,
                              text: "Average Score",
                              color: "white",
                            },
                            ticks: {
                              color: "white",
                            },
                            grid: {
                              color: "rgba(255, 255, 255, 0.1)",
                            },
                          },
                          x: {
                            ticks: {
                              color: "white",
                            },
                            grid: {
                              color: "rgba(255, 255, 255, 0.1)",
                            },
                          },
                        },
                        plugins: {
                          legend: {
                            labels: {
                              color: "white",
                            },
                          },
                        },
                      }}
                    />
                  ) : (
                    <Box sx={{ p: 3, textAlign: "center" }}>
                      <Typography sx={{ color: "white/70" }}>
                        Complete interviews to see company performance analytics
                      </Typography>
                    </Box>
                  )}
                </CardContent>
              </StyledCard>
            </motion.div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default GamifiedAnalyticsDashboard;
