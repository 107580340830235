// import React, { useState } from "react";
// import {
//   TextField,
//   Button,
//   Grid,
//   Avatar,
//   Typography,
//   Container,
//   Paper,
// } from "@mui/material";
// import { motion } from "framer-motion";
// import { styled } from "@mui/system";

// // Mock user data as a JSON object
// const mockUserData = {
//   name: "John Doe",
//   email: "john.doe@example.com",
//   phoneNumber: "+1234567890",
//   company: "Example Corp",
//   profilePicPath:
//     "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQKYK3Z-brarkS6HlNUOYHt1vH03rOs_aebA&s", // Placeholder image path
// };

// const ProfileContainer = styled(motion.div)(({ theme }) => ({
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   background: "rgba(255, 255, 255, 0.1)", // Semi-transparent white
//   backdropFilter: "blur(8px)", // Background blur
//   boxShadow: theme.shadows[4],
//   width: "100%",
//   maxWidth: "800px",
//   margin: "auto",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   color: "white", // Set text color to white
//   [theme.breakpoints.down("sm")]: {
//     padding: theme.spacing(2),
//   },
// }));

// const StyledTextField = styled(TextField)(({ theme }) => ({
//   "& .MuiInputLabel-root": {
//     color: "white", // Label color
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "white", // Border color
//     },
//     "&:hover fieldset": {
//       borderColor: "white", // Border color on hover
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "white", // Border color when focused
//     },
//   },
//   "& .MuiInputBase-input": {
//     color: "white", // Input text color
//   },
// }));

// const EditProfile = () => {
//   const [formData, setFormData] = useState({
//     name: mockUserData.name || "",
//     email: mockUserData.email || "",
//     phoneNumber: mockUserData.phoneNumber || "",
//     company: mockUserData.company || "",
//     profilePic: mockUserData.profilePicPath || "",
//   });

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleProfilePicChange = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       setFormData((prevData) => ({
//         ...prevData,
//         profilePic: URL.createObjectURL(file),
//       }));
//     }
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Updated Profile Data:", formData);
//   };

//   return (
//     <div
//       style={{
//         height: "80vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//       }}
//     >
//       {/* <Container
//         //   component="main"

//         className="flex justify-center items-center bg-white h-full"
//       > */}
//       <ProfileContainer
//         initial={{ opacity: 0, y: 20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.5 }}
//       >
//         <Paper
//           elevation={0}
//           style={{ background: "transparent", width: "100%" }}
//         >
//           <Grid container spacing={2}>
//             <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
//               <Avatar
//                 alt="Profile Picture"
//                 src={
//                   formData.profilePic ||
//                   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQKYK3Z-brarkS6HlNUOYHt1vH03rOs_aebA&s"
//                 }
//                 sx={{ width: 100, height: 100, margin: "auto" }}
//               />
//               <Typography
//                 variant="h6"
//                 style={{ color: "white" }}
//                 sx={{ mt: 2 }}
//               >
//                 Edit Profile
//               </Typography>
//               <Button
//                     component={motion.button}
//                     variant="contained"
//                     size="large"
//                     sx={{
//                       mt:"8px",
//                       width:"200px",
//                       fontSize:"14px",
//                       height:"40px",
//                        textTransform: "none",
//                        fontWeight: 500,
//                       boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Adding white shadow
//                      }}
//                    className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-48 font-semibold btn-alt"
//                   initial={{ backgroundPosition: "200% center" }}
//                     animate={{ backgroundPosition: "0% center" }}
//                 transition={{ duration: 0.2, ease: "linear" }}
//                 whileHover={{ scale: 1.05 }}
//                       whileTap={{ scale: 0.95 }}
//                   >

//                 Upload Profile Picture
//                 <input type="file" hidden onChange={handleProfilePicChange} />
//                 </Button>
//             </Grid>

//             <Grid item xs={12} sm={8}>
//               <Grid container spacing={2}>
//                 <Grid item xs={12}>
//                   <StyledTextField
//                     fullWidth
//                     variant="outlined"
//                     label="Name"
//                     name="name"
//                     value={formData.name}
//                     onChange={handleInputChange}
//                   />
//                 </Grid>

//                 <Grid item xs={12}>
//                   <StyledTextField
//                     fullWidth
//                     variant="outlined"
//                     label="Email"
//                     name="email"
//                     value={formData.email}
//                     onChange={handleInputChange}
//                   />
//                 </Grid>

//                 <Grid item xs={12}>
//                   <StyledTextField
//                     fullWidth
//                     variant="outlined"
//                     label="Phone Number"
//                     name="phoneNumber"
//                     value={formData.phoneNumber}
//                     onChange={handleInputChange}
//                   />
//                 </Grid>

//                 <Grid item xs={12}>
//                   <StyledTextField
//                     fullWidth
//                     variant="outlined"
//                     label="Company"
//                     name="company"
//                     value={formData.company}
//                     onChange={handleInputChange}
//                   />
//                 </Grid>

//                 <Grid item xs={12}>
//                 <Button
//                     component={motion.button}
//                     variant="contained"
//                     fullWidth
//                     size="large"
//                     sx={{
//                       fontSize:"18px",
//                       height:"50px",
//                        textTransform: "none",
//                        fontWeight: 500,
//                       boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Adding white shadow
//                      }}
//                    className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-48 font-semibold btn-alt"
//                   initial={{ backgroundPosition: "200% center" }}
//                     animate={{ backgroundPosition: "0% center" }}
//                 transition={{ duration: 0.2, ease: "linear" }}
//                 whileHover={{ scale: 1.05 }}
//                       whileTap={{ scale: 0.95 }}
//                   >
//                   Save changes
//                     </Button>
//                 </Grid>
//               </Grid>
//             </Grid>
//           </Grid>
//         </Paper>
//       </ProfileContainer>
//       {/* </Container> */}
//     </div>
//   );
// };

// export default EditProfile;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Button,
  Grid,
  Avatar,
  Typography,
  Paper,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Chip,
} from "@mui/material";
import { motion } from "framer-motion";
import { styled } from "@mui/system";
import axios from "axios";
import Swal from "sweetalert2";
import BASE_URL from "../../../API/BaseUrl";
import {
  companies as COMPANIES,
  interestsOptions as INTERESTS,
  fieldsOfStudy as DEPARTMENTS,
} from "../../data/CampanyTopic";

// Constants for dropdown options
// const DEPARTMENTS = [
//   "Computer Science",
//   "Electronics",
//   "Electrical",
//   "Mechanical",
//   "Civil",
//   "Instrumentation",
//   "Chemical",
//   "Aerospace",
//   "Aeronautical",
//   "Agricultural",
//   "Mining",
//   "Naval and Marine",
//   "Metallurgy",
//   "Other",
// ];

// const COMPANIES = [
//   "Google",
//   "Microsoft",
//   "Amazon",
//   "Apple",
//   "Meta",
//   "Netflix",
//   "Tesla",
//   "IBM",
//   "Oracle",
//   "Intel",
//   "Other",
// ];

// const INTERESTS = [
//   "Computer Science",
//   "Electronics",
//   "Electrical",
//   "Mechanical",
//   "Civil",
//   "Instrumentation",
//   "Chemical",
//   "Aerospace",
//   "Aeronautical",
//   "Agricultural",
//   "Mining",
//   "Naval and Marine",
//   "Metallurgy",
//   "Other",
// ];

// const ProfileContainer = styled(motion.div)(({ theme }) => ({
//   padding: theme.spacing(4),
//   borderRadius: theme.shape.borderRadius,
//   background: "rgba(255, 255, 255, 0.1)",
//   backdropFilter: "blur(8px)",
//   boxShadow: theme.shadows[4],
//   width: "100%",
//   maxWidth: "800px",
//   margin: "auto",
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   color: "white",
//   [theme.breakpoints.down("sm")]: {
//     padding: theme.spacing(2),
//   },
// }));

// const StyledTextField = styled(TextField)(({ theme }) => ({
//   "& .MuiInputLabel-root": {
//     color: "white",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "white",
//     },
//     "&:hover fieldset": {
//       borderColor: "white",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "white",
//     },
//     "&.Mui-disabled": {
//       "& fieldset": {
//         borderColor: "rgba(255, 255, 255, 0.3)",
//       },
//     },
//   },
//   "& .MuiInputBase-input": {
//     color: "white",
//     "&.Mui-disabled": {
//       color: "rgba(255, 255, 255, 0.7)",
//       WebkitTextFillColor: "rgba(255, 255, 255, 0.7)",
//     },
//   },
//   "& .MuiSelect-icon": {
//     color: "white",
//   },
// }));

// const StyledFormControl = styled(FormControl)(({ theme }) => ({
//   "& .MuiInputLabel-root, & .MuiSelect-root, & .MuiSelect-icon": {
//     color: "white",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "white",
//     },
//     "&:hover fieldset": {
//       borderColor: "white",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "white",
//     },
//     "&.Mui-disabled": {
//       "& fieldset": {
//         borderColor: "rgba(255, 255, 255, 0.3)",
//       },
//     },
//   },
// }));

// const StyledChip = styled(Chip)(({ theme }) => ({
//   margin: theme.spacing(0.5),
//   color: "white",
//   borderColor: "white",
//   "& .MuiChip-deleteIcon": {
//     color: "white",
//     "&:hover": {
//       color: "rgba(255, 255, 255, 0.7)",
//     },
//   },
// }));

const ProfileContainer = styled(motion.div)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  background: "white", // White background
  boxShadow: theme.shadows[4],
  width: "100%",
  maxWidth: "800px",
  margin: "auto",
  display: "flex",
  flexDirection: "column", // Change to column layout for small screens
  justifyContent: "center",
  alignItems: "center",
  color: "black", // Text color to black
  [theme.breakpoints.up("md")]: {
    flexDirection: "row", // Row layout for medium and up
    justifyContent: "space-between", // Adjust spacing for medium screens
  },
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(2), // Reduced padding for smaller screens
    width: "100%", // Full width for smaller screens
  },
  [theme.breakpoints.down("xs")]: {
    padding: theme.spacing(1), // Further reduce padding for extra small screens
    alignItems: "flex-start", // Align items to start for extra small screens
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: "black", // Changed label text color to black
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black", // Changed border color to black
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
    "&.Mui-disabled": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.3)", // Adjusted for disabled state
      },
    },
  },
  "& .MuiInputBase-input": {
    color: "black", // Changed input text color to black
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.7)",
      WebkitTextFillColor: "rgba(0, 0, 0, 0.7)",
    },
  },
  "& .MuiSelect-icon": {
    color: "black", // Changed select icon color to black
  },
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputLabel-root, & .MuiSelect-root, & .MuiSelect-icon": {
    color: "black", // Changed form control text color to black
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "black", // Changed border color to black
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
    "&.Mui-disabled": {
      "& fieldset": {
        borderColor: "rgba(0, 0, 0, 0.3)", // Adjusted for disabled state
      },
    },
  },
}));

const StyledChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  color: "black", // Changed chip text color to black
  borderColor: "black", // Changed border color to black
  "& .MuiChip-deleteIcon": {
    color: "black", // Changed delete icon color to black
    "&:hover": {
      color: "rgba(0, 0, 0, 0.7)",
    },
  },
}));

const EditProfile = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    company: "",
    dept: "",
    interests: [],
    profilePic: "",
  });
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const userId = user.id || user._id;
  const isGoogleUser = user.googleId;

  const fetchUserData = async (userId) => {
    try {
      const response = await axios.get(`${BASE_URL}/api/user/${userId}`);
      const userData = response.data;

      let profilePicUrl = userData.profilePicPath;

      if (profilePicUrl && !profilePicUrl.startsWith("http")) {
        profilePicUrl = `${BASE_URL}/api/profile_pictures/${profilePicUrl}`;

        // Removing '/uploads/profile_pictures/' from the URL
        profilePicUrl = profilePicUrl.replace(
          "/uploads/profile_pictures/",
          "/"
        );
      }

      console.log(profilePicUrl);

      setFormData({
        name: userData.name || "",
        email: userData.email || "",
        phoneNumber: userData.phoneNumber || "",
        company: userData.company || "",
        dept: userData.dept || "",
        interests: userData.interests || [],
        profilePic: profilePicUrl || "",
      });
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to load user data",
      });
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUserData(userId);
    }
  }, [userId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleInterestsChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormData((prevData) => ({
      ...prevData,
      interests: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFormData((prevData) => ({
        ...prevData,
        profilePic: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      Object.keys(formData).forEach((key) => {
        if (key === "interests") {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        } else if (key !== "profilePic") {
          formDataToSend.append(key, formData[key]);
        }
      });

      if (selectedFile) {
        formDataToSend.append("profilePicture", selectedFile);
      }

      await axios.put(`${BASE_URL}/api/user/${userId}`, formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setIsEditing(false);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Profile updated successfully!",
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update profile",
      });
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div
      style={{
        height: "85vh",
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ProfileContainer
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Paper
          elevation={0}
          style={{
            // background: "transparent",
            backgroundColor: "white",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} style={{ textAlign: "center" }}>
              <Avatar
                alt="Profile Picture"
                src={
                  formData.profilePic ||
                  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTQKYK3Z-brarkS6HlNUOYHt1vH03rOs_aebA&s"
                }
                sx={{ width: 100, height: 100, margin: "auto" }}
              />
              <Typography
                variant="h6"
                // style={{ color: "white" }}
                sx={{ mt: 2 }}
              >
                {isEditing ? "Edit Profile" : "Profile"}
              </Typography>
              {!isGoogleUser && isEditing && (
                <Button
                  component={motion.button}
                  variant="contained"
                  size="large"
                  sx={{
                    mt: "8px",
                    width: "200px",
                    fontSize: "14px",
                    height: "40px",
                    textTransform: "none",
                    fontWeight: 500,
                    background: "linear-gradient(to right, #444, #666)", // Darker color gradient
                    color: "white",
                    boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)",
                  }}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() =>
                    document.getElementById("profile-pic-input").click()
                  }
                >
                  Upload Picture
                </Button>
              )}
              <input
                type="file"
                accept="image/*"
                id="profile-pic-input"
                style={{ display: "none" }}
                onChange={handleProfilePicChange}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    disabled={!isEditing || isGoogleUser}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    fullWidth
                    label="Phone Number"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    disabled={!isEditing}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledFormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="company-label">
                      Company/Institution
                    </InputLabel>
                    <Select
                      labelId="company-label"
                      name="company"
                      value={formData.company}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    >
                      {COMPANIES.map((company) => (
                        <MenuItem key={company} value={company}>
                          {company}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledFormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="dept-label">Field of Study</InputLabel>
                    <Select
                      labelId="dept-label"
                      name="dept"
                      value={formData.dept}
                      onChange={handleInputChange}
                      disabled={!isEditing}
                    >
                      {DEPARTMENTS.map((dept) => (
                        <MenuItem key={dept} value={dept}>
                          {dept}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                {/* <Grid item xs={12}>
                  <StyledFormControl fullWidth>
                    <InputLabel id="interests-label">Interests</InputLabel>
                    <Select
                      labelId="interests-label"
                      name="interests"
                      multiple
                      value={formData.interests}
                      onChange={handleInterestsChange}
                      disabled={!isEditing}
                      renderValue={(selected) => selected.join(", ")} // Join values into a string
                    >
                      {INTERESTS.map((interest) => (
                        <MenuItem key={interest} value={interest}>
                          {interest}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid> */}
              </Grid>
              <Grid container spacing={2} justifyContent="flex-end">
                <Grid item>
                  {isEditing ? (
                    <Button
                      component={motion.button}
                      variant="contained"
                      size="large"
                      onClick={handleSubmit}
                      sx={{
                        mt: "8px",
                        width: "150px",
                        fontSize: "14px",
                        height: "40px",
                        textTransform: "none",
                        fontWeight: 500,
                        background:
                          "linear-gradient(to right, #1e3c72, #2a5298)", // Gradient background
                        boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Box shadow effect
                        "&:hover": {
                          background:
                            "linear-gradient(to right, #2a5298, #1e3c72)", // Reverse gradient on hover
                        },
                      }}
                      whileHover={{ scale: 1.05 }} // Animation on hover
                      whileTap={{ scale: 0.95 }} // Animation on tap
                    >
                      Save
                    </Button>
                  ) : (
                    <Button
                      component={motion.button}
                      variant="outlined"
                      size="large"
                      onClick={() => setIsEditing(true)}
                      sx={{
                        mt: "8px",
                        width: "150px",
                        fontSize: "14px",
                        height: "40px",
                        textTransform: "none",
                        fontWeight: 500,
                        color: "black",
                        borderColor: "#1e3c72",
                        "&:hover": {
                          background: "white", // White background on hover
                          color: "#1e3c72", // Change text color to match the theme
                          borderColor: "#1e3c72", // Update the border to the new color
                        },
                      }}
                      whileHover={{ scale: 1.05 }} // Animation on hover
                      whileTap={{ scale: 0.95 }} // Animation on tap
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </ProfileContainer>
    </div>
  );
};

export default EditProfile;
