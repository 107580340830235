// import React from "react";
// import { Typography, Box, Paper, Grid, Chip, Fade, Grow, Button } from "@mui/material";
// import { styled } from "@mui/system";
// import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
// import AnalysisChart from "./AnalysisChart";
// import { motion } from "framer-motion";

// const StyledPaper = styled(Paper)(({ theme }) => ({
//   marginBottom: theme.spacing(4),
//   padding: theme.spacing(4),
//   borderRadius: 24,
//   backgroundColor: "#ffffff",
//   boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
//   transition: "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
//   "&:hover": {
//     transform: "translateY(-5px)",
//     boxShadow: "0 15px 35px rgba(0, 0, 0, 0.12)",
//   },
// }));

// const QuestionTypography = styled(Typography)(({ theme }) => ({
//   fontWeight: 700,
//   color: theme.palette.primary.main,
//   marginBottom: theme.spacing(2),
//   fontFamily: "'Poppins', sans-serif",
// }));

// const AnswerTypography = styled(Typography)(({ theme }) => ({
//   backgroundColor: theme.palette.grey[50],
//   padding: theme.spacing(2),
//   borderRadius: 16,
//   marginBottom: theme.spacing(2),
//   fontFamily: "'Roboto', sans-serif",
//   lineHeight: 1.6,
// }));

// const FeedbackTypography = styled(Typography)(({ theme }) => ({
//   fontStyle: "italic",
//   color: theme.palette.text.secondary,
//   marginBottom: theme.spacing(2),
//   fontFamily: "'Roboto', sans-serif",
// }));

// const AnalysisSummary = ({ analyses = [] }) => {
//   const navigate = useNavigate(); // Initialize useNavigate

//   const handleBackToDashboard = () => {
//     navigate("/dashboard"); // Navigate to the dashboard route
//   };

//   if (!analyses.length) {
//     return (
//       <Typography variant="h6" color="text.secondary" textAlign="center" fontFamily="'Poppins', sans-serif">
//         No analysis data available.
//       </Typography>
//     );
//   }

//   return (
//     <Box textAlign="center" p={4} sx={{ background: "linear-gradient(145deg, #f3f4f6, #ffffff)" }}>
//       <motion.div
//         initial={{ opacity: 0, y: -20 }}
//         animate={{ opacity: 1, y: 0 }}
//         transition={{ duration: 0.5 }}
//       >
//         <Typography variant="h3" gutterBottom fontWeight="800" color="primary" fontFamily="'Poppins', sans-serif">
//           Your Analysis Summary
//         </Typography>
//       </motion.div>
//       {analyses.map((item, index) => (
//         <Grow in={true} timeout={(index + 1) * 500} key={index}>
//           <StyledPaper>
//             <Grid container spacing={3}>
//               <Grid item xs={12} md={8}>
//                 <QuestionTypography variant="h5">
//                   Q: {item.question}
//                 </QuestionTypography>
//                 <AnswerTypography variant="body1">
//                   A: {item.answer}
//                 </AnswerTypography>
//                 <FeedbackTypography variant="body1">
//                   Feedback: {item.analysis?.feedback || "No feedback available"}
//                 </FeedbackTypography>
//                 <Box display="flex" justifyContent="center" flexWrap="wrap" gap={1}>
//                   {item.analysis?.keywords?.map((keyword, idx) => (
//                     <Fade in={true} timeout={(idx + 1) * 300} key={idx}>
//                       <Chip
//                         label={keyword}
//                         color="primary"
//                         variant="outlined"
//                         sx={{
//                           borderRadius: '16px',
//                           fontWeight: 600,
//                           '&:hover': {
//                             backgroundColor: 'primary.light',
//                             color: 'primary.contrastText',
//                           },
//                         }}
//                       />
//                     </Fade>
//                   ))}
//                 </Box>
//               </Grid>
//               <Grid item xs={12} md={4}>
//                 <Box height="100%" display="flex" alignItems="center" justifyContent="center">
//                   <AnalysisChart score={item.analysis?.score || 0} title="Score" />
//                 </Box>
//               </Grid>
//             </Grid>
//           </StyledPaper>
//         </Grow>
//       ))}

//       {/* Back to Dashboard Button */}
//       <Button
//         variant="contained"
//         color="primary"
//         onClick={handleBackToDashboard}
//         sx={{ mt: 4, fontWeight: 'bold', borderRadius: '12px' }}
//       >
//         Back to Dashboard
//       </Button>
//     </Box>
//   );
// };

// export default AnalysisSummary;

import React from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Chip,
  Button,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import CountUp from "react-countup";
import Title1 from "../../Header/Title";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StyledPaper = styled(motion.div)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),

  borderRadius: 24,
  boxShadow: "0 10px 30px rgba(0, 0, 0, 0.08)",
  transition: "box-shadow 0.3s ease-in-out",
  "&:hover": {
    boxShadow: "0 15px 35px rgba(0, 0, 0, 0.12)",
  },
  background: "rgba(255, 255, 255, 0.1)", // Use a semi-transparent white for a modern look
  backdropFilter: "blur(8px)",
}));

const QuestionTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: "#FFFFFF", // Change to white for better contrast
  marginBottom: theme.spacing(2),
  fontFamily: "'Poppins', sans-serif",
}));

const AnswerTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, 0.2)", // Light grey with transparency
  padding: theme.spacing(2),
  borderRadius: 16,
  marginBottom: theme.spacing(2),
  fontFamily: "'Roboto', sans-serif",
  lineHeight: 1.6,
  color: "#FFFFFF", // Text color in the answer section
}));

const FeedbackTypography = styled(Typography)(({ theme }) => ({
  fontStyle: "italic",
  color: "rgba(255, 255, 255, 0.7)", // Light grey for feedback to maintain readability
  marginBottom: theme.spacing(2),
  fontFamily: "'Roboto', sans-serif",
}));

const CircularProgressWithLabel = (props) => {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        {...props}
        size={80}
        thickness={4}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="text.secondary">
          <CountUp end={props.value} duration={2} decimals={1} suffix="%" />
        </Typography>
      </Box>
    </Box>
  );
};

const AnalysisSummary = ({ analyses = [] }) => {
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate("/dashboard-layout/analytics");
  };

  if (!analyses.length) {
    return (
      <Typography
        variant="h6"
        color="white"
        textAlign="center"
        fontFamily="'Poppins', sans-serif"
      >
        No analysis data available.
      </Typography>
    );
  }

  const chartData = {
    labels: analyses.map((_, index) => `Analysis ${index + 1}`),
    datasets: [
      {
        label: "Score",
        data: analyses.map((item) => item.analysis?.score || 0),
        backgroundColor: "rgba(25, 118, 210, 0.6)",
        borderColor: "rgba(25, 118, 210, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Overall Performance",
        font: {
          size: 18,
          weight: "bold",
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 10,
        title: {
          display: true,
          text: "Score",
        },
      },
      x: {
        title: {
          display: true,
          text: "Analysis",
        },
      },
    },
  };

  return (
    <>
      <div className="">
        <Title1 title="Your Analysis Summary"></Title1>
      </div>
      <Box
        sx={{
          // background: "linear-gradient(145deg, #e6f2ff, #ffffff)",
          padding: 4,
        }}
        // style={{ marginTop: "35px" }}
      >
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* <Typography
            variant="h3"
            gutterBottom
            fontWeight="800"
            // color="primary"
            fontFamily="'Poppins', sans-serif"
            textAlign="center"
            padding={1}
            sx={{
              fontSize: {
                xs: "1.5rem",
                sm: "2rem",
                md: "2.5rem",
                lg: "3rem",
              },
            }}
          >
            Your Analysis Summary
          </Typography> */}
        </motion.div>

        {analyses.map((item, index) => (
          <StyledPaper
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
          >
            <Typography
              variant="h4"
              className="text-2xl md:text-3xl font-bold text-center text-white mb-6" // Changed text color to white
            >
              Analysis {index + 1}
            </Typography>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={4}>
                <Box
                  className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 w-80 h-60 font-semibold btn-alt"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  backgroundColor="white"
                  borderRadius={4}
                  color="white"
                  paddingTop={2}
                >
                  <CircularProgressWithLabel
                    variant="determinate"
                    value={(item.analysis?.score || 0) * 10}
                  />
                  <Typography variant="h6" mt={2}>
                    {" "}
                    {/* Set text color to white */}
                    Score:{" "}
                    <CountUp
                      end={item.analysis?.score || 0}
                      duration={2}
                      decimals={1}
                    />
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={8}>
                <QuestionTypography variant="h5" style={{ color: "#FFFFFF" }}>
                  {" "}
                  {/* Set text color to white */}
                  Q: {item.question}
                </QuestionTypography>
                <AnswerTypography variant="body1" style={{ color: "#FFFFFF" }}>
                  {" "}
                  {/* Set text color to white */}
                  A: {item.answer}
                </AnswerTypography>
                <FeedbackTypography variant="body1">
                  Feedback: {item.analysis?.feedback || "No feedback available"}
                </FeedbackTypography>
                <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                  {item.analysis?.keywords?.map((keyword, idx) => (
                    <Chip
                      key={idx}
                      label={keyword}
                      variant="outlined"
                      sx={{
                        borderRadius: "16px",
                        fontWeight: 600,
                        color: "#FFFFFF", // Set text color to white
                        borderColor: "rgba(255, 255, 255, 0.5)", // Light border color
                        "&:hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.2)", // Light background on hover
                          color: "primary.contrastText", // Contrasting text color
                        },
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </StyledPaper>
        ))}

        <Box
          mt={4}
          style={{
            background: "#2328355E",
            backdropFilter: "blur(8px)",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            fontWeight="bold"
            textAlign="center"
            color="white" // Change text color to white for better contrast
          >
            Overall Performance
          </Typography>
          <Box height={400}>
            <Bar
              data={chartData}
              options={{
                ...chartOptions,
                plugins: {
                  legend: {
                    labels: {
                      color: "white", // Set legend text color to white
                    },
                  },
                },
                scales: {
                  x: {
                    ticks: {
                      color: "white", // Set x-axis ticks color to white
                    },
                    grid: {
                      color: "rgba(255, 255, 255, 0.2)", // Set x-axis grid color to a light color
                    },
                  },
                  y: {
                    ticks: {
                      color: "white", // Set y-axis ticks color to white
                    },
                    grid: {
                      color: "rgba(255, 255, 255, 0.2)", // Set y-axis grid color to a light color
                    },
                  },
                },
                elements: {
                  bar: {
                    backgroundColor: "white", // Set bar color to white
                  },
                },
              }}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            component={motion.button}
            variant="contained"
            onClick={handleBackToDashboard}
            fullWidth
            size="large"
            sx={{
              mt: "20px",
              fontSize: "22px",
              height: "70px",
              textTransform: "none",
              fontWeight: 500,
              boxShadow: "0 4px 10px 0 rgba(121, 120, 129, 0.5)", // Adding white shadow
            }}
            className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-4 w-48 font-semibold btn-alt"
            initial={{ backgroundPosition: "200% center" }}
            animate={{ backgroundPosition: "0% center" }}
            transition={{ duration: 0.2, ease: "linear" }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.95 }}
          >
            Goto Dashboard
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AnalysisSummary;
