// src/contexts/GoogleOAuthContext.js
import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import googleApiKey from "../API/googleApiKey";

// Replace with your actual Google Client ID
const clientId = `${googleApiKey}`;

const GoogleOAuthContext = ({ children }) => {
  return (
    <GoogleOAuthProvider clientId={clientId}>{children}</GoogleOAuthProvider>
  );
};

export default GoogleOAuthContext;
