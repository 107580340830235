import React from "react";
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { motion } from "framer-motion";
import { Margin } from "@mui/icons-material";
import Title from "../components/Header/Title";

const faqData = [
  {
    question: "What is HireHack?",
    answer:
      "HireHack is an AI-powered interview preparation platform that helps job seekers practice for interviews through mock interviews, real-time feedback, and performance tracking. We provide personalized guidance and resources to help candidates succeed in both technical and non-technical interviews.",
  },
  {
    question: "How does HireHack work?",
    answer:
      "HireHack uses AI to simulate real-world interview scenarios. You can choose from various interview types (technical, HR, panel, etc.), answer questions, and get real-time feedback on your performance. Our platform also provides coding challenges, company-specific questions, and behavioral assessments.",
  },
  {
    question: "Is HireHack only for technical interviews?",
    answer:
      "No, HireHack supports both technical and non-technical interviews. In addition to coding challenges and technical assessments, we also provide practice for HR interviews, behavioral questions, and group discussions. You can tailor your interview practice to suit the role you're applying for.",
  },
  {
    question: "Can I practice for specific companies?",
    answer:
      "Yes! HireHack offers company-specific interview preparation, including questions frequently asked by top companies in various industries. You can select a company you're targeting, and our platform will tailor the interview experience based on that company's recruitment process.",
  },
  {
    question: "How is the feedback generated?",
    answer:
      "HireHack uses AI-powered algorithms to analyze your responses in real-time. Our feedback covers various aspects such as communication skills, problem-solving ability, coding efficiency, and behavioral responses. For technical interviews, the AI evaluates your code for accuracy, efficiency, and best practices.",
  },
  {
    question: "What is included in the free plan?",
    answer:
      "The free plan allows you to access up to 3 mock interviews per month, basic interview questions, and limited AI feedback. It's a great starting point for candidates who want to explore the platform before committing to a paid plan.",
  },
  {
    question: "Can I track my progress over time?",
    answer:
      "Yes, HireHack provides detailed performance reports that allow you to track your progress over time. You'll receive personalized insights after each mock interview, and our platform keeps a record of your improvements to help you focus on areas that need more attention.",
  },
  {
    question: "Is my data secure?",
    answer:
      "Absolutely. We take your privacy and data security seriously. All user data is encrypted and stored securely. HireHack does not share your personal information with third parties without your consent.",
  },
  {
    question: "Can I use HireHack on mobile?",
    answer:
      "Yes, HireHack is accessible on both desktop and mobile devices. You can practice interviews on-the-go, making it easy to fit preparation into your busy schedule.",
  },
  {
    question: "How do I cancel my subscription?",
    answer:
      "You can cancel your subscription at any time by visiting your account settings. If you have any issues, feel free to reach out to our customer support team for assistance.",
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "We accept all major credit cards and online payment platforms. Our payment system is secure, and we offer flexible billing options for both monthly and annual subscriptions.",
  },
];

const FAQComponent = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const rendertcontact = () => {
    navigate("/contact");
  };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div className="">
        <Title title="Reports "></Title>
      </div>
      <Container
        maxWidth="md"
        style={{
          // background:
          //   "linear-gradient(59.29deg, #68328F 2.5%, #2D1375 47%, #0F0F0F 99.31%)",
          color: "black",
          background: "#FFFFFF12",

          backdropFilter: "blur(8px)",
        }}
      >
        <Box py={4} mt={2}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant={isMobile ? "h4" : "h3"}
              component="h1"
              gutterBottom
              align="center"
              fontWeight="bold"
            >
              Frequently Asked Questions (FAQ)
            </Typography>
          </motion.div>
          <Typography variant="body1" paragraph align="center">
            We understand you might have some questions about how HireHack
            works, our pricing, and the tools we provide. Below are answers to
            some of the most frequently asked questions.
          </Typography>

          <Box mt={4}>
            {faqData.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Accordion style={{ margin: "10px" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}a-content`}
                    id={`panel${index}a-header`}
                  >
                    <Typography fontWeight="bold">{`${index + 1}. ${
                      faq.question
                    }`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>{faq.answer}</Typography>
                  </AccordionDetails>
                </Accordion>
              </motion.div>
            ))}
          </Box>

          <Box mt={4} textAlign="center">
            <Typography variant="h6" gutterBottom>
              Still have questions?
            </Typography>
            <Typography variant="body1" paragraph>
              If you didn't find the answer to your question here, please feel
              free to contact our support team. We're happy to help!
            </Typography>
            <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <motion.button
                className="bg-clip text-white bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 ml-2 p-2 w-64 font-bold btn-alt"
                style={{
                  borderRadius: "32px",
                }}
                initial={{ backgroundPosition: "200% center" }}
                animate={{ backgroundPosition: "0% center" }}
                transition={{ duration: 0.2, ease: "linear" }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={rendertcontact}
              >
                Contact Support
              </motion.button>
            </motion.div>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default FAQComponent;
