// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Typography,
//   FormControl,
//   InputLabel,
//   Select,
//   MenuItem,
//   Button,
//   Paper,
// } from "@mui/material";
// import { motion } from "framer-motion";

// // const companies = ["TCS", "Accenture", "Wipro", "Cognizant", "Capgemini"];
// const companies = [
//   "PALO ALTO",
//   "TEXAS INSTRUMENTS",
//   "SAMSUNG",
//   "DASSAULT SYSTEMS",
//   "DISH NETWORK",
//   "MERCEDES BENZ",
//   "TEJAS NETWORKS",
//   "WESTERN DIGITAL",
//   "ITTIAM",
//   "REDBUS",
//   "FALABELLA",
//   "NTELLIPAAT",
//   "KPIT",
//   "PRINCIPAL",
//   "VAYAVYA",
//   "IN TIME TEC",
//   "PERSISTENT",
//   "APRA LABS",
//   "BROSE IND automotive systems",
//   "PWC",
//   "HARMAN",
//   "ACCENTURE",
//   "AXISXADES",
//   "BRILLIO",
//   "COGNIZANT",
//   "HCL",
//   "ITC INFOTECH",
//   "UNBXD",
//   "PIDILITE",
//   "TATA ELXSI",
//   "TATA TCS NINJA",
//   "QUEST",
//   "COMVIVA",
//   "AARBEE",
//   "ZAGGALE",
//   "TESSOLVE",
//   "COREEL TECHNOLOGIES",
//   "ALLGOVISION",
//   "BETA",
//   "TECH MAHINDRA",
//   "TOYOTA KIRLOSKAR MOTOR",
//   "DAIMLER",
//   "AFCONS",
//   "MARUTI SUZUKI",
//   "MAPLE LABS",
//   "DXC TECHNOLOGIES",
//   "ACCOLITE",
//   "CODECRAFT",
//   "CONTINENTAL",
//   "DELL TECHNOLOGIES",
//   "DHIOMICS",
//   "EDUSTATION",
//   "ELASTICRUN",
//   "HEXAWARE TECHNOLOGIES",
//   "HITECH",
//   "HP",
//   "KNEST",
//   "LUMEN",
//   "UBER",
//   "SANSERA",
//   "SMARTSOC",
//   "TVS ELECTRONICS",
//   "VIRTUSA",
//   "INFOYS",
//   "RADCAM TECHNOLOGIES",
//   "WIPRO",
//   "AMAZON",
//   "SABRE",
//   "PEGA",
//   "ORACLE",
//   "NUCLEI",
//   "SCHNEIDER ELECTRIC",
//   "DRAUP",
//   "CISCO",
//   "ASIANPAINTS",
//   "IMPACT ANALYTICS",
//   "JPMORGAN",
//   "CONTENTSTACK",
//   "TOSHIBA",
//   "HASHEDIN",
//   "AURIGO",
//   "BOSCH",
//   "EPSILON",
//   "SIEMENS",
//   "BAJAJ FINANCE",
//   "RAKUTEN",
//   "DELOITTE",
//   "GOOGLE",
//   "KOTAK LIFE",
//   "MINDTREE",
//   "AMD",
//   "IBM",
//   "INTEL",
//   "SONY",
//   "facebook meta",
//   "Microsoft",
//   "Esko",
//   "TCS DIGITAL",
//   "Qualcomm",
// ];

// // const topics = ["Data Science", "Software Engineering"];
// const topics = [
//   "Data Science",
//   "Software Engineering",
//   "Machine Learning",
//   "Artificial Intelligence",
//   "Cloud Computing",
//   "Cybersecurity",
//   "Blockchain",
//   "DevOps",
//   "Full Stack Development",
//   "Front-end Development",
//   "Back-end Development",
//   "Mobile App Development",
//   "Augmented Reality",
//   "Virtual Reality",
//   "Data Analysis",
//   "Big Data",
//   "Internet of Things (IoT)",
//   "Quantum Computing",
//   "Natural Language Processing",
//   "Robotics",
//   "Digital Marketing",
//   "Game Development",
//   "Web Development",
//   "UI/UX Design",
//   "Embedded Systems",
//   "Computer Vision",
//   "Database Management",
//   "Automation",
//   "Agile Methodologies",
//   "Business Intelligence",
//   "Software Testing",
//   "API Development",
//   "Cloud Infrastructure",
//   "Edge Computing",
//   "Network Security",
//   "Penetration Testing",
//   "Human-Computer Interaction",
//   "Autonomous Systems",
//   "Deep Learning",
//   "Neural Networks",
//   "Computer Graphics",
//   "Software Architecture",
//   "Product Management",
//   "Quantum Machine Learning",
//   "Augmented Analytics",
//   "Edge AI",
//   "Distributed Systems",
//   "Serverless Computing",
//   "Data Mining",
//   "Software Project Management",
//   "Mobile Security",
//   "Digital Twin Technology",
//   "Containerization",
//   "Microservices",
//   "Ethical Hacking",
//   "Smart Contracts",
//   "Data Governance",
//   "5G Technology",
//   "API Design",
//   "Computer Networks",
//   "Predictive Analytics",
//   "Innovation Management",
//   "SaaS (Software as a Service)",
// ];

// const CompanySelection = () => {
//   const [company, setCompany] = useState("");
//   const [topic, setTopic] = useState("");
//   const navigate = useNavigate();

//   const handleStartInterview = () => {
//     if (company && topic) {
//       localStorage.setItem("company", company);
//       localStorage.setItem("topic", topic);
//       navigate("/dashboard-layout/interview");
//     } else {
//       alert("Please select both company and topic");
//     }
//   };

//   return (
//     <motion.div
//       initial={{ opacity: 0, y: 20 }}
//       animate={{ opacity: 1, y: 0 }}
//       transition={{ duration: 0.5 }}
//     >
//       <Paper
//         elevation={3}
//         sx={{
//           padding: 4,
//           marginTop: 8,
//           background: "rgba(255, 255, 255, 0.1)",
//           backdropFilter: "blur(20px)",
//         }}
//       >
//         <Typography variant="h4" align="center" gutterBottom color="white">
//           Select Company and Topic
//         </Typography>
//         <Box
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             gap: 2,
//             color: "white",
//           }}
//         >
//           <FormControl variant="outlined" fullWidth>
//             <InputLabel style={{ color: "white" }}>Company</InputLabel>
//             <Select
//               value={company}
//               onChange={(e) => setCompany(e.target.value)}
//               label="Company"
//               style={{ color: "white" }}
//             >
//               <MenuItem value="" style={{ color: "white" }}>
//                 <em>Select Company</em>
//               </MenuItem>
//               {companies.map((company) => (
//                 <MenuItem key={company} value={company}>
//                   {company}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <FormControl variant="outlined" fullWidth>
//             <InputLabel style={{ color: "white" }}>Topic</InputLabel>
//             <Select
//               value={topic}
//               onChange={(e) => setTopic(e.target.value)}
//               label="Topic"
//               style={{ color: "white" }}
//             >
//               <MenuItem value="" style={{ color: "white" }}>
//                 <em>Select Topic</em>
//               </MenuItem>
//               {topics.map((topic) => (
//                 <MenuItem key={topic} value={topic}>
//                   {topic}
//                 </MenuItem>
//               ))}
//             </Select>
//           </FormControl>
//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleStartInterview}
//           >
//             Start Interview
//           </Button>
//         </Box>
//       </Paper>
//     </motion.div>
//   );
// };

// export default CompanySelection;

// CompanySelection.js

import { comapny as companies, topics } from "../../data/CampanyTopic"; // Import companies and topics

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import withReactContent from "sweetalert2-react-content";
import { topicsByInterest } from "../../data/CampanyTopic";
const MySwal = withReactContent(Swal);

const Playground = () => {
  const [company, setCompany] = useState(null);
  const [startEnabled, setStartEnabled] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);
  const userInterest = user.interests[0];
  // const handleStartPlayground = () => {
  //   // Randomly select 4 topics
  //   const selectedTopics = [];
  //   while (selectedTopics.length < 4) {
  //     const randomTopic = topics[Math.floor(Math.random() * topics.length)];
  //     if (!selectedTopics.includes(randomTopic)) {
  //       selectedTopics.push(randomTopic);
  //     }
  //   }

  //   // Join the selected topics with " and " and store them in localStorage
  //   const topicsString = selectedTopics.join(" and ");

  //   // Store company and selected topics in localStorage
  //   localStorage.setItem("company", company);
  //   localStorage.setItem("topic", topicsString);

  //   // Show rules and regulations using SweetAlert
  //   showRulesAndRegulations();
  // };

  const handleStartPlayground = () => {
    // Get topics based on the user's interest from topicsByInterest
    // const interestTopics = topicsByInterest[userInterest];

    // Randomly select 4 topics
    // const selectedTopics = [];
    // while (selectedTopics.length < 4) {
    //   const randomTopic =
    //     interestTopics[Math.floor(Math.random() * interestTopics.length)];
    //   if (!selectedTopics.includes(randomTopic)) {
    //     selectedTopics.push(randomTopic);
    //   }
    // }

    // Join the selected topics with " and " and store them in localStorage
    // const topicsString = selectedTopics.join(" and ");

    // Store company and selected topics in localStorage
    localStorage.setItem("company", company);
    // localStorage.setItem("topic", topicsString);

    // Show rules and regulations using SweetAlert (assuming showRulesAndRegulations is defined)
    showRulesAndRegulations();
  };

  const showRulesAndRegulations = () => {
    MySwal.fire({
      title: "Rules & Regulations",
      html: `
      <div style="text-align: left;">
  <h3>Rounds:</h3>
  <ul>
    <li>1. Aptitude Test</li>
    <li>2. Technical Interview</li>
    <li>3. Technical Interview Advance</li>
    <li>4. HR/Managerial</li>
  </ul><br/>
  <h3>Requirements:</h3>
  <ul>
    <li>Ensure a stable internet connection and a quiet environment.</li>
  </ul><br/>
  <h3>Proctored:</h3>
  <ul>
    <li>Tab switching is not allowed, and copy/paste functionality is disabled.</li>
  </ul><br/>
  <h3>Advice:</h3>
  <ul>
    <li>Read each question carefully and manage your time wisely.</li>
  </ul><br/>
  <h3>Important Instructions:</h3>
  <ul>
    <li>The test will be conducted in fullscreen mode. Do not exit fullscreen mode until instructed.</li>
    <li>Do not refresh the page during the test as this will result in an incomplete test.</li>
  </ul>
</div>

      `,
      width: window.innerWidth < 600 ? "90%" : "80%",
      input: "checkbox",
      inputValue: 0,
      inputPlaceholder: "I agree to the rules and regulations",
      confirmButtonText: "Proceed with Interview",
      showCancelButton: true,
      inputValidator: (result) => {
        return !result && "You need to agree to the rules to proceed!";
      },
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/dashboard-layout/interview");
      }
    });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "85vh",
      }}
    >
      <Box
        sx={{
          padding: 4,
          maxWidth: "800px",
          margin: "0 auto",
          // textAlign: "center",
          backgroundColor: "#f9f9f9",
          borderRadius: "10px",
          color: "black",
          border: "2px solid white",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Welcome to the Interview Playground
        </Typography>
        <Typography variant="body1" gutterBottom>
          This playground is designed to help you prepare for technical
          interviews. Follow the process to simulate the real interview flow:
          <br />
          <br />
          1. Select a company from the list.
          <br />
          2. Start the interview process by clicking "Start Interview."
          <br />
          3. Read through the rules and regulations.
          <br />
          4. Accept the terms by checking the "I agree" box.
          <br />
          5. Click "Proceed with Interview" to start.
          <br />
          <br />
          The playground is tailored to mimic a professional interview process.
          Stay calm, focus, and do your best!
        </Typography>

        {/* Autocomplete for selecting a company */}
        <Autocomplete
          value={company}
          onChange={(event, newValue) => {
            setCompany(newValue);
            setStartEnabled(true); // Enable start button once a company is selected
          }}
          options={companies}
          sx={{
            mb: 3,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "white",
              },
              "&:hover fieldset": {
                borderColor: "white",
              },
              "&.Mui-focused fieldset": {
                borderColor: "white",
              },
            },
          }}
          renderInput={(params) => (
            <TextField {...params} label="Select Company" variant="outlined" />
          )}
        />

        {/* Start Interview button */}
        {startEnabled && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleStartPlayground}
          >
            Start Interview
          </Button>
        )}
      </Box>
    </div>
  );
};

export default Playground;
